<template>
  <div>
    <div class="text-center pb-2 d-none d-lg-block">


      <div class="d-flex justify-content-center">
        <b-container class="bv-example-row d-flex">
          <div class=" info-box border border-secondary mb-2 col-md-2 notranslate" style="min-height: fit-content">

            <div class="info-box border border-secondary mb-2 mt-4" style="min-height: 55px;">
              <p class="mt-4" id="myDiv" ref="myDiv">Tablets</p>
            </div>
            <div v-if="brand_active" class="info-box border border-secondary mb-2 mt-2 " style="min-height: 55px;">
              <p class="mt-4">{{ brand_active }}</p>
            </div>
            <div v-if="model_active" class="info-box border border-secondary mb-2 mt-2 " style="min-height: 55px;">
              <p class="mt-4">{{ model_active }} Series</p>
            </div>
            <div v-if="pro_active" class="info-box border border-secondary mb-2 mt-2 " style="min-height: 55px;">
              <p class="mt-4">{{ pro_active }}</p>
            </div>
            <div v-if="grade_active" class="info-box border border-secondary mb-2 mt-2 " style="min-height: 55px;">
              <p class="mt-4">{{ grade_active }} inches</p>
            </div>
            <div v-if="grade_active2" class="info-box border border-secondary mb-2 mt-2 " style="min-height: 55px;">
              <p class="mt-4">{{ grade_active2 }}</p>
            </div>
            <!-- <div  v-if="grade_selected"  class="info-box border border-secondary mb-2 mt-2 " style="min-height: 55px;">
                  <p  class="mt-4"  v-if="grade_selected === 'A'">Condition 1</p>
                  <p  class="mt-4" v-if="grade_selected === 'B'">Condition 2</p>
                  <p  class="mt-4" v-if="grade_selected === 'C'">Condition 3</p>
                </div>
                <div  v-if="value_selected"  class="info-box border border-secondary mb-2 mt-1 " style="min-height: 55px;">
                  <p class="mt-4">Value {{ value_selected }} AED</p>
                </div> -->
          </div>
          <div class="info-box  justify-content-center  border border-secondary mb-2 col-md-10 ml-3 scroll"
            style="max-height: 450px;min-height: 450px;">
            <img src="../assets/Buyback_content/2 - Choose Brands & Product.svg" class="col-md-12" />
            <b-row class="d-flex justify-content-center notranslate">
              <b-col v-for="i in brandList" :key="i.id" class="mt-2" v-if="brand_selected">
                <a href="#">
                  <div class="info-box border border-secondary mb-2"
                    style="max-height: 150px;max-width: 150px;min-height: 150px;min-width: 150px"
                    @click="brand(i.id, i.name)">
                    <img :src="'data:image/png;base64,' + i.image" height="120" />
                    <br />
                    {{ i.name }}
                  </div>
                </a>
              </b-col>
            </b-row>
            <!-- <b-row class="d-flex justify-content-center" v-if="model_selected">
              <b-col v-for = "i in modelList2" :key="i.id" class="mt-2"> 
                <a href="#">
                  <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center" style="min-width: 180px"  @click="model_new(i.id,i.name)">
                   
                    {{ i.name }} Series
                </div>
                </a>
              </b-col>
            </b-row> -->

            <b-row class="d-flex justify-content-center notranslate" v-if="model_selected">
              <b-col v-if="!modelList2.empty && !modelList2.error" v-for="i in modelList2" :key="i.id" class="mt-2">
                <a href="#">
                  <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center"
                    style="min-width: 180px" @click="model_new(i.id, i.name)">
                    <p class="txt2">{{ i.name }} Series</p>
                  </div>
                </a>
              </b-col>
              <div v-else>
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <span class="mb-2">No Data Found</span>
                  <b-button class="explore_btn" @click="reloadPage">Explore More Brands</b-button>
                </div>
              </div>
            </b-row>
            <b-row class="d-flex justify-content-center notranslate" v-if="pro_selected">
              <b-col v-if="!modelPro.empty && !modelPro.error" v-for="i in modelPro" :key="i.id" class="mt-2">
                <a href="#">
                  <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center"
                    style="min-width: 180px" @click="pro_new(i.id, i.name)">
                    <p class="txt2">{{ i.name }}</p>
                  </div>
                </a>
              </b-col>
              <div v-else>
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <span class="mb-2">No Data Found</span>
                  <b-button class="explore_btn" @click="reloadPage">Explore More Brands</b-button>
                </div>
              </div>
            </b-row>
            <b-row class="d-flex justify-content-center notranslate" v-if="repair">
              <b-col v-if="!priceList.empty && !priceList.error" v-for="i in priceList" :key="i.id" class="mt-2">
                <a href="#">
                  <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center"
                    style="min-width: 180px" @click="grades(i.id, i.name)">
                    <p class="txt2">{{ i.name }} inches</p>
                  </div>
                </a>
              </b-col>
              <div v-else>
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <span class="mb-2">No Data Found</span>
                  <b-button class="explore_btn" @click="reloadPage">Explore More Brands</b-button>
                </div>
              </div>
            </b-row>
            <b-row class="d-flex justify-content-center notranslate" v-if="grade_selected2">
              <b-col v-if="!extraList.empty && !extraList.error" v-for="i in extraList" :key="i.id" class="mt-2">
                <a href="#">
                  <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center"
                    style="min-width: 180px" @click="grades2(i.id, i.name)">
                    <p class="txt2">{{ i.name }}</p>
                  </div>
                </a>
              </b-col>
              <div v-else>
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <span class="mb-2">No Data Found</span>
                  <b-button class="explore_btn" @click="reloadPage">Explore More Brands</b-button>
                </div>
              </div>
            </b-row>
            <b-row class="d-flex justify-content-center notranslate" v-if="grade_selected3">
              <b-col v-if="!extraList2.empty && !extraList2.error" v-for="i in extraList2" :key="i.id" class="mt-2">
                <a href="#">
                  <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center"
                    style="min-width: 180px" @click="grades3(i.id, i.name)">
                    <p class="txt2">{{ i.name }}</p>
                  </div>
                </a>
              </b-col>
              <div v-else>
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <span class="mb-2">No Data Found</span>
                  <b-button class="explore_btn" @click="reloadPage">Explore More Brands</b-button>
                </div>
              </div>
            </b-row>
            <b-row class=" d-flex justify-content-center" v-if="grade_selected">

              <b-col v-for="i in gradeList" :key="i.id" class="">
                <b-row style="flex-wrap: inherit !important;">
                  <b-col>
                    <div class="info-box3 txt border border-secondary " @click="checkout_grade('A', i.a, i.a1, i.a_id)"
                      style="max-height: 320px;max-width: 250px;min-height: 320px;min-width: 250px" v-if="i.a">
                      <h4 class="mt-3">Condition 1</h4>
                      <h6>{{ i.a }} {{ i.currency }} </h6>
                      <ul class="text-left mt-3 ml-4 mr-2" style="padding:0% ; ">
                        <li>
                          No Scratches


                        </li>
                        <li>
                          Body has minor scratches, No dents


                        </li>
                        <li>
                          Battery health 90% or Above


                        </li>
                        <li>
                          Fully functional


                        </li>
                        <li>
                          No Device Lock/Sim Lock


                        </li>
                        <li>
                          No missing parts (back cover, sim tray, buttons)


                        </li>
                        <li>
                          Face ID working


                        </li>
                      </ul>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="info-box3 txt border border-secondary" @click="checkout_grade('B', i.b, i.b1, i.b_id)"
                      style="max-height: 320px;max-width: 250px;min-height: 320px;min-width: 250px" v-if="i.b">
                      <h4 class="mt-3">Condition 2</h4>
                      <h6>{{ i.b }}  {{ i.currency }}</h6>
                      <ul class="text-left mt-3 mr-2  ml-4" style="padding:0% ;">
                        <li>
                          Screen having Scratches, open or swapped


                        </li>
                        <li>
                          Major scratches / dents on back cover and housing.


                        </li>
                        <li>
                          Battery health 80% or Above


                        </li>
                        <li>
                          Issues with either mic, speaker or buttons


                        </li>
                        <li>
                          No Device Lock/Sim Lock


                        </li>
                        <li>
                          No missing parts (back cover, sim tray, buttons)


                        </li>
                        <li>
                          Face ID working


                        </li>

                      </ul>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="info-box3 txt border border-secondary" @click="checkout_grade('C', i.c, i.c1, i.c_id)" 
                      style="max-height: 320px;max-width: 250px;min-height: 320px;min-width: 250px" v-if="i.c">
                      <h4 class="mt-3">Condition 3</h4>
                      <h6>{{ i.c }} {{ i.currency }}</h6>
                      <ul class="text-left mt-2 ml-4 mr-2" style="padding:0% ;">
                        <li>
                          LCD dots/ lines/ discoloration/Screen Burn, Screen Touch not working



                        </li>
                        <li>
                          Device cracked / Bent device


                        </li>
                        <li>
                          Battery health: Service indicator on


                        </li>
                        <li>
                          Any one of the following not working (touch ID, face ID, camera)


                        </li>
                        <li>
                          Device Lock/Sim Lock

                        </li>
                        <li>
                          Missing parts (back cover, sim tray or buttons)


                        </li>
                        <li>
                          Face ID not working


                        </li>
                      </ul>
                    </div>
                  </b-col>
                </b-row>

              </b-col>
            </b-row>

          </div>
          <div>
            <b-modal id="modal-prevent-closing" ref="modal" scrollable @show="resetModal" @hidden="resetModal" hide-footer
              hide-header @ok="handleOk">

              <div>
                <h2>Quotation Form</h2>
              </div>
              
              <!-- {{ selected3 }}i.id === selected3 -->
              <div class="p-2 " v-if="promo !== 'No Data Found'">
                <p class="" style="font-size: small;">FINAL TRADE-IN AMOUNT</p>
                <h5 v-if="selected3 !== 'Select a Device' && selected3 !== null" v-for="i in promo" :key="i">
                  <b v-if="selected3.includes(i.id)"> {{ parseInt(value_selected) + parseInt(i.price) }}</b>
                </h5>
                <h5 v-if="selected3 === null"> {{ value_selected }}  {{ currency }}</h5>
                <h5 v-if="selected3 !== 'Select a Device' && selected3 !== null" v-for="i in promo" :key="i">
                  <p class="" style="font-size: small;" v-if="selected3.includes(i.id)">The final amount is based on the
                    result provided for {{ pro_active }} of {{ value_selected }} + {{ i.price }} (Offer Price)</p>
                </h5>
                <h5 v-if="selected3 === null">
                  <p class="" style="font-size: small;">The final amount is based on the result provided for {{ pro_active
                  }}</p>
                </h5>
              </div>
              <div v-else>
                <p class="" style="font-size: small;">FINAL TRADE-IN AMOUNT</p>
                <h5> {{ value_selected }}</h5>
                <h5>
                  <p class="" style="font-size: small;">The final amount is based on the result provided for {{ pro_active
                  }}</p>
                </h5>
              </div>
              <div class=" ">
                <div class="d-flex  justify-content-around">
                  <div class="p-2">
                    <label class="text-left"></label>
                    <b-form-input placeholder="Name" :state="nameState" v-model="userName" class="inp"></b-form-input>
                  </div>

                  <div class="p-2">
                    <label class="text-left"></label>
                    <b-form-input placeholder="Email" :state="emailState" v-model="userEmail"
                      class="inp"></b-form-input>
                  </div>
                </div>
                <!-- <div class="d-flex  justify-content-center">
                      <div class="">
                        <label class="text-left"></label>
                        <b-form-input placeholder="Email" :state="emailState" v-model="userEmail"
                          class="inp"></b-form-input>
                      </div>
                    </div> -->
                <div class="d-flex  justify-content-center">
                  <div class="p-2">
                    <label class="text-left"></label>

                    <b-form-input type="text"
                      onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
                      max="999999999" placeholder="5XXXXXXXXXXX" :state="mobileState" v-model="userMobile"
                      class="input-login inp" :formatter="mobileFormatter"></b-form-input>
                  </div>
                  <div class="p-2">
                    <label class="text-left"></label>

                    <b-form-input type="text"
                      onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
                      max="999999999" placeholder="IMEI/Serial No" :state="imeiState" v-model="text5"
                      class="input-login inp" :formatter="imeiFormatter"></b-form-input>
                  </div>

                </div>
                <div>
                  <VueUploadMultipleImage @updateData2="handleDataUpdate2" :model_active="'Tablets'"/>
                </div>
                <div class="d-flex justify-content-center mt-3" v-if="emailState && nameState && mobileState && text5">
                  <b-button @click="send_quote()">
                    Confirm
                  </b-button>
                </div>
              </div>
              <!-- </b-collapse> -->
              <!-- </b-navbar> -->
            </b-modal>
            <b-modal id="thankyou_model" centered title="" footer-class="border-0 mx-auto" header-class="border-0"
              no-close-on-backdrop="false" no-close-on-esc="false" hide-header-close='true' header-close-content="false"
              hide-footer hide-header>

              <b-col class="wraper">
                <div class="container-fluid">
                  <div class="row justify-content-center">
                    <div class="col text-center">
                      <img src="../assets/home/mobile/Redington Logo Highest Quality (PNG) 3.png" alt="" class="r3_logo">
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col text-center">
                      <img src="../assets/home/mobile/Tick.svg" alt="" class="tick_image">
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col class="wraper text-center mt-3">
                <span>
                  Thank you for choosing<br> Redington Service
                </span>
              </b-col>
              <b-col class="d-flex justify-content-center align-items-center mt-2">
                <b-button class="exp_btn" @click="exportToPDF()">
                  Download Quotation
                </b-button>
              </b-col>
              <b-col class="d-flex justify-content-center align-items-center mt-2">
                <b-button class="exp_btn" @click="refresh_page()">
                  Close
                </b-button>
              </b-col>
              <template #modal-footer>
                <div></div>
              </template>
            </b-modal>
          </div>

        </b-container>

      </div>

    </div>

    <div>
      <div class="mt-3 pb-2 print d-lg-none">
        <!-- <h2 class="mb-3 info-box" style="color: #39A536;"><b>Thank you</b></h2> -->
        <div class="d-flex justify-content-center mt-3" id="element-to-convert">
          <div>
            <div class="home">
              <!-- <img src="../assets/Buyback_content/5 - Thank you.svg"/> -->

              <div class=" mb-2 ">

                <div>
                  <div class="d-flex justify-content-between">
                    <div v-if="return_data">
                      <p><b>Date:</b>{{ return_data[0].date }}</p>
                    </div>
                    <div v-if="return_data">
                      <p><b>Reference#</b> {{ return_data[0].id }}</p>
                    </div>

                  </div>
                  <h4 class="text-center ">Device Quotation</h4>

                  <p>
                    Dear Customer,
                  </p>
                  <p>
                    This is just an estimation. Please visit any Redington Retail Partner in the {{ country }} to complete
                    the transaction. The actual price may vary as per the actual grading of the device.
                  </p>
                </div>

                <div class="text-left mt-3">
                  <p class=" notranslate"><b>Name: </b>{{ userName }}</p>
                  <p class="notranslate"><b>Email: </b>{{ userEmail }}</p>
                  <p class="notranslate"><b>Contact: </b>{{ userMobile }}</p>
                  <p class="notranslate"><b>country: </b>{{ country }}</p>
                  <p class="notranslate" v-if="return_data"><b>partner: </b>{{ return_data[0].partner }}</p>
                  <p class="notranslate" v-if="grade_selected === 'A'"><b>Grade: </b>Condition 1</p>
                  <p class="notranslate" v-if="grade_selected === 'B'"><b>Grade: </b>Condition 2</p>
                  <p class="notranslate" v-if="grade_selected === 'C'"><b>Grade: </b>Condition 3</p>
                                    <p class="notranslate"><b>Price: </b>{{ value_selected }}</p>
                  <p class="notranslate" v-if="return_data"><b>Product Description: </b>{{ return_data[0].device }}</p>

                  <div>

                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="d-lg-none">
      <div class="info-box  justify-content-center  border border-secondary mb-2 col-md-12 scroll">
        <img src="../assets/Buyback_content/mobile/Step 1.svg" class="col-md-12" />
        <b-row class="d-flex justify-content-center notranslate">
          <b-col v-for="i in brandList" :key="i.id" class="mt-2" v-if="brand_selected">
            <a href="#">
              <div class="info-box border border-secondary mb-2"
                style="max-height: 130px;max-width: 130px;min-height: 130px;min-width: 130px"
                @click="brand(i.id, i.name)">
                <img :src="'data:image/png;base64,' + i.image" height="90" />
                <br />
                {{ i.name }}
              </div>
            </a>
          </b-col>
        </b-row>
        <!-- <b-row class="d-flex justify-content-center" v-if="model_selected">
              <b-col v-for = "i in modelList2" :key="i.id" class="mt-2"> 
                <a href="#">
                  <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center" style="min-width: 180px"  @click="model_new(i.id,i.name)">
                   
                    {{ i.name }} Series
                </div>
                </a>
              </b-col>
            </b-row> -->

        <b-row class="d-flex justify-content-center notranslate" v-if="model_selected">
          <b-col v-if="!modelList2.empty && !modelList2.error" v-for="i in modelList2" :key="i.id" class="mt-2">
            <a href="#">
              <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center"
                style="min-height: 80px;min-width: 130px" @click="model_new(i.id, i.name)">
                <p class="txt2">{{ i.name }} Series</p>
              </div>
            </a>
          </b-col>
          <div v-else>
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <span class="mb-2">No Data Found</span>
                  <b-button class="explore_btn" @click="reloadPage">Explore More Brands</b-button>
                </div>
          </div>
        </b-row>
        <b-row class="d-flex justify-content-center notranslate" v-if="pro_selected">
          <b-col v-if="!modelPro.empty && !modelPro.error" v-for="i in modelPro" :key="i.id" class="mt-2">
            <a href="#">
              <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center"
                style="min-height: 80px;min-width: 130px" @click="pro_new(i.id, i.name)">
                <p class="txt2">{{ i.name }}</p>
              </div>
            </a>
          </b-col>
          <div v-else>
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <span class="mb-2">No Data Found</span>
                  <b-button class="explore_btn" @click="reloadPage">Explore More Brands</b-button>
                </div>
          </div>
        </b-row>
        <b-row class="d-flex justify-content-center notranslate" v-if="repair">
          <b-col v-if="!priceList.empty && !priceList.error" v-for="i in priceList" :key="i.id" class="mt-2">
            <a href="#">
              <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center"
                style="min-height: 80px;min-width: 130px" @click="grades(i.id, i.name)">
                <p class="txt2">{{ i.name }} inches</p>
              </div>
            </a>
          </b-col>
          <div v-else>
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <span class="mb-2">No Data Found</span>
                  <b-button class="explore_btn" @click="reloadPage">Explore More Brands</b-button>
                </div>
          </div>
        </b-row>
        <b-row class="d-flex justify-content-center notranslate" v-if="grade_selected2">
          <b-col v-if="!extraList.empty && !extraList.error" v-for="i in extraList" :key="i.id" class="mt-2">
            <a href="#">
              <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center"
                style="min-height: 80px;min-width: 130px" @click="grades2(i.id, i.name)">
                <p class="txt2">{{ i.name }}</p>
              </div>
            </a>
          </b-col>
          <div v-else>
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <span class="mb-2">No Data Found</span>
                  <b-button class="explore_btn" @click="reloadPage">Explore More Brands</b-button>
                </div>
          </div>
        </b-row>
        <b-row class="d-flex justify-content-center notranslate" v-if="grade_selected3">
          <b-col v-if="!extraList2.empty && !extraList2.error" v-for="i in extraList2" :key="i.id" class="mt-2">
            <a href="#">
              <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center"
                style="min-height: 80px;min-width: 130px" @click="grades3(i.id, i.name)">
                <p class="txt2">{{ i.name }}</p>
              </div>
            </a>
          </b-col>
          <div v-else>
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <span class="mb-2">No Data Found</span>
                  <b-button class="explore_btn" @click="reloadPage">Explore More Brands</b-button>
                </div>
          </div>
        </b-row>
        <b-row class=" d-flex justify-content-center" v-if="grade_selected">

          <b-col v-for="i in gradeList" :key="i.id" class="">
            <b-row>
              <b-col>
                <div class="info-box3 txt border border-secondary "
                  style="max-height: 320px;max-width: 250px;min-height: 320px;min-width: 250px" v-if="i.a"
                  @click="checkout_grade('A', i.a, i.a1, i.a_id)">
                  <h4 class="mt-3">Condition 1</h4>
                  <h6>{{ i.a }}  {{ i.currency }}</h6>
                  <ul class="text-left mt-3 ml-4 mr-2" style="padding:0% ; ">
                    <li>
                      No Scratches


                    </li>
                    <li>
                      Body has minor scratches, No dents


                    </li>
                    <li>
                      Battery health 90% or Above


                    </li>
                    <li>
                      Fully functional


                    </li>
                    <li>
                      No Device Lock/Sim Lock


                    </li>
                    <li>
                      No missing parts (back cover, sim tray, buttons)


                    </li>
                    <li>
                      Face ID working


                    </li>
                  </ul>
                </div>
              </b-col>
              <b-col>
                <div class="info-box3 txt border border-secondary mt-3"
                  style="max-height: 320px;max-width: 250px;min-height: 320px;min-width: 250px" v-if="i.b"
                  @click="checkout_grade('B', i.b, i.b1, i.b_id)">
                  <h4 class="mt-3">Condition 2</h4>
                  <h6>{{ i.b }}  {{ i.currency }}</h6>
                  <ul class="text-left mt-3 mr-2  ml-4" style="padding:0% ;">
                    <li>
                      Screen having Scratches, open or swapped


                    </li>
                    <li>
                      Major scratches / dents on back cover and housing.


                    </li>
                    <li>
                      Battery health 80% or Above


                    </li>
                    <li>
                      Issues with either mic, speaker or buttons


                    </li>
                    <li>
                      No Device Lock/Sim Lock


                    </li>
                    <li>
                      No missing parts (back cover, sim tray, buttons)


                    </li>
                    <li>
                      Face ID working


                    </li>

                  </ul>
                </div>
              </b-col>
              <b-col>
                <div class="info-box3 txt border border-secondary mt-3 mb-5"
                  style="max-height: 320px;max-width: 250px;min-height: 320px;min-width: 250px" v-if="i.c" 
                  @click="checkout_grade('C', i.c, i.c1, i.c_id)">
                  <h4 class="mt-3">Condition 3</h4>
                  <h6>{{ i.c }} {{ i.currency }}</h6>
                  <ul class="text-left mt-2 ml-4 mr-2" style="padding:0% ;">
                    <li>
                      LCD dots/ lines/ discoloration/Screen Burn, Screen Touch not working



                    </li>
                    <li>
                      Device cracked / Bent device


                    </li>
                    <li>
                      Battery health: Service indicator on


                    </li>
                    <li>
                      Any one of the following not working (touch ID, face ID, camera)


                    </li>
                    <li>
                      Device Lock/Sim Lock

                    </li>
                    <li>
                      Missing parts (back cover, sim tray or buttons)


                    </li>
                    <li>
                      Face ID not working


                    </li>
                  </ul>
                </div>
              </b-col>
            </b-row>

          </b-col>
        </b-row>

      </div>

    </div>
  </div>
</template>
    
<script>
import api from '../api'
import Header from '../components/Header2.vue'
import VueUploadMultipleImage from '../components/VueUploadMultipleImage4.vue'
import OtpInput from "otp-input-vue2";
import { BIconChevronUp, BIconChevronDown } from 'bootstrap-vue'
import html2pdf from "html2pdf.js";

export default {
  name: 'LeftBar',
  props: ['partner', 'country','tablet_cate_id'],
  components: {
    Header,
    VueUploadMultipleImage,
    OtpInput,
    BIconChevronUp,
    BIconChevronDown,
    html2pdf
  },
  data() {
    return {
      currency: '',

      trans: null,
      promo: null,
      name2: '',
      id: null,
      id2: this.$route.params.id,
      promo_device: [],

      name2State: null,
      status: 'not_accepted',
      selected: [], // Must be an array reference!
      options: [
        { text: 'Quality and Eligibility', value: 'Quality' },
        { text: 'Sim Card Removal', value: 'Sim' },
        { text: 'Data Wiped', value: 'Data' },
        { text: 'Find My Device', value: 'Find' }
      ],
      selected2: null,
      selected3: null,
      options2: [
        { value: null, text: 'New Device Model.' },
        { value: 'a', text: 'iPhone 13' },
        { value: 'b', text: 'iPhone 13 Pro' },
        { value: 'c', text: 'iPhone 13 Pro Max' },
      ],
      options3: [
        { value: null, text: 'Select Payment' },
        { value: 'a', text: 'Cash' },
        { value: 'b', text: 'Card' }
      ],
      sms_id: null,
      C: null,
      grade_selected: null,
      value_selected: null,
      brand_selected: true,
      brand_active: null,
      brand_id: null,
      model_selected: false,
      model_active: null,
      model_id: null,
      pro_selected: false,
      pro_active: null,
      pro_id: null,
      grade_selected: false,
      grade_active: null,
      grade_id: null,
      grade_selected2: false,
      grade_active2: null,
      grade_id2: null,
      grade_selected3: false,
      grade_active3: null,
      grade_id3: null,
      return_data: null,
      vendor: null,
      userName: this.$store.state.signUpForm ? this.$store.state.signUpForm.name : '',
      useradd: this.$store.state.signUpForm ? this.$store.state.signUpForm.useradd : '',
      userEmail: this.$store.state.signUpForm ? this.$store.state.signUpForm.email : '',
      userMobile: this.$store.state.signUpForm ? this.$store.state.signUpForm.mobile : '',
      text5: this.$store.state.signUpForm ? this.$store.state.signUpForm.text5 : '',
      return_data: null,
      images_phones: null
    }
  },
  computed: {
    Location: function () {
      return this.$store.state.user.location
    },
    nameState() {
      return this.userName.length > 3 ? true : false
    },
    addState() {
      return this.useradd.length > 4 ? true : false
    },
    emailState() {
      if (this.userEmail.length < 5 || this.userEmail.length) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(this.userEmail).toLowerCase())
      } else {
        return null
      }
    },

    mobileState: function () {
      if (this.userMobile.length < 9 || this.userMobile.length) {
        return this.userMobile.length === 9
      } else {
        return null
      }
    },
    imeiState() {
      if (this.text5.length < 5 || this.text5.length) {
        const re = /^[0-9a-zA-Z]+$/
        return re.test(String(this.text5).toLowerCase())
      } else {
        return null
      }
    },
    brandList: function () {
      return this.$store.state.brandList
    },
    modelList: function () {
      return this.$store.state.modelList
    },
    modelList2: function () {

      return this.$store.state.modelList2
    },
    modelPro: function () {
      return this.$store.state.modelPro
    },
    modelGen: function () {
      return this.$store.state.modelGen
    },
    modelRam: function () {
      return this.$store.state.modelRam
    },
    romList: function () {
      return this.$store.state.romList
    },
    priceList: function () {
      return this.$store.state.deductionData
    },
    extraList: function () {
      return this.$store.state.extra
    },
    extraList2: function () {
      return this.$store.state.extra2
    },
    gradeList: function () {
      return this.$store.state.gradesData
    },
    defects: function () {
      return this.$store.state.defectList
    }
  },
  mounted() {
    this.$store.commit('CLEAR_SELECTION')
  },

  methods: {
    handleDataUpdate2(newData) {
      this.images_phones = newData;
    },
    exportToPDF() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 1,
        filename: "Quotation.pdf",
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'a5', orientation: 'portrait' }
      });
    },
    getPromo: function (id) {
      let currentDate = new Date().toJSON().slice(0, 10);
      const inputs = {
        
            'id': this.$store.state.user.uid,
            'device': id,
            'date': currentDate
         
      }
      api
        .request(process.env.VUE_APP_Get_Promotion_Buyback, inputs)
        .then((response) => {
          var data = response.data
          if (data.errorcode) {
            return
          }
          if (data.result) {
            console.log(data.result)
            this.promo = data.result
            for (let i = 0; i < this.promo.length; i++) {
              for (let j = 0; j < this.promo[i].device.length; j++) {
                this.promo_device.push(this.promo[i].device[j]);
              }
            }
          }
        })
        .catch((error) => {
          // console.log('What happened? ' + error)
        })
    },
    reloadPage() {
          location.reload();
        },
    uploadImageSuccess(formData, index, fileList) {

    },
    myFunction(value) {
      // console.log(value)
    },
    beforeRemove(index, done, fileList) {
      // console.log('index', index, fileList)
      var r = confirm("remove image")
      if (r == true) {
        done()
      } else {
      }
    },
    editImage(formData, index, fileList) {
      // console.log('edit data', formData, index, fileList)
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    mobileFormatter: function (e) {
      return String(e).substring(0, 9)
    },
    imeiFormatter: function (e) {
      return String(e).substring(0, 20)
    },
    checkout_grade: function (grade, price, vendor,id) {
      this.id = id

      this.trans = this.$refs.myDiv.innerHTML
      this.grade_selected = grade
      this.value_selected = price
      this.vendor = vendor
      if (this.id2) {
        this.$bvModal.show('modal-prevent-closing2')
      } else {
        this.$bvModal.show('modal-prevent-closing')
      }
    },
    cate: function (cate_id, name) {
      this.category_selected = false
      this.category_active = name
      this.category_id = cate_id
      this.getBrands2(cate_id)
    },
    brand: function (cate_id, name) {
      this.brand_active = name
      this.brand_id = cate_id
      this.getModels(name)

    },
    model_new: function (cate_id, name) {
      this.model_active = name
      this.model_id = cate_id
      this.getYear(name)
    },
    pro_new: function (cate_id, name) {
      this.pro_active = name
      this.pro_id = cate_id
      this.getDeductionData(name)
    },
    grades: function (cate_id, name) {
      this.grade_active = name
      this.grade_id = cate_id
      this.getCellData(name)
    },
    grades2: function (cate_id, name) {
      this.grade_active2 = name
      this.grade_id2 = cate_id
      this.getCellData2(name)
    },
    grades3: function (cate_id, name) {
      this.grade_active3 = name
      this.grade_id3 = cate_id
      this.getGradeData(cate_id)
    },
    getModels: function (id) {
      const inputs = {
       
            'cate': 'Tablets',
            'brand': this.brand_active,
            'location': this.country
       
      }
      api
        .request(process.env.VUE_APP_Get_Series_data, inputs)
        .then((response) => {
          var data = response.data
          if (data.errorcode) {
            return
          }
          if (data.result) {
            this.$store.commit('SET_MODEL_LIST2', data.result)
            this.brand_selected = false
            this.model_selected = true
          }
        })
        .catch((error) => {
          // console.log('What happened? ' + error)
        })
    },
    getYear: function (id) {
      const inputs = {
       
            'cate': 'Tablets',
            'brand': this.brand_active,
            'series': id,
            'location': this.country

        
      }
      api
        .request(process.env.VUE_APP_Get_Model_Data, inputs)
        .then((response) => {
          var data = response.data
          if (data.errorcode) {
            return
          }
          if (data.result) {
            this.$store.commit('SET_MODEL_Proc', data.result)
            this.model_selected = false
            this.pro_selected = true
          }
        })
        .catch((error) => {
          // console.log('What happened? ' + error)
        })
    },
    getDeductionData: function (id) {
      const inputs = {
       
            'cate': 'Tablets',
            'brand': this.brand_active,
            'series': this.model_active,
            'model': id,
            'location': this.country
         
      }
      api
        .request(process.env.VUE_APP_Get_Rom_Data, inputs)
        .then((response) => {
          var data = response.data
          if (data.errorcode) {
            return
          }
          if (data.result) {
            // console.log('sdsds')
            this.$store.commit('SET_DEDUCTION_DATA', data.result)
            this.pro_selected = false
            this.repair = true

          }
        })
        .catch((error) => {
          // console.log('What happened? ' + error)
        })
    },
    getCellData: function (id) {
      const inputs = {
       
            'cate': 'Tablets',
            'brand': this.brand_active,
            'series': this.model_active,
            'model': this.pro_active,
            'screen': id,
            'location': this.country
         
      }
      api
        .request(process.env.VUE_APP_Get_Rom_Data, inputs)
        .then((response) => {
          var data = response.data
          if (data.errorcode) {
            return
          }
          if (data.result) {
            // console.log('sdsds')
            this.$store.commit('SET_Extra_LIST', data.result)

            this.repair = false
            this.grade_selected2 = true

          }
        })
        .catch((error) => {
          // console.log('What happened? ' + error)
        })
    },
    getCellData2: function (id) {
      const inputs = {
        
            'cate': 'Tablets',
            'brand': this.brand_active,
            'series': this.model_active,
            'model': this.pro_active,
            'screen': this.grade_active,
            'cell': id,
            'location': this.country
         
      }
      api
        .request(process.env.VUE_APP_Get_Rom_Data, inputs)
        .then((response) => {
          var data = response.data
          if (data.errorcode) {
            return
          }
          if (data.result) {
            // console.log('sdsds')
            this.$store.commit('SET_Extra_LIST2', data.result)

            this.grade_selected2 = false
            this.grade_selected3 = true

          }
        })
        .catch((error) => {
          // console.log('What happened? ' + error)
        })
    },

    getGradeData: function (id) {
      let currentDate = new Date().toJSON().slice(0, 10);
      const inputs = {
        'id': id,
        'user': this.$store.state.user.uid,
        'current_date': currentDate,
        'country': this.Location

          
      }
      api
        .request(process.env.VUE_APP_Get_Price_Data, inputs)
        .then((response) => {
          var data = response.data
          if (data.errorcode) {
            return
          }
          if (data.result) {
            // console.log('sdsds')
            this.$store.commit('SET_GRADES_DATA', data.result)
            // this.id = data.result[0].id
            this.currency = data.result[0].currency

            this.grade_selected3 = false
            this.grade_selected = true
            // this.getPromo(id)

          }
        })
        .catch((error) => {
          // console.log('What happened? ' + error)
        })
    },

    getBrands2: function () {
      // if (!this.$store.state.selectedCategory) {
      //   return false
      // }
      const inputs = {
        
            'cate_id': parseInt(this.tablet_cate_id),
            'location': this.country
          
      }
      const url = 'dataset'
      api
        .request(process.env.VUE_APP_Get_Brand_Data, inputs)
        .then((response) => {
          var data = response.data
          if (data.errorcode) {
            // this.response = data.errormessage
            return
          }
          if (data.result) {
            this.$store.commit('SET_BRAND_LIST', data.result)
          }
        })
        .catch((error) => {
          // console.log('What happened? ' + error)
        })
    },
    send_quote: function () {
      if (!this.images_phones) {
        this.$bvToast.toast('We are not able to process this request right now. Please upload the Device Images', {
          title: 'Device Images not Found',
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        })
        return
      }
      const inputs = {

        'name': this.userName,
        'user': this.$store.state.user.uid,
        'email': this.userEmail,
        'num': this.userMobile,
        'imei': this.text5,
        'device': this.id,
        'price': this.value_selected,
        'grade': this.grade_selected,
        'partner': this.partner,
        'vendor': this.vendor,
        'date': new Date().toLocaleString(),
        'attachment1': this.images_phones[0].path.replace('data:', '').replace(/^.+,/, ''),
        'attachment2': this.images_phones[1].path.replace('data:', '').replace(/^.+,/, ''),
        'type1': 'png',
        'file_type1': 'one',
        'type2': 'png',
        'file_type2': 'two',

      }
      const url = 'dataset'
      api
        .request(process.env.VUE_APP_Create_Quotation, inputs)
        .then((response) => {
          const data = response.data.result
          if (response.data.result &&response.data.result.error) {
            this.$bvToast.toast('We are not able to process this request right now. Please try again later.', {
              title: 'Error',
              variant: 'danger',
              toaster: 'b-toaster-top-right'
            })
          } else {
            this.$router.push({ name: 'TrackQuote', params: { 'id': data[0].id } })
          }

        })
        .catch((error) => {
          // console.log('What happened? ' + error)
        })
    },
    clearSelection: function () {
      this.$store.commit('CLEAR_SELECTION')
    },
    visit: function (sel) {
      if (sel === 'cate') {
        this.$router.push({ name: 'ReuseSell' })
      } else {
        window.location.reload()
      }
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    refresh_page() {
      window.location.reload()

    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvent) {
      this.getOTP()
    },
    handleOk2(bvModalEvent) {
      this.$bvModal.hide('modal-prevent-closing-otp')
      this.$bvModal.show('modal-prevent-closing2')
      // this.getOTP()
    },
    handleOnComplete(value) {
      // console.log('OTP completed: ', value);
    },
    handleOnChange(value) {
      // console.log('OTP changed: ', value);
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }
      this.submittedNames.push(this.name)
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing')
      })
    },

  },
  mounted() {
    this.getBrands2()

  }
}
</script>
    
<style scoped>
.icon-left {
  position: absolute;
  right: -20px;
  top: 0;
}

a {
  color: #A9A9A9 !important;
}

/* .selection-box-shadow {
      animation: mymove 3s infinite;
    } */

@keyframes mymove {
  50% {
    box-shadow: 0px 0px 15px 0px rgba(0, 193, 56, 0.75);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 193, 56, 0.75);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 193, 56, 0.75);
  }
}

.aa {
  text-align: left;
  margin-left: 20px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: -10px;
  background: #FFFFFF;
  width: fit-content;
  position: absolute;
  display: block;
  z-index: 2;
  border-radius: 5px;
}

.details {
  padding: 20px;
  margin: 10px;
  background: #FFFFFF;
  border: 1px solid rgba(63, 63, 68, 0.005);
  box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
  border-radius: 7px;
}

.details2 {
  padding: 10px;
  background: #FFFFFF;
  border: 1px solid rgba(63, 63, 68, 0.005);
  box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
  border-radius: 7px;
}

.details2 b {
  margin-left: 20px;
}

.list-group-item {
  color: black !important;
}

.list-group-item.active {
  background-color: rgb(56, 173, 56) !important;
  border-color: rgb(56, 173, 56) !important;
}

.info-box {
  margin-right: 3px;
  margin-left: 3px;
  padding: 3px;
  border-radius: 7px;
  border-color: #e0e0ec !important;
  text-align: center;
  background: #FFFFFF;
  border: 1px solid rgba(63, 63, 68, 0.005);
  box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
  border-radius: 7px;
}

.info-box4 {
  margin-right: 3px;
  margin-left: 3px;
  padding: 3px;
  border-radius: 7px;
  border-color: #e0e0ec !important;
  text-align: center;
  background: #FFFFFF;
  border: 1px solid rgba(63, 63, 68, 0.005);
  box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
  border-radius: 7px;
}

.info-box4:hover {
  background-color: green;
  color: white;
}

.info-box2 {
  margin-right: 3px;
  margin-left: 3px;
  padding: 3px;
  border-radius: 7px;
  border-color: green !important;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

}

.info-box2:hover {
  background-color: green;
  color: white;
}

.info-box3 {
  border-color: green !important;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.info-box3:hover {
  background-color: green;
  color: white;
}

.otp-inputnew {
  width: 45px;
  height: 40px;
  padding: 5px;
  margin: 0 10px !important;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-style: dashed;
  text-align: center;
  margin-right: 50px;
}

/* Background colour of an input field with value */
.otp-inputnew.is-complete {
  background-color: #e4e4e4;
}

.otp-inputnew::-webkit-inner-spin-button,
.otp-inputnew::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.otp-title {
  color: #3FAF2B;
  font-weight: bold;
  font-size: 24px !important;
}

.col {
  flex-grow: 0;
}

.scroll {
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}

.txt {
  font-size: smaller;
}

.aa {
  text-align: left;
  margin-left: 10px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
  background: #FFFFFF;
  width: fit-content;
  position: absolute;
  display: block;
  font-size: smaller;
  font-weight: lighter;
  z-index: 2;
  border-radius: 5px;
}

.btnn:hover {
  background-color: #3FAF2B;
  border-color: #3FAF2B;
}

footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: #FFFFFF !important;
  border-radius: 15px 15px 0px 0px;
  justify-content: center;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.5) !important;
}

.aaa {
  color: black !important;
}
.explore_btn{
      background-color: rgb(56, 173, 56) !important;
      color: #fff;
      border: none;
    }

.aaa:hover {
  color: #FFFFFF !important;
}

.a {
  color: black !important;
  ;
}

.txt2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

a {
  color: black !important;
}
</style>
    