export default {
  taskStage: 0,
  searching: '',
  baseURL: '',
  serverURI: '',
  user: null,
  partner: null,
  token: null,
  userInfo: {},
  activeService: null,
  activeServiceType: null,
  selectedCategory: null,
  selectedBrand: null,
  selectedModel: null,
  selectedROM: null,
  selectedAddress: null,
  activeAddress: null,
  selectedDeliverAddress: null,
  categoryList: [],
  brandList: [],
  modelList: [],
  defectList: [],
  romList: [],
  selectedDefects: [],
  collectMethod: null,
  deliverMethod: null,
  collectBranch: null,
  deliverBranch: null,
  checkoutStage: 'login',
  premiumList: [],
  recoveryOptions: [],
  deductionData: null,
  selectedGrade: null,
  selectedRecoveryOption: null,
  warrentyList: [
    {
      'id': 1,
      'title': '1 Year Warranty',
      'description': 'ADLD + Theft',
      'type': 'addld_theft'
    },
    {
      'id': 2,
      'title': '2 Years Warranty',
      'description': '1 year + extended',
      'type': 'additional_warranty'
    }
  ],
  selectedWarrenties: [],
  selectedPremium: null,
  taskList: [{
    step: 1,
    label: 'Choose Service'
  },
  {
    step: 2,
    label: 'Product Selection'
  },
  {
    step: 3,
    label: 'Select Defects'
  },
  {
    step: 4,
    label: 'Service Delivery Request'
  },
  {
    step: 5,
    label: 'Estimation'
  },
  {
    step: 6,
    label: 'Approval'
  },
  {
    step: 7,
    label: 'Order No Created'
  },
  {
    step: 8,
    label: 'Work in Progress'
  },
  {
    step: 9,
    label: 'Final Status'
  },
  {
    step: 10,
    label: 'Service Delivery and Payment'
  }],
  branchList: [],
  activeProduct: null,
  activeOrder: null,
  cartItems: [],
  signUpForm: null,
  isLoading: false,
  productList: [],
  productListTimeStamp: false,
  mobileVerified: null,
  shippingCharge: null
}
