<template>
  <div id="splash-screen">
      <div id="center-view">
        <div class="dots"></div>
        <div class="dots"></div>
        <div class="dots"></div>
        <div class="dots"></div>
        <div class="dots"></div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'SplashScreen'
}
</script>

<style scoped>
#splash-screen {
    background-color: rgba(255, 255, 255, 0.75);
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;

}
#center-view {
    position: absolute;
    top: 50%;
    left: 35%;
}

@keyframes scaling{
  0%, 100%{
    transform: scale(0.2);
    background-color: #5af055;
  }
  40%{
    transform: scale(1);
    background-color: #47c943 ;
  }
  50%{
    transform: scale(1);
    background-color: #39A536 ;
  }
}

.dots {
    height: 40px;
    width: 40px;
    border-radius:50%;
    transform: scale(0);
    animation: scaling 2.5s ease-in-out infinite;
    display: inline-block;
    margin:.5rem;
}

.dots:nth-child(0){
  animation-delay:0s;
}
.dots:nth-child(1){
  animation-delay:0.2s;
}
.dots:nth-child(2){
  animation-delay:0.4s;
}
.dots:nth-child(3){
  animation-delay:0.6s;
}
.dots:nth-child(4){
  animation-delay:0.8s;
}
.dots:nth-child(5){
  animation-delay:1s;
}
</style>
