<template>
  <b-table-simple striped hover responsive>
    <b-thead>
      <b-tr>
        <b-th>Reference ID</b-th>
        <b-th>Device</b-th>
        <b-th>Invoice Date</b-th>
        <b-th>Claim Date</b-th>
        <b-th>Condition</b-th>
        <b-th>Price</b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <OrderLine v-for="(item, index) in orderList" :key="index" :item="item"/>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import OrderLine from './AbbOrderLine.vue'
export default {
  name: 'OrderTable',
  props: ['orderList'],
  components: {
    OrderLine
  },
  data () {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
</style>
