<template>
    <div>
      <header>      <Header/>.

          <!-- <ServiceBar :items="services" /> -->
          <!-- <ServiceBar :items="getServiceTypes" /> -->
        </header>
      <div>
        <b-container class="mt-5 ">
            <b-col v-if="!sended">
                <p class="title text-center mt-3">Emter your registered email address</p>
                <h5 class=" text-center mt-3 d-none d-lg-block">
                  If you have forgotten your password, don't worry.<br/>Please enter your registered email address and we will send you a link to reset your password.</h5>
                  <h6 class=" text-center mt-3 d-lg-none">
                  If you have forgotten your password, don't worry. Please enter your registered email address and we will send you a link to reset your password.</h6>
                <b-form inline class="justify-content-center mt-4 ">
                  <label class="mr-3" style="color: #37a531;">Email ID</label>
                    <b-form-input  v-model="text" placeholder="Enter your Email"></b-form-input>
                  </b-form>
                  <b-button variant="success" style="font-size: large;" class="mt-4" @click="forgotpassword()">Submit</b-button>
                </b-col>

                <b-col v-if="sended">
                <p class="title text-center mt-3">Email Send Successfully!</p>
                <h5 class=" text-center mt-3 d-none d-lg-block">An email is send to your account for verification</h5>
                <h6 class=" text-center mt-3 d-lg-none">
                  An email is send to your account for verification</h6>
                <b-button variant="success" style="font-size: large;" class="mt-4" @click="Homepage()">Go Back</b-button>
                </b-col>
        </b-container>
      </div>
      
      <Footer/>
    </div>
  </template>
  
  <script>
  import api from '../api'
  import Header from '@/components/Header.vue'
  // import ServiceBar from '@/components/ServiceBar.vue'
  import Footer from '@/components/Footer.vue'

  export default {
    name: 'Profile',
    data () {
      return {
        text: '',
        sended: false
      }
    },
    props: {
    },
    computed: {
      
    },
    components: {
      Header,
      // ServiceBar,
      Footer
    },
    methods: {
    forgotpassword: function () {
        this.signUpMessage = ''
        const inputs = {
            
         
                'email': this.text,'link': true
                // 'barcode': 'welifwulkv','password': 'abcdef'
             
        }
        console.log('User Creation input', inputs)
        api
            .request(process.env.VUE_APP_Forgot_Password, inputs)
            .then((response) => {
            console.log('API Response:', response)
            var data = response.data
            console.log('API Response Data:', data)
            if (data.errorcode) {
                this.response = data.errormessage
                return
            }
            if (data.result) {
              this.sended = true
            }
            })
            .catch((error) => {
            console.log('What happened? ' + error)
            })
        },
    Homepage: function () {
        this.$router.push({ name: 'LandingPage' })
   }
    },
    mounted () {
    }
  }
  </script>
  
  <style scoped>
  .title{
    color: #37a531;
    font-size: 30px;
    font-family: 'Avenir Black', sans-serif;
  }
  
  </style>
  