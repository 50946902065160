<template>
  <div>
    <hr/>
    <b-container fluid class="footer d-none d-lg-block">
      <b-row class="footer-gradient">
          <!-- <img width="100%" src="@/assets/imgs/footer/footer-gradient.png" alt=""> -->
      </b-row>
      <!-- <b-row class="back-to-top">
        <b-col class="py-2" @click="scrollTop()">
          Back to top
        </b-col>
      </b-row> -->
      <b-container class="footer-main mt-2 pb-2">
        <b-row>
          <div class="col-12 mb-5">
            <div class="row">
              <div class="col mt-5 " >
                <img  @click="scrollTop()" src="../assets/SVG/Up arrow.svg"/>
                <!-- <b-link to="#" class="d-block mt-5" style="text-decoration: underline;font-size: large;">Contact No</b-link>
                <b-link to="#" class="d-block mt-3" style="text-decoration: underline;font-size: large;">Email Address</b-link> -->
              </div>
              <div class="col mt-5 text-right" style="color: black;">
                <b-link href="https://redingtongroup.com/mea/about-us/" class="d-block mt-1">معلومات عنا</b-link>
                <b-link href="https://redingtongroup.com/mea/brands/" class="d-block  mt-1">العلامات التجارية</b-link>
                <b-link href="https://redingtongroup.com/mea/our-services/" class="d-block  mt-1">الخدمات</b-link>
                
              </div>
              <div class="col  mt-5 text-right">
                <b-link href="https://redingtongroup.com/mea/privacy-policy/" class="d-block  mt-1">سياسة الخصوصية</b-link>
                <b-link href="https://redingtongroup.com/mea/terms-and-conditions/" class="d-block  mt-1">البنود و الاحكام</b-link>
                <b-link href="mailto:support.vas@redingtongroup.com"  class="d-block  mt-1">الدعم<i style="font-size:18px" class="fa">&#xf0e0;</i></b-link>

              </div>
              <div class="col-1 mt-5 mr-5 ">
                 <b-row >
                  <b-col class="px-0 text-right" @click="scrollTop()">
                    <a href="#" >
                      <b-link :to="{ name: 'LandingPage' }" class="d-block footer-section-head "><img src="../assets/Buyback_content/Redington (India).png"/></b-link>
                    </a>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-row>
        <b-row class="justify-content-center mt-4">
          <div class="col-4">
            &copy; 2023 ردينجتون. كل الحقوق محفوظة
          </div>
          <!-- <div class="col-8 text-right">
                <b-link class="mx-1" :to="{ name: 'TermsOfSale' }"><small> Terms and conditions</small></b-link>
                <b-link class="mx-1" :to="{ name: 'PrivacyPolicy' }"><small>Privacy Policy</small></b-link>
                <b-link class="mx-1" :to="{ name: 'WarrantyPolicy' }"><small> Warranty Policy</small></b-link>
                <b-link class="mx-1" :to="{ name: 'ReturnPolicy' }"><small> Return Policy</small></b-link>
                <b-link class="mx-1" :to="{ name: 'RefundPolicy' }"><small> Refund Policy</small></b-link>
                <b-link class="mx-1" :to="{ name: 'FAQ' }"><small> FAQs</small></b-link>
          </div> -->
        </b-row>
      </b-container>
    </b-container>
    <b-container fluid class="footer d-lg-none">
      <b-row class="footer-gradient">
          <!-- <img width="100%" src="@/assets/imgs/footer/footer-gradient.png" alt=""> -->
      </b-row>
      <!-- <b-row class="back-to-top">
        <b-col class="py-2" @click="scrollTop()">
          Back to top
        </b-col>
      </b-row> -->
      <b-container class="footer-main mt-2 pb-2">
        <b-row>
          <div class="col-12 mb-5">
            <div class="row">
              <div class="col mt-3">
                <img  src="../assets/Buyback_content/Redington (India).png" alt="Redington"  width="180px"  />
               </div>
              <div class=" mt-3 ">
                 <b-row >
                  <b-col class="py-2 text-left" @click="scrollTop()">
                    <a href="#">
                      <img src="../assets/SVG/Up arrow.svg" width="40px"/>
                    </a>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div class="text-right">
                <b-link href="https://redingtongroup.com/mea/about-us/" class="d-block mt-1">معلومات عنا</b-link>
                <b-link href="https://redingtongroup.com/mea/brands/" class="d-block  mt-1">العلامات التجارية</b-link>
                <b-link href="https://redingtongroup.com/mea/our-services/" class="d-block  mt-1">الخدمات</b-link>
                <b-link href="https://redingtongroup.com/mea/privacy-policy/" class="d-block  mt-1">سياسة الخصوصية</b-link>
                <b-link href="https://redingtongroup.com/mea/terms-and-conditions/" class="d-block  mt-1">البنود و الاحكام</b-link>
                <b-link href="mailto:support.vas@redingtongroup.com"  class="d-block  mt-1">الدعم<i style="font-size:18px" class="fa">&#xf0e0;</i></b-link>

            </div>
          </div>
        </b-row>
        <b-row class="justify-content-center mt-4">
          <div class="col text-right">
            &copy; 2023 ردينجتون. كل الحقوق محفوظة
          </div>
          <!-- <div class="col-8 text-right">
                <b-link class="mx-1" :to="{ name: 'TermsOfSale' }"><small> Terms and conditions</small></b-link>
                <b-link class="mx-1" :to="{ name: 'PrivacyPolicy' }"><small>Privacy Policy</small></b-link>
                <b-link class="mx-1" :to="{ name: 'WarrantyPolicy' }"><small> Warranty Policy</small></b-link>
                <b-link class="mx-1" :to="{ name: 'ReturnPolicy' }"><small> Return Policy</small></b-link>
                <b-link class="mx-1" :to="{ name: 'RefundPolicy' }"><small> Refund Policy</small></b-link>
                <b-link class="mx-1" :to="{ name: 'FAQ' }"><small> FAQs</small></b-link>
          </div> -->
        </b-row>
      </b-container>
    </b-container>
 
  </div>
</template>

<script>
// import FooterContact from './FooterContact.vue'
// import ServiceBarItem from './ServiceBarItem.vue'
// import SignIn from '@/components/SignIn.vue'
// import api from '../api'
// import SubscribeBox from './SubscribeBox.vue'

export default {
  name: 'Footer',
  props: ['items'],
  components: {
    // SubscribeBox
    // FooterContact
  },
  data () {
    return {}
  },
  methods: {
    scrollTop: function () {
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    }
    // subscribeNewsletter: function () {
    //   const inputs = {
    //     'inputs': {
    //       'login': 'admin',
    //       'password': 'admin'
    //     },
    //     'modecode': 100
    //   }
    //   const url = 'admin-test'
    //   api
    //     .request('post', url, inputs, 'login')
    //     .then((response) => {
    //       var data = response.data
    //       if (data.error) {
    //         this.error = 'Login Failed!'
    //         return
    //       }
    //       if (data.result) {
    //         var userAuth = data.result.session_id
    //         this.$store.commit('SET_TOKEN', userAuth)

    //         if (window.localStorage) {
    //           window.localStorage.setItem('user', userAuth)
    //         }
    //       }
    //     })
    //     .catch((error) => {})
    // }
  }
}
</script>

<style scoped>
.footer{
  /* background-color: #404041; */
  color: #000000;
}
.footer-gradient{
  background-color: #fff;
}
.back-to-top{
  background-color: #000;
  color: #FEFEFE;
  font-weight: bold;
  cursor: pointer;
}
.footer-main{
  text-align: left;
}
.footer-main a{
color: #000000;
}
.footer-nav-section{
  justify-content: space-between;
  flex-direction: column;
}
.footer-section-head{
  color: #0bb32a !important;
  font-weight: bold;
  font-size: large;
}
.footer-nav .nav-link{
  padding: 0;
  color: #939394;
}
#newsletter-email{
  background: none;
  border-top-width:0;
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
  border-bottom-color: #0DB02B;
}
  .footer-social{
    justify-content: flex-end;
  }
.footer-social .list-group-item{
  background: none;
}
.footer-business-link{
  text-decoration: underline;
  color: #808184;

}
.footer-contact{
  background-color: #e9ecef;
}
.footer-about{
  text-align: left;
}
.footer-about p {
  line-height: 14px;
  font-size: 12px;
  margin-bottom: 0;
}
@media screen and (min-width: 992px) {
  .footer-nav-section{
    flex-direction: row;
  }
  .footer-right-section{
    width: 325px;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .flex-column{
    justify-content: space-between;
  }
}
.collapes-bar {
  background-color: #303132;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-radius: 0rem !important;
}
.collapsing_div {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.collapsing_div a {
color: #939394;
text-align: left;
}
.connect-mobile-block a {
  color: #939394;
  text-align: left;
}
.collapsed .minus-sign {
  display: none;
}
.collapsed .plus-sign {
  display: show;
}
.not-collapsed .plus-sign {
  display: none;
}
.not-collapsed .minus-sign {
  display: show;
}
</style>
