import Vue from 'vue'
import VueMeta from 'vue-meta'
import { BootstrapVue } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/style.css";
import VueHtml2pdf from 'vue-html2pdf'

import VueKinesis from 'vue-kinesis'
import {Swiper} from 'vue2-swiper'
import VueHorizontal from 'vue-horizontal';

import FileReader from 'vue-filereader'
import VueLazyload from 'vue-lazyload'
import VueQRCodeComponent from 'vue-qrcode-component'
import OtpInput from "@bachdgvn/vue-otp-input";
import responsive from 'vue-responsive'
Vue.use(VueHtml2pdf)
Vue.use(responsive)
Vue.component("v-otp-input", OtpInput);
Vue.component('qr-code', VueQRCodeComponent)
Vue.use(VueLazyload)
Vue.component(FileReader.name, FileReader)
Vue.use(VueHorizontal)
Vue.use(Swiper)
Vue.use(VueKinesis)
Vue.use(BootstrapVue)
Vue.config.devtools = true

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  beforeCreate () { this.$store.commit('INITIALISE_STORE') },
  render: h => h(App)
}).$mount('#app')
