<template>
  <b-tr @click="getOrderDetails()">
    <b-td>{{getRefID}}</b-td>
    <b-td>{{getDevice}}</b-td>
    <b-td>{{getDate}}</b-td>
    <b-td>{{getClaim_date}}</b-td>
    <b-td>{{getCondition}}</b-td>
    <b-td>{{getOrderprice}}</b-td>
  </b-tr>
</template>

<script>
export default {
  name: 'OrderLine',
  props: ['item'],
  components: {
  },
  data () {
    return {}
  },
  computed: {
    getOrderID: function () {
      return this.item.id
    },
    getOrderprice: function () {
      return this.item.amount +' '+ this.item.currency
    },
    getDevice: function () {
      return this.item.device
    },
    getClaim_date: function () {
      return this.item.claim_date
    },
    getDate: function () {
      return this.item.invoice_date
    },
    getRefID: function () {
      return this.item.ref
    },
    getCondition: function () {
      return this.item.condition
    }
  },
  methods: {
    getOrderDetails: function () {
      
        this.$router.push({ name: 'ABB_Track', params: { 'id': this.getOrderID } })
      
    }
  }
}
</script>

<style scoped>
</style>
