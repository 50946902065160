<template>
  <div>
    <Header />
    <div class="home mt-1">
      <!-- <DocsMenuBar /> -->
      <div class="content-section mt-5">
        <h3>Frequently Asked Questions</h3>
        <!-- <div v-for="i in faqdata" :key="i.id">{{i.id}}</div> -->
        <div class="container-fluid">
          <div class="row">
            <div class="col">
              <div class="bread">
                <b-breadcrumb style="background-color:white">
                  <b-breadcrumb-item href="#foo" active>Home<a href=""></a></b-breadcrumb-item>
                  <b-breadcrumb-item href="#foo" active>FAQ</b-breadcrumb-item>
                  <b-breadcrumb-item href="#bar" active>{{ selectedCategory }}
                  </b-breadcrumb-item>
                </b-breadcrumb>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
    <div>
      <b-container class="reuse-buy" fluid>
      <b-row no-gutters>
      <b-col class="d-none d-md-flex col-md-2 mt-1 filter-block ">
        <b-card no-body class="p-2 ml-1 text-left classify">
          <div class="col">
               <ul class="navbar-nav mr-auto ul-list">
                    <li class="nav-item">
                      <div v-for="i in get_categories" :key="i">
                        <a class="nav-link" href="#">
                          <h5 @click="show(i)" id="active_class" v-if="i === 'BuyBackt'">
                            {{ i }}
                          </h5>
                        </a>
                      </div>
                    </li>
                  </ul>
            </div>
        </b-card>
      </b-col>
       <b-col class="col-12 col-md-10 pl-0 content-block">
        <div class="input-group">
                    <div class="dropdown d-flex">
                      <div class="input-group-append">
                        <button class="btn bg-white text-black buttonborder" type="button">
                          <BIconSearch>
                            <i class="fa fa-search"></i>
                          </BIconSearch>
                        </button>
                      </div>
                      <b-form-input v-model="text" list="my-list-id" class="search_bar">
                      </b-form-input>
                      <datalist id="my-list-id">
                        <option v-for="i in get_search" :key="i" @click="this.show2(i)">{{ i }}</option>
                      </datalist>
                    </div>
                  </div>
        <div  class="group2" v-if="search_id === actual_address || search_id === actual_address && search_id !== search_chk || search_id === actual_address || search_id === actual_address && search_id === search_chk">
                <b-list-group class="list-group list-group-flush" v-if="!text.length">
                  <div v-for="i in get_faq" :key="i.id">
                    <b-list-group-item :href="'#'+i.name" class="flex-column align-items-start" style="border:none"
                      @click="_collapse(i)">
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1  abc2">
                          <div>
                            {{ i.name }}
                          </div>
                        </h5>
                        <b-badge variant="white" v-if="chk && chk_id === i.id">
                          <BIconChevronRight style="color: green;" class="t_7" scale="1.3" />
                        </b-badge>
                        <b-badge variant="white" v-else-if="chk_id !== i.id">
                          <BIconChevronRight style="color: green;" class="t_7" scale="1.3" />
                        </b-badge>
                        <b-badge variant="white" v-if="!chk && chk_id === i.id">
                          <BIconChevronDown style="color: green;" class="t_7" scale="1.3" />
                        </b-badge>
                      </div>
                      <b-collapse :id="i.id.toString()">
                        <p class="mb-1" id="chk" style="text-align:left">
                          {{ i.des }}
                        </p>
                      </b-collapse>
                    </b-list-group-item>
                    <hr />
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="overflow-auto">
                        <b-pagination align="right" size="lg" v-model="currentPage" :total-rows="rows"
                          :per-page="perPage" aria-controls="faq-visible-data" first-number last-number
                          prev-text="First" next-text="Last"></b-pagination>
                      </div>
                    </div>
                  </div>
                </b-list-group>
              </div>
              <div v-if="text.length" class="group2">
                <b-list-group class="list-group list-group-flush">
                  <b-list-group-item href="#" class="flex-column align-items-start" style="border:none"
                    v-b-toggle.collapse-search @click="_collapse2()">
                    <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-1  abc2">
                        <div>
                          {{ text }}
                        </div>
                      </h5>
                      <b-badge variant="white" v-if="chk2">
                        <BIconChevronRight style="color: green;" class="t_7" scale="1.3" />
                      </b-badge>
                      <b-badge variant="white" v-if="!chk2">
                        <BIconChevronDown style="color: green;" class="t_7" scale="1.3" />
                      </b-badge>
                    </div>
                    <b-collapse id="collapse-search">
                      <div v-for="i in faqdata" :key="i.id">
                        <p class="mb-1" style="text-align:left" v-if="i.name === text">
                          {{ i.des }}
                        </p>
                      </div>
                    </b-collapse>
                  </b-list-group-item>
                  <hr />
                </b-list-group>
              </div>
              <div class="group2" v-if="search_id !== actual_address || search_id !== actual_address && search_id !== search_chk">
                <b-list-group class="list-group list-group-flush">
                  <div v-for="i in faqdata" :key="i.id">
                    <b-list-group-item class="flex-column align-items-start" style="border:none"
                      v-b-toggle.collapse-search2 @click="_collapse3()"
                      v-if="search_id.replaceAll(actual_address2,'').
                            replaceAll('%20',' ') == i.name || search_id.replaceAll(actual_address2,'').
                            replaceAll('%20',' ') == i.name">
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1  abc2">
                          <div >
                            {{ i.name }}
                          </div>
                        </h5>
                        <b-badge variant="white" v-if="chk3">
                        <BIconChevronRight style="color: green;" class="t_7" scale="1.3" />
                      </b-badge>
                      <b-badge variant="white" v-if="!chk3">
                        <BIconChevronDown style="color: green;" class="t_7" scale="1.3" />
                      </b-badge>
                      </div>
                      <b-collapse id="collapse-search2">
                        <p class="mb-1" id="chk" style="text-align:left">
                          {{ i.des }}
                        </p>
                      </b-collapse>
                      <hr/>
                    </b-list-group-item>
                  </div>
                </b-list-group>
              </div>
      </b-col>
      </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { BIconChevronRight, BIconChevronDown, BIconSearch } from 'bootstrap-vue'
import faqdata from '@/data/faqsData.js'
import Header from '@/components/Header2.vue'
import Footer from '@/components/Footer.vue'
// import DocsMenuBar from '@/components/DocsMenuBar.vue'
export default {
  name: 'FAQ',
  computed: {
    get_faq: function () {
      return faqdata.filter(faq => faq.ctg === this.selectedCategory).slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage)
    },
    get_name: function () {
      return faqdata.filter(faq => faq.name === this.selectedname)
    },
    get_categories: function () {
      let cat = faqdata.map(item => item.ctg)
      cat = new Set(cat)
      return cat
    },
    get_search: function () {
      let cat2 = faqdata.map(item => item.name)
      cat2 = new Set(cat2)
      return cat2
    },
    rows () {
      return faqdata.filter(faq => faq.ctg === this.selectedCategory).length
    }
  },
  components: {
    Header,
    BIconSearch,
    Footer,
    // DocsMenuBar,
    BIconChevronDown,
    BIconChevronRight
  },
  data () {
    return {
      faqdata: faqdata,
      selectedCategory: 'BuyBack',
      selectedname: 'Do you offer warranty?',
      perPage: 7,
      currentPage: 1,
      fields: ['name'],
      items: this.get_faq,
      isHidden: false,
      text: '',
      chk: true,
      chk_id: '',
      chk2: true,
      chk3: true,
      actual_address: process.env.VUE_APP_FAQ,
      actual_address2: process.env.VUE_APP_FAQ_,
      search_id: window.location.href,
      search_chk: ''
    }
  },
  methods: {
    show: function (i) {
      this.selectedCategory = i
      this.search_chk = this.search_id
    },
    show2: function (i) {
      this.selectedname = i
    },
    _collapse: function (i) {
      this.$root.$emit('bv::toggle::collapse', i.id.toString())
      if (this.chk) {
        this.chk = false
        this.chk_id = i.id
      } else {
        this.chk = true
        this.chk_id = i.id
      }
      this.search_chk = window.location.href
    },
    _collapse2: function () {
      if (this.chk2) {
        this.chk2 = false
      } else {
        this.chk2 = true
      }
    },
    _collapse3: function () {
      if (this.chk3) {
        this.chk3 = false
      } else {
        this.chk3 = true
      }
    }
  }
}
</script>
<style scoped>
.buttonborder {
  border-color: #C9C9C9;
  border-right-style: hidden;
}

.buttonborder:focus {
  box-shadow: none;
  border-color: #4CAF50;
}

.input-group-append .btn {
  border-radius: 30px 0px 0px 30px;
}

input {
  height: 60px;
  border-radius: 0px 30px 30px 0px;
  border-left: none;
  width: 100% !important;
}

input:focus {
  box-shadow: none;
  border-color: #4CAF50;
}

input::placeholder {
  opacity: 0.7;
}

@media (min-width: 992px) {
  .input-group {
    width: 1000px;
  }
  .group2 {
    width: 1000px;
    margin-top: 23px;
  }
}

.dropdown {
  position: relative;
  width: 100% !important;
}

.content-section h3 {
  padding-left: 50px;
  text-align: left;
  font-style: normal;
  font-weight: bolder;
  font-size: 32px;
  line-height: 48px;
  /* identical to box height */
  color: #3FAF2B;
}

.bread {
  padding-left: 35px;
}

.abc2 {
  font-size: 20px;
  font-weight: bolder;
}

.ul-list {
  margin-top: 100px;;
  margin-left: 40px;
  text-align: left;
}

.ul-list a {
  font-weight: bolder;
}

.abc2 {
  text-align: left;
}

.nav-item h5 {
  font-size: medium;
}

.nav-link h5:hover {
  color: #3FAF2B;
}

.nav-link h5 {
  color: black;
}

.btn {
  border-radius: 30px 0px 0px 30px;
}

.dropdown_list {
  border: 1px solid white;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-left: 15px;
}
.classify{
  border: none
}
</style>

<style>
/* .page-link {
  border: none !important;
  color: #BDBDBD;
}

.pagination li {
  border-top: 2px solid #BDBDBD;
  border-bottom: 2px solid #BDBDBD;
}

.pagination li:first-child {
  border: 2px solid #BDBDBD !important;
  border-radius: 5px 0px 0px 5px;
  color: #BDBDBD !important;
  background-color: #F3F3F3 !important;
}

.pagination li:last-child {
  border: 2px solid #BDBDBD !important;
  border-radius: 0px 5px 5px 0px;
  background-color: #F3F3F3 !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: green;
  background-color: white;
}

.page-item.disabled .page-link {
  background-color: #F3F3F3;
}

.page-item {
  align-content: center;
}

.pagination li:last-child button {
  background-color: #F3F3F3;
}

.pagination li:first-child button {
  background-color: #F3F3F3;
}

.pagination {}

.btn {
  font-size: 13px;
  color: #666666;
}

.dropdown-item {
  color: #666666;
  font-size: 13px;
} */
</style>
