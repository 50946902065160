export default
[
  {
    id: 1,
    ar: 'استمارةمطالبةالبرنامج',
    en: 'Program Claim Form',
  },
  {
    id: 2,
    ar: 'رقمالهوية الدولية لألجهزة املتنقلة/الرقمالتسلسلي/املعرفالفريد',
    en: 'IMEI / SERIAL NO / UNIQUE IDENTIFIER',
  },
  {
    id: 3,
    ar: 'في حال عدم العثور على السجل',
    en: 'In Case Record Not Found',
  },
  {
    id: 4,
    ar: 'إذا لم يتم العثور على السجل باستخدام رقمالهوية الدولية لألجهزة املتنقلةأو الرقم التسلسلي أو املعرف الفريد، يمكنك االستفادة من عرض االسترجاع املؤكد عن طريق تحميل الفاتورة كإثبات للشراء. يرجى النقر على الزر أدناه للمتابعة.',
    en: 'If the record is not found using the IMEI, Serial Number, or Unique Identifier, you can still avail the Assured Buyback (ABB) offer by uploading the invoice as proof of purchase. Please click the button below to proceed',
  },


  {
    id: 5,
    ar: 'استمارة طلب املطالبة',
    en: 'GBB Device Claim Form',
  },
  {
    id: 6,
    ar: 'تفاصيل العميل',
    en: 'Customer Details',
  },
  {
    id: 7,
    ar: 'اسم العميل',
    en: 'Customer Name',
  },
  {
    id: 8,
    ar: 'البريد اإللكتروني',
    en: 'Email',
  },
  

  {
    id: 9,
    ar: 'رقم الجوال',
    en: 'Mobile Number',
  },
  {
    id: 10,
    ar: 'تفاصيل الفاتورة',
    en: 'Invoice Details',
  },
  {
    id: 11,
    ar: 'رقم الفاتورة (اختياري)',
    en: 'Invoice Number (optional)',
  },
  {
    id: 12,
    ar: 'تاريخالفاتورة',
    en: 'Invoice Date',
  },


  {
    id: 13,
    ar: 'معرف فريد',
    en: 'Unique ID',
  },
  {
    id: 14,
    ar: 'رفع الفاتورة األصلية للشراء',
    en: 'Upload the original purchase invoice',
  },
  {
    id: 15,
    ar: 'رفع إثبات االستبدال من ممثل معتمد من شركة',
    en: 'Upload proof of exchange by an authorized Apple representative.',
  },
  {
    id: 16,
    ar: 'الفئة',
    en: 'Category',
  },

  {
    id: 17,
    ar: 'العالمة التجارية',
    en: 'Brand',
  },
  {
    id: 18,
    ar: 'املوديل (سكو',
    en: 'SKU Model',
  },
  {
    id: 19,
    ar: 'يرجى اختيار املوديل',
    en: 'Please Select the Model',
  },
  {
    id: 20,
    ar: 'مبلغ الفاتورة (بدون ضريبة القيمة املضافة)',
    en: 'Invoice Amount (Excluding VAT)',
  },


  {
    id: 21,
    ar: 'اسمالجهاز',
    en: 'Device Name',
  },
  {
    id: 22,
    ar: 'رقمالهوية الدولية لألجهزة املتنقلة/الرقمالتسلسلي',
    en: 'IMEI / SERIAL NO:',
  },
  {
    id: 23,
    ar: 'املعرفالفريد',
    en: 'UNIQUE IDENTIFIER:',
  },
  {
    id: 24,
    ar: 'يرجىالتأكدمنأنالتصنيفيتوافقمعالتعريف،وإالفسوفيؤديذلكإلىاختالففيسعراملنتج',
    en: 'Please ensure that grading confirms to the definition, otherwise, it will result in a price variance of the product.',
  },

  {
    id: 25,
    ar: 'البحثعنعرضالسعر',
    en: 'Search Quotation',
  },
  {
    id: 26,
    ar: 'رفعالفاتورة',
    en: 'Record Not Found',
  },




  

  {
    id: 27,
    ar: 'ال خدوش على الشاشة',
    en: 'No Scratches on the screen',
  },


  {
    id: 28,
    ar:'خالية من األضرار والخدوش الطفيفة على الجسم',
    en: 'Dent-free and minor scratches on body '
  },

  {
    id: 29,
    ar:'صحةالبطارية90% أو أعلى',
    en: 'Battery health 90% or Above'
  },
  {
    id: 30,
    ar:'عمل كامل',
    en: 'Fully functional'
  },
  {
    id: 31,
    ar:'ال يوجد قفل للجهاز/قفل للشريحة',
    en: 'No Device Lock/Sim Lock'
  },
  {
    id: 32,
    ar:'ال توجد أجزاء مفقودة (غطاء خلفي، درجالشريحة، أزرار)',
    en: 'No missing parts (back cover, sim tray, buttons)'
  },
  {
    id:33,
    ar: 'عرض تاريخ GBB',
    en: 'View GBB HIstory'
  },

  {
    id: 34,
    ar: 'أنت مؤهل للمطالبة',
    en: 'You are eligible to claim'
  }

]
