<template>
  <div>
    <SplashScreen v-if="animate" />

    <Header class="head"/>

    <div class="mt-5 pb-2 print d-none d-lg-block" >
      <!-- <h2 class="mb-3 info-box" style="color: #39A536;"><b>Thank you</b></h2> -->
    <div class="d-flex justify-content-center mt-3" >
      <b-container class=" d-flex justify-content-center" >
        <div  class=" mb-2 ">
          <div class="home">
            <!-- <img src="../assets/Buyback_content/5 - Thank you.svg"/> -->
          <div class="row justify-content-end" v-if="this.$store.state.user">          
            <b-button @click="getOrderStatus2()" class="button mr-3 mb-3" v-if="this.$store.state.user.position === 'Manager' && order[0].status === 'Pending'">إلغاء إعادة الشراء</b-button>
            <!-- <b-button @click="getOrderStatus()" class="button mr-3 mb-3" v-if="order[0].status === 'Pending'">Incomplete Buyback</b-button>
            <b-button @click="getOrderStatus()" class="button mr-3 mb-3" v-if="order[0].status === 'Incomplete'">Complete Buyback</b-button> -->
            <!-- <b-button @click="print_rsp()" class="button mr-3 mb-3" v-if="trans === 'Seller'">Print</b-button>
            <b-button @click="print_rsp()" class="button mr-3 mb-3" v-else>اطبع</b-button> -->
            <b-button @click="exportToPDF" class="button mr-3 mb-3"  v-if="trans === 'Seller'">اطبع</b-button>
            <b-button @click="exportToPDF" class="button mr-3 mb-3"  v-else>اطبع</b-button>

          </div>
          <div class="row justify-content-end" v-else>          
            <b-button @click="exportToPDF" class="button mr-3 mb-3"  v-if="trans === 'Seller'">اطبع</b-button>
            <b-button @click="exportToPDF" class="button mr-3 mb-3"  v-else>اطبع</b-button>

          </div>
      <div class="thanks p-3 info-box border border-secondary mb-2 "   id="element-to-convert" v-if="!animate">
        <div class="text-center d-flex justify-content-center">
          <div class="text-right">
            <h5 v-if="trans === 'Seller'">إيصال الشراء</h5>
            <h5 v-else>إيصال الشراء</h5>
            <p><b>تاريخ:</b> {{order[0].date}}</p>
            <p> {{order[0].id}}#<b>مرجع</b></p>
          </div>
       
        <qr-code class="ml-5" :text="'https://vas.redingtongroup.com/orders_ar/sell_ar/'+id" size="80"></qr-code>

            <!-- <div>
              <qr-code :text="'https://vas.redingtongroup.com/orders/sell/'+id" size="100"></qr-code>
            </div> -->
        </div>
        <div class="text-right mt-3" >
          <div class="d-flex justify-content-around " >
            <div>
              <h6><u id="myDiv" ref="myDiv">بائع</u></h6>
              <p class=" notranslate">{{order[0].seller}}<b>:اسم</b> </p>
              <p class="notranslate"><b></b> {{order[0].email}}<b>:بريد إلكتروني</b></p>
              <p class="notranslate"><b>اتصال:</b> {{order[0].num}}</p>
              <p class="notranslate"><b></b> {{order[0].add}}<b>:العنوان</b></p>
            </div>
          <div>
            <h6 class=""><u>تم شراؤها عبر</u></h6>
            <p class=" "> {{order[0].sales}}<b>مندوب مبيعات:</b></p>
            <p class=" "> {{order[0].address}}<b>اسم المتجر:</b></p>
            <p>{{order[0].shop}}<b>عنوان:</b> </p>
          </div>
            
          </div>
       
        <br/>
        <!-- <h6><u id="myDiv" ref="myDiv">Seller</u></h6>
        <p class="mt-4 notranslate"><b>Name:</b> {{order[0].seller}}</p>
        <p class="notranslate"><b>Email:</b> {{order[0].email}}</p>
        <p class="notranslate"><b>Contact:</b> {{order[0].num}}</p>
        <p class="notranslate"><b>Place:</b> {{order[0].add}}</p> -->
        <!-- <div class="d-flex justify-content-center" v-if="order[0].pro_d">
          <table id="customers">
            <tr >
              <th style="border-right: none; ">The Device You Have Purchased</th>
              <th style="border-left: none;"></th>
            </tr>
            <tr>
              <th>Product Description</th>
              <th>IMEI/Serial Number </th>
            </tr>
            <tr>
              <td>{{order[0].pro_d}}</td>
              <td>{{order[0].pro_i}}</td>
            </tr>
          </table>
        </div> -->
        <div class=" d-flex justify-content-center">
          <table id="customers">
            <tr v-if="order[0].pro_d" >
              <th style="border-right: none; ">الجهاز الذي اشتريته</th>
              <th style="border-left: none;border-right: none; "></th>
              <th  style="border-left: none;border-right: none; "></th>
              <th  style="border-left: none;border-right: none; "></th>
              <th  style="border-left: none;"></th>
            </tr>
            <tr v-if="order[0].pro_d">
              <th>وصف المنتج</th>
              <th style="border-right: none; ">IMEI/الرقم التسلسلي</th>
              <th  style="border-left: none;border-right: none; "></th>
              <th  style="border-left: none;border-right: none; "></th>
              <th  style="border-left: none;"></th>
            </tr>
            <tr v-if="order[0].pro_d">
              <td>{{order[0].pro_d}}</td>
              <td style="border-right: none; ">{{order[0].pro_i}}</td>
              <td  style="border-left: none;border-right: none; "></td>
              <td  style="border-left: none;border-right: none; "></td>
              <td  style="border-left: none;"></td>
            </tr>
            <tr>
              <th style="border-right: none; ">الجهاز الذي ترغب في مبادلته</th>
              <th style="border-left: none;border-right: none; "></th>
              <th  style="border-left: none;border-right: none; "></th>
              <th  style="border-left: none;border-right: none; "></th>
              <th  style="border-left: none;"></th>

            </tr>
            <tr>
              <th>وصف المنتج</th>
              <th>IMEI/الرقم التسلسلي</th>
              <th>السعر (درهم) (ريال)</th>
              <th>قيمة إضافية</th>
              <th>المجموع</th>
            </tr>
            <tr v-for="i in order" :key="i">
              <td>{{i.device}}</td>
              <td>{{i.imei}}</td>
              <td>{{i.price}}</td>
              <td v-if="i.device === order[0].device && order[0].pro_p !== false">{{order[0].pro_p}}</td>
              <td v-else>None</td>
              <td v-if="i.device === order[0].device && order[0].pro_p !== false">{{parseInt(i.price) + parseInt(order[0].pro_p)}}</td>
              <td v-else>{{ parseInt(i.price) }}</td>
            </tr>
            <tr>
              <th style="border-right: none; "></th>
              <th style="border-left: none;border-right: none; "></th>
              <th  style="border-left: none;border-right: none; " v-if="trans === 'Seller'">إجمالي المبادلة</th>
              <th  style="border-left: none;border-right: none; " v-else>إجمالي المبادلة</th>
              <th  style="border-left: none;border-right: none; "></th>
              <th  style="border-left: none;">{{ order[0].total }}</th>

            </tr>
          </table>
        </div>
        
        <div class="mt-3 d-flex justify-content-around ">
          <!-- <div>
            <h6 class="mt-4"><u>Purchased via</u></h6>
            <p class=" "><b>Sales Representative:</b> {{order[0].sales}}</p>
            <p class=" "><b>Store Name:</b> {{order[0].address}}</p>
            <p><b>Address:</b> {{order[0].shop}}</p>
          </div> -->
          <div class="mt-3">
            <!-- <qr-code :text="'https://vas.redingtongroup.com/orders/sell/'+id" size="100"></qr-code> -->
          </div>
        </div>
        <!-- <div class="html2pdf__page-break"></div> -->

        
        <div   >
          <h3 class="text-center" style="font-size: smaller;">القيود و الاحكام</h3>
          <!-- <h6 class="text-right mt-4">
            انا الموقع ادناه _________________ حامل الجواز رقم ______________ اقر و أوافق على الشروط و الاحكام المرفقة في الأسفل بتوقيعي المرفق ادناه, و أؤكد اني قرأت و فهمت و أوافق على هذه الشروط و الاحكام  إلى أفضل ما لدي من وعي.

          </h6> -->
          <h6 class="text-right" style="font-size: x-small;">
                    
     <br/><br/>    ١. أقر بموجبه وأوافق على الشروط والأحكام الواردة أدناه. من خلال التوقيع على ما يلي ، أؤكد أنني قد قرأت وفهمت وأوافق على الشروط والأحكام بأفضل ما لدي من وعي.
     <br/><br/>    ٢. اوافق على تقديم معلومات دقيقة وكاملة بشأن الأجهزة التي ينوون استبدالها ، بما في ذلك على سبيل المثال: الطراز والحالة وأي ملحقات مرتبطة.
     <br/> <br/>   ٣. اقر بأن قيمة الاستبدال التي يقدمها بائع التجزئة للأجهزة تخضع للتقييم والتحقق من خلال الفحص المادي من قبل بائع التجزئة أو ممثليه المعتمدين.
        <br/> <br/>    ٤. افهم أن قيمة المقايضة النهائية التي يقدمها بائع التجزئة قد تختلف عن التقدير الأولي ، ويجب إبلاغ العميل بأي تعديلات قبل إتمام معاملة المقايضة.
        <br/> <br/>   ٥. اتعهد بأن لديه/لها الملكية القانونية أو الإذن المناسب للتداول في الأجهزة وأن الأجهزة خالية من أي أعباء أو قيود من شأنها أن تمنع نقلها.
        <br/> <br/>    ٦. الموافقة على نقل جميع حقوق الملكية في أجهزة المقايضة إلى بائع التجزئة عند إتمام صفقة الاستبدال.
        <br/> <br/>   ٧. أقر بأن معاملة الاستبدال نهائية ، وأدرك أنه لا يمكن إرجاعها أو استردادها تحت أي ظرف من الظروف.
        <br/> <br/>    ٨. أعفي بائع التجزئة من أي مسؤولية أو مطالبات أو أضرار أو نزاعات تنشأ عن أو تتعلق بمعاملة المقايضة ، بما في ذلك على سبيل المثال فقدان البيانات أو المعلومات الشخصية أو أي استخدام غير مصرح به لأجهزة الاستبدال.
        <br/> <br/>   ٩. الموافقة على تعويض وإبقاء بائع التجزئة غير ضار من أي مطالبات أو مطالب من طرف ثالث تنشأ عن أو فيما يتعلق بمعاملة المقايضة ، بما في ذلك أي مطالبات انتهاك حقوق الملكية الفكرية.
        <br/> <br/>   ١٠. أوافق على أنه بمجرد الانتهاء من المعاملة ، لن يقوم/تقوم بإثارة أي نزاعات من معاملة التجارة ، وأنا بموجب هذا أتنازل عن جميع الحقوق لإثارة أي نزاع بشأن معاملة التجارة بمجرد اكتمال المعاملة وفقًا للقوانين. من الاختصاص الذي يحكم هذه الاتفاقية.
        <br/> <br/>    ١١. يشكل هذا البند الاتفاقية الكاملة فيما يتعلق بصفقة التجارة ويبطل أي تفاهمات أو اتفاقيات سابقة ، سواء كانت مكتوبة أو شفهية، تتعلق بها.
        <br/>  <br/>   ١٢. من خلال متابعة معاملة الاستبدال ، أقر بأنهم قد قرأوا وفهموا الشروط والأحكام الموضحة في هذا البند وأوافق على الالتزام بها.
        <br/> <br/>   ١٣. يجب أن يكون هذا البند ملزمًا للأطراف وخلفائهم والمتنازل لهم والممثلين القانونيين.

          </h6>
          <br/>
        </div>
        <div class="row justify-content-start" >
          <h6 class="text-center ml-5  mb-5">
            <hr/>توقيع العميل
            
          </h6>
        </div>
        </div>
      </div>
    </div>
       
        </div>
        </b-container>
        </div>
  </div>
  <div class="mt-3 pb-2 print d-lg-none" >
      <!-- <h2 class="mb-3 info-box" style="color: #39A536;"><b>Thank you</b></h2> -->
    <div class="d-flex justify-content-center mt-3" >
      <b-container class=" d-flex justify-content-center" >
        <div  class=" mb-2 ">
          <div class="home">
            <!-- <img src="../assets/Buyback_content/5 - Thank you.svg"/> -->
         
      <div class="thanks p-5 info-box border border-secondary mb-2 "   >
        <div>
              <qr-code :text="'https://vas.redingtongroup.com/orders_ar/sell_ar/'+id" size="100"></qr-code>
            </div>
        <div class="text-right mt-5">
        <h4 v-if="trans === 'Seller'">إيصال الشراء</h4>
        <h2 v-else>إيصال الشراء</h2>
        </div>
   
        <div class="text-right mt-3" >
          <div class=" justify-content-center">
            <div>
              <p> {{order[0].date}}:<b>تاريخ</b></p>
              <p> {{order[0].id}}#<b>مرجع</b></p>
            </div>
            
          </div>
       
        <br/>
        <h5><u id="myDiv" ref="myDiv">بائع</u></h5>
        <p class="mt-3 notranslate"> {{order[0].seller}}:<b>اسم</b></p>
        <p class="notranslate">{{order[0].email}}:<b>بريد إلكتروني</b> </p>
        <p class="notranslate">{{order[0].num}}:<b>اتصال</b> </p>
        <p class="notranslate">{{order[0].add}}:<b>العنوان</b> </p>
        <div class="mt-3" v-if="order[0].pro_d">
          <table id="customers">
            <tr>
              <th style="border-right: none; ">الجهاز الذي اشتريته</th>
              <th style="border-left: none;"></th>
            </tr>
            <tr>
              <th>Product Description</th>
              <th>IMEI/Serial Number </th>
            </tr>
            <tr>
              <td>{{order[0].pro_d}}</td>
              <td>{{order[0].pro_i}}</td>
            </tr>
          </table>
        </div>
        <div>

        </div>
        <div class="justify-content-center d-flex mt-3">
          <b-button @click="exportToPDF" class="button mr-3 mb-3"  v-if="trans === 'Seller'">اطبع</b-button>
            <b-button @click="exportToPDF" class="button mr-3 mb-3"  v-else>اطبع</b-button>

        </div>
        <div class="row justify-content-center">          
            <b-button @click="getOrderStatus2()" class="button mr-3 mb-3" v-if="position === 'Manager' && order[0].status === 'Pending'">Cancel Buyback</b-button>
            <b-button @click="getOrderStatus()" class="button mr-3 mb-3" v-if="order[0].status === 'Pending'">إعادة شراء غير مكتملة</b-button>
            <b-button @click="getOrderStatus()" class="button mr-3 mb-3" v-if="order[0].status === 'Incomplete'">Complete Buyback</b-button>
            <!-- <b-button @click="print_rsp()" class="button mr-3 mb-3" v-if="trans === 'Seller'">Print</b-button>
            <b-button @click="print_rsp()" class="button mr-3 mb-3" v-else>اطبع</b-button> -->

          </div>
        
        </div>
      </div>
    </div>
       
        </div>
        </b-container>
        </div>
  </div>
  </div>
</template>

<script>
import api from '../api'
import Header from '../components/Header2_ar.vue'
import html2pdf from "html2pdf.js";
import SplashScreen from '../components/SplashScreen.vue'

export default {
  name: 'ThanksPage',
  props: {
    orderno: ''
  },
  components: {
    Header,
    html2pdf,
    SplashScreen
  },
  computed: {
    customerList: function () {
        return this.$store.state.reciept
    },
  },
  data () {
    return {
      id: this.$route.params.id,
      trans: false,
      order: null,
      // position:this.$store.state.user.position,
      animate: true
    }
  },
  methods: {
    exportToPDF() {
      html2pdf(document.getElementById("element-to-convert"), {
				margin: 1,
  			filename: "Receipt.pdf",
			});
    },
    print_rsp: function () {
      // this.order = true
      window.print();
    },
    getOrderDetails: function () {
      var token_data = JSON.parse(localStorage.getItem('token_data'));
      const inputs = {
       
            'id': this.id,
            'user_id': this.$store.state.user.uid,
            'user_token': this.$store.state.user.user_token,
            'token_data':this.$store.state.user.token_data
         
      }
      api.request(process.env.VUE_APP_Get_Device_Data, inputs).then((response) => {
        if (response.data.errorcode) {
          this.errormessage = response.data.errormessage
        } else if (response.data.result && typeof response.data.result === 'object') {
          this.order = response.data.result
          // console.log(this.order)
        } else if (response.data.result && typeof response.data.result === 'string') {
          this.errormessage = response.data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    },
    getOrderStatus: function () {
      var token_data = JSON.parse(localStorage.getItem('token_data'));
      const inputs = {
        
            'id': this.id,
            'user_id': this.$store.state.user.uid,
            'user_token': this.$store.state.user.user_token,
            'token_data':this.$store.state.user.token_data
        
      }
      api.request(process.env.VUE_APP_Complete_Buyback, inputs).then((response) => {
        if (response.data.errorcode) {
          this.errormessage = response.data.errormessage
        } else if (response.data.result ) {
          window.location.reload()
        } else if (response.data.result && typeof response.data.result === 'string') {
          this.errormessage = response.data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    },
    getOrderStatus2: function () {
      var token_data = JSON.parse(localStorage.getItem('token_data'));
      const inputs = {
       
            'id': this.id,
            'user_id': this.$store.state.user.uid,
            'user_token': this.$store.state.user.user_token,
            'token_data':this.$store.state.user.token_data
      }
      api.request(process.env.VUE_APP_Cancel_Buyback, inputs).then((response) => {
        if (response.data.errorcode) {
          this.errormessage = response.data.errormessage
        } else if (response.data.result ) {
          window.location.reload()
        } else if (response.data.result && typeof response.data.result === 'string') {
          this.errormessage = response.data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    },
  },
  
  mounted () {
    // if (this.orderno !== undefined) {
    //   this.orderno === undefined
    //   window.location.reload()
    // }
    this.getOrderDetails()
    // this.getData()
    setTimeout(() => {
      // console.log(this.$refs.myDiv.innerHTML)
      this.trans = this.$refs.myDiv.innerHTML
      this.animate = false
        }, 2000);
  }
}
</script>

<style scoped>
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}
#customers th {
  font-size:  x-small;
}
#customers td {
  font-size:  x-small;
}
.thanks{
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}
.hrc{
  border: 1px solid rgb(180, 180, 180);
  width: 60%;
}
.hrc2{
  border: 1px solid rgb(199, 197, 197);
  width: 100%;
}
#c2 h6{
  font-size:  x-small;
}
p{
  font-size: x-small;
  margin: 0;
}
.info-box{
  width: fit-content;
  margin-right: 3px;
  margin-left: 3px;
  padding: 3px;
  border-radius: 7px;
  border-color: #e0e0ec !important;
  text-align: center;
}
.wdk_content-pagetitle {
display:none;
}
@media print {
  @page { margin: 0; }
  head * {
    visibility: hidden;
  }
  body * {
    visibility: hidden;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}

</style>
