<template>
  <div>
    <Header class="head"/>
    <div class="mt-5 pb-2 print" >
      <!-- <h2 class="mb-3 info-box" style="color: #39A536;"><b>Thank you</b></h2> -->
    <div class="d-flex justify-content-center mt-3" >
      <b-container class=" d-flex justify-content-center" >
        <div  class=" mb-2 ">
          <div class="home">
            <!-- <img src="../assets/Buyback_content/5 - Thank you.svg"/> -->
          <div class="row justify-content-end">     
            <!-- <b-button @click="ChangeStatus()" class="button mr-3 mb-3" v-if="order[0].status === 'Pending' && position !== 'Logistics'">Deliver Batch</b-button> -->
            <b-button @click="ChangeStatus()" class="button mr-3 mb-3" v-if="order[0].status === 'Pending' && Manager === 'Manager'">Close Batch</b-button>
            <b-button @click="exportToPDF" class="button mr-3 mb-3">Print</b-button>
          </div>
      <div class="thanks p-5 info-box border border-secondary mb-2 "  id="element-to-convert">
        <div class="text-center mt-5">
        <h2>Batch Delivery Note</h2>
        </div>
        <div class="text-left mt-3" >
          <div class="d-flex justify-content-between">
            <div>
              <p><b>Date:</b> {{order[0].date}}</p>
              <p><b>Reference#</b> {{id}}</p>
            </div>
            <div>
              <qr-code :text="order[0].packing" size="100"></qr-code>
            </div>
          </div>
       
        <br/>
        <h5><u>Liquidator</u></h5>
        <p class="mt-4"><b>Name:</b> {{order[0].seller}}</p>
        <p><b>Contact Number:</b> {{order[0].num}}</p>
        <p><b>Place:</b> {{order[0].add}}</p>
        <div class="mt-5">
          <table id="customers">
            <tr>
              <th>Product Description</th>
              <th>IMEI/Serial Number </th>
              <th>Reference</th>
            </tr>
            <tr v-for="i in order" :key="i.id">
              <td>{{i.device}}</td>
              <td>{{i.imei}}</td>
              <td>{{i.id}}</td>
            </tr>
          </table>
        </div>
        <br/>
        <h5 class="mt-4"><u>Purchased via</u></h5>
        <p class="mt-4 "><b>Store Name:</b> {{order[0].address}}</p>
        <p><b>Address:</b> {{order[0].shop}}</p>
        <!-- <div>
          <h3 class="text-center">AGREEMENT</h3>
          <h6 class="text-center mt-4">
            This Bond of Indemnity is made and executed by : {{order.seller}} herein after referred to as "Seller" who has
            sold his/her old gadget through our retail partner {{order.address}}, herein after referred to as
            "Purchaser" which expression shall mean and include its representative, executors, nominee, partners, affiliates and
            assigns on day of {{order.date}}.
          </h6>
          <h6 class="text-center mt-3">
            Data Protection : All the Traded Devices will be processed via PhoneCheck Software(The Industry Standard in Used
            Device Certification) for Data Wiping and this process has been Certified by ADISA (Asset Disposal and Information
            Security Alliance).
          </h6>
          <br/>
          <p class="text-center mt-5 mb-5
          " style="font-size: smaller;">This is a Computer Generated Purchase Receipt and does not require signature or stamp.</p>
        </div> -->
        
        </div>
      </div>
    </div>
       
        </div>
        </b-container>
        </div>
  </div>

  </div>
</template>

<script>
import api from '../api'
import Header from '../components/Header2.vue'
import html2pdf from "html2pdf.js";

export default {
  name: 'ThanksPage',
  props: {
    orderno: ''
  },
  components: {
    Header,
    html2pdf
  },
  computed: {
    customerList: function () {
        return this.$store.state.reciept
    },
    Manager: function () {
        return this.$store.state.user.position
    },
  },
  data () {
    return {
      id: this.$route.params.id,
      order: null,
      position:null
    }
  },
  methods: {
    exportToPDF() {
      html2pdf(document.getElementById("element-to-convert"), {
				margin: 1,
  			filename: "Receipt.pdf",
			});
    },
    print_rsp: function () {
      this.order = true
      window.print();
    },
    getOrderDetails: function () {
      var token_data = JSON.parse(localStorage.getItem('token_data'));
      const inputs = {
        
            'id': this.id,
            'user_id': this.$store.state.user.uid,
            'user_token': this.$store.state.user.user_token,
            'token_data':this.$store.state.user.token_data
         
      }
      api.request(process.env.VUE_APP_Get_Batch_Data, inputs).then((response) => {
        if (response.data.errorcode) {
          this.errormessage = response.data.errormessage
        } else if (response.data.result && typeof response.data.result === 'object') {
          this.order = response.data.result
        } else if (response.data.result && typeof response.data.result === 'string') {
          this.errormessage = response.data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    },
    
    ChangeStatus: function () {
      var token_data = JSON.parse(localStorage.getItem('token_data'));
      const inputs = {
        
            'id': this.id,
            'user_id': this.$store.state.user.uid,
            'user_token': this.$store.state.user.user_token,
            'token_data':this.$store.state.user.token_data
          
      }
      api.request(process.env.VUE_APP_Change_Batch_Status, inputs).then((response) => {
        if (response.data.errorcode) {
          this.errormessage = response.data.errormessage
        } else if (response.data.result && typeof response.data.result === 'object') {
          window.location.reload()
        } else if (response.data.result && typeof response.data.result === 'string') {
          this.errormessage = response.data.result
        }
        window.location.reload()

      }).catch((error) => {
        this.errormessage = error
      })
    }
  },
  mounted () {
    this.getOrderDetails()
    console.log(this.$store.state.user.position)
    // this.getData()
  }
}
</script>

<style scoped>
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}
.thanks{
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}
.hrc{
  border: 1px solid rgb(180, 180, 180);
  width: 60%;
}
.hrc2{
  border: 1px solid rgb(199, 197, 197);
  width: 100%;
}
p{
  font-size: larger;
  margin: 0;
}
.info-box{
  margin-right: 3px;
  margin-left: 3px;
  padding: 3px;
  border-radius: 7px;
  border-color: #e0e0ec !important;
  text-align: center;
}
.wdk_content-pagetitle {
display:none;
}
@media print {
  @page { margin: 0; }
  head * {
    visibility: hidden;
  }
  body * {
    visibility: hidden;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}

</style>
