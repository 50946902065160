import axios from 'axios';  
export default {
  // Function to make asynchronous HTTP requests using Axios
  async request(endpoint, datas) {
    
    var data = JSON.stringify(datas);
    console.log(data)
    var config = {
      method: 'post',
      url: process.env.VUE_APP_API_BASE_URL+ endpoint,
      // withCredentials: true,
			crossDomain: true,
      headers: { 
        'Content-Type': 'application/json',
      },
      data: data
    };
    const response = axios( config )
    return response
  },
}
