export default
[
  {
    id: 1,
    name: 'Repair',
    value: 'repair',
    type: 'main',
    
    services: [
      {
        id: 4,
        name: 'Repair Gadget',
        value: 'repair',
        erp_value: 'gadget_repair',
        type: 'sub'
      },
      {
        id: 5,
        name: 'Protection/ Warranty',
        value: 'protection',
        erp_value: 'warranty',
        type: 'sub'
      } /*,
      {
        id: 6,
        name: 'Data Recovery',
        value: 'data recovery',
        erp_value: 'data_recovery',
        type: 'sub'
      } */
    ]
  },
  {
    id: 3,
    name: 'Reuse',
    type: 'main',
    value: 'reuse',
    
    services: [
      {
        id: 11,
        name: 'Buy Fully-Tested Device',
        value: 'buy',
        type: 'sub'
      },
      {
        id: 12,
        name: 'Sell your gadget',
        value: 'sell',
        type: 'sub'
      }
    ]
  },
  {
    id: 2,
    name: 'Renew',
    value: 'renew',
    type: 'main',
    
    services: [
      // {
      //   id: 8,
      //   name: 'Tempered Glass',
      //   value: 'tempered glass',
      //   type: 'sub'
      // },
      // {
      //   id: 9,
      //   name: 'Texture',
      //   value: 'texture',
      //   type: 'sub'
      // },
      // {
      //   id: 10,
      //   name: 'Laser Engraving',
      //   value: 'laser engraving',
      //   type: 'sub'
      // },
      // {
      //   id: 7,
      //   name: 'Upgrade',
      //   value: 'upgrade',
      //   type: 'sub'
      // }
    ]
  }
]
