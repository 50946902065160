<template>
  <div>
    <Header />
    <div class="container-fluid">
        <b-row align-h="center">
            <b-col>
                <h1 class="heading-404">
                    404
                </h1>
            </b-col>
        </b-row>
        <b-row align-h="center" class="mb-5">
            <b-col>
                <h5 class="description-text">
                    We can't find the page that you're looking for
                </h5>
            </b-col>
        </b-row>
        <b-row align-h="center">
            <b-col>
                <b-button class="button-go-home px-4 py-2 m-4" href="/">
                    Go Home
                </b-button>
            </b-col>
        </b-row>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header2.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'PageNotFound',
  data () {
    return {
      activePage: 'home'
    }
  },
  computed: {},
  components: {
    Header,
    Footer
  },
  methods: {},
  mounted () {}
}
</script>

<style scoped>
.heading-404 {
  color: #3faf2b;
  font-weight: bold;
  font-size: 1200%;
}
.button-go-home {
    background-color: #3faf2b;
    color: #ffff;
    border-radius: 25px;
    border-color: #3faf2b;
}
.button-go-home:hover {
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.8);
}
.description-text {
    color: #3faf2b;
}
</style>
