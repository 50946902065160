<template>
  <b-col class="text-right my-2 p-0">
      <b-nav-item-dropdown class="profile-menu d-none d-lg-block text-right" v-if="signedUser" :text="signedUser.name" right>
        <!-- <b-dropdown-item to="/profile">My Profile</b-dropdown-item> -->
        <b-dropdown-item to="/orders_ar"  v-if="position !== 'Logistics'">مشاهدة ملف</b-dropdown-item>
        <b-dropdown-item to="/abb_clams" >GBB</b-dropdown-item>

        <b-dropdown-item href="https://drive.google.com/drive/folders/18MVtqEUO0O2LXDyVsSlZDSye39TNCDd8?usp=sharing" target="_blank">مواد تدريبية</b-dropdown-item>
        <b-dropdown-item to="/buybacklist" v-if="position === 'Manager' || position === 'Logistics'|| position === 'KSA'">مشاهدة ملف</b-dropdown-item>
        <b-dropdown-item to="/faq" v-if="position !== 'Logistics'">أسئلة وأجوبة</b-dropdown-item>

        <!-- <b-button to="/orders" class="ml-3  text-decoration-none py-0 ccc" variant="link">View Request</b-button> -->

        <b-dropdown-item @click="signOut()">خروج</b-dropdown-item>
      </b-nav-item-dropdown>
    <b-col class="user my-auto pr-0 d-none d-lg-block text-right">
      <b-button v-if="!signedUser" style="background-color: #016938;color: white;" variant="link" v-b-modal.modalSignIn class=" btn-sign-in  py-2 px-4">تسجيل الدخول</b-button>
    </b-col>
    <b-nav-item-dropdown class="profile-menu d-lg-none py-0 text-right" v-if="signedUser" :text="signedUser.name" right style="color: black !important;">
        <b-dropdown-item to="/orders_ar">مشاهدة ملف</b-dropdown-item>
        <b-dropdown-item href="https://drive.google.com/drive/folders/18MVtqEUO0O2LXDyVsSlZDSye39TNCDd8?usp=sharing" target="_blank">مواد تدريبية</b-dropdown-item>
        <b-dropdown-item to="/buybacklist" v-if="signedUser.name === 'Demo Admin'">مشاهدة ملف</b-dropdown-item>
        <b-dropdown-item to="/faq">أسئلة وأجوبة</b-dropdown-item>


        <b-dropdown-item @click="signOut()">خروج</b-dropdown-item>
      </b-nav-item-dropdown>
    <b-col class="user my-auto pr-0 d-lg-none ">
      <b-button v-if="!signedUser" style="background-color: #016938;color: white;" variant="link" v-b-modal.modalSignIn class="  btn-sign-in  py-2 px-4">تسجيل الدخول</b-button>

    </b-col>
          
    <b-modal
    hide-header-close ='true'
    no-close-on-backdrop = 'false'
      @hide="hideSignIn()"
      id="modalSignIn"
      centered
      title=""
      footer-class="border-0 mx-auto"
      header-class="border-0"
    >
      <template #modal-header="{}">
      </template>
      <b-tabs
        v-model="tabIndex"
        active-nav-item-class="font-weight-bold text-uppercase text-success"
        active-tab-class=""
        content-class="mt-3"
      >
        <b-tab title="Partner Sign In" active>
          <!--<b-link @click="googleLogin()"><img width="40" class="mr-2" src="@/assets/imgs/google-login.png" alt="Google" />Sign In with Google</b-link>
          <section id="firebaseui-auth-container"></section> -->
          <b-form-input
            placeholder="Email"
            :state="userNameState"
            v-model="loginName"
            class="mb-3 border-top-0 border-left-0 border-right-0"
          ></b-form-input>
          <b-form-input
            @keydown.enter.native="submitTab()"
            placeholder="Pasword"
            type="password"
            :state="passwordState"
            v-model="userPassword"
            class="mb-3 border-top-0 border-left-0 border-right-0"
          ></b-form-input>
          <p class="text-danger text-center mb-0">{{loginMessage}}</p>
          <a class="ml-3" href="" @click="setActive()">Forgot Password?</a>

        </b-tab>
      </b-tabs>
      <template #modal-footer="{}">
        <b-button squared variant="success" @click="submitTab()" style="font-size: x-large;"
        >{{btnLabel}}</b-button
        >
        <!-- <div v-if="btnLabel == 'Sign Up'">
          <b>OR</b>
        </div>
        <div v-if="btnLabel == 'Sign Up'">
          <v-facebook-login app-id="844362653338545"></v-facebook-login>
        </div> -->
        <!-- <div>
          <b>OR</b>
        </div>
        <div v-if="btnLabel == 'Sign Up'">
          
          <GoogleLogin class="google-signin-btn-3 " :params="params" :onSuccess="onSuccess" :onFailure="onFailure"><img src="../assets/Flat-design-Google-logo-design-Vector-PNG.png" width="40px"/>  Sign Up</GoogleLogin>

        </div>
        <div v-else>
          
          <GoogleLogin class="google-signin-btn-3 " :params="params" :onSuccess="onSuccess" :onFailure="onFailure"><img src="../assets/Flat-design-Google-logo-design-Vector-PNG.png" width="40px"/>  Sign In</GoogleLogin>

        </div> -->
      </template>
    </b-modal>
    <!--
    <b-modal
      @hide="hideSignIn()"
      id="modal-sign-in"
      centered
      title=""
      footer-class="border-0 mx-auto"
      header-class="border-0"
    >
      <template #modal-header="{}">
      </template>
      <b-tabs
        v-model="tabIndex"
        active-nav-item-class="font-weight-bold text-uppercase text-success"
        active-tab-class=""
        content-class="mt-3"
      >
        <b-tab title="Sign In" active>
          <b-link @click="googleLogin()"><img width="40" class="mr-2" src="@/assets/imgs/google-login.png" alt="Google" />Sign In with Google</b-link>
          <section id="firebaseui-auth-container"></section>
          <b-form-input
            placeholder="Email"
            :state="userNameState"
            v-model="loginName"
            class="mb-3 border-top-0 border-left-0 border-right-0"
          ></b-form-input>
          <b-form-input
            placeholder="Pasword"
            type="password"
            :state="passwordState"
            v-model="userPassword"
            class="mb-3 border-top-0 border-left-0 border-right-0"
          ></b-form-input>
          <p class="text-danger text-center mb-0">{{loginMessage}}</p>
        </b-tab>
        <b-tab title="Sign Up" :active="isSignUp">
          <b-form-input
            placeholder="Name"
            :state="nameState"
            v-model="userName"
            class="mb-3 border-top-0 border-left-0 border-right-0"
          ></b-form-input>
          <b-form-input
            placeholder="Email"
            :state="emailState"
            v-model="userEmail"
            class="mb-3 border-top-0 border-left-0 border-right-0"
          ></b-form-input>
          <b-form-input
            type="number"
            placeholder="Phone Number"
            :state="mobileState"
            v-model="userMobile"
            class="mb-3 border-top-0 border-left-0 border-right-0"
          ></b-form-input>
          <b-form-input
            type="password"
            placeholder="Password"
            :state="passwordState"
            v-model="userPassword"
            class="mb-3 border-top-0 border-left-0 border-right-0"
          ></b-form-input>
          <b-form-input
            type="password"
            placeholder="Confirm Password"
            :state="passwordConfirmState"
            v-model="confirmPassword"
            class="mb-3 border-top-0 border-left-0 border-right-0"
          ></b-form-input>
        </b-tab>
      </b-tabs>
      <template #modal-footer="{}">
        <b-button squared variant="success" @click="submitTab()"
        >{{btnLabel}}</b-button
        >
      </template>
    </b-modal>-->
    <b-modal
      id="modalVerification"
      centered
      title=""
      footer-class="border-0 mx-auto"
      header-class="border-0"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-header-close="true"
    >
      <template #modal-header="{}">
      </template>
      <b-col class="wraper">
        <div v-if="regStep < 5" class="register-wraper">
          <div v-if="regStep === 1" class="otp-generator">
            <!-- <p class="text-right"><b-link @click="regStep = 3" class="skip-link">Skip Now</b-link></p> -->
            <div class="thanks-wrap">
              <p class="thanks-text">Thank you for signing up, {{userName}}!</p>
            </div>
            <p class="otp-title">OTP Verification</p>
            <!-- <img src="@/assets/imgs/phone-ringing.png" width="40" alt="OTP" /> -->
            <p class="thanks-text mt-4">We will send you an <span class="text-dark-grey">One Time Password</span></p>
            <p v-if="!mobileFieldOpen">{{maskedMobile}}</p>
            <p v-else>
              <b-input-group class="mb-3">
                <template #prepend>
                  <b-input-group-text class="phone-code">+971</b-input-group-text>
                </template>
                <b-form-input
                  type="number"
                  max="999999999"
                  placeholder="Phone Number"
                  :state="mobileState"
                  v-model="userMobile"
                  class="input-login border-top-0 border-left-0 border-right-0"
                  :formatter="mobileFormatter">
                </b-form-input>
              </b-input-group>
            </p>
            <div style="max-width: 200px; margin: 0 auto">
              <b-button v-if="!mobileFieldOpen" squared block variant="outline-success" class="mb-2" @click="editField()">Edit Mobile Number</b-button>
              <b-button v-if="mobileFieldOpen" squared block variant="success" class="mb-2" @click="saveField()">Save</b-button>
              <b-button v-if="!mobileFieldOpen" squared block variant="success" class="mb-2" @click="getOTP()">Get OTP</b-button>
            </div>
          </div>
          <div v-if="regStep === 2" class="otp-verification">
            <p class="text-left"><button class="btn" @click="regStep = 1"><span><BIconArrowLeft/> Back</span></button></p>
            <div class="thanks-wrap">
              <p class="otp-title">OTP Verification</p>
            </div>
            <p class="thanks-text mt-4">Enter the OTP sent to <span class="text-dark-grey">{{maskedMobile}}</span></p>
            <b-form inline class="justify-content-center add_space">
              <b-form-input
                onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
                v-model="otp1"
                ref="otpInput1"
                data-index="1"
                type="text"
                max="9"
                min="0"
                inputmode="numeric"
                :formatter="otpFormatter"
                class="col-auto m-0 p-0 otp-inputnew"
                @input="handleOTPInput1($event)"
              ></b-form-input>
              <b-form-input
                onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
                type="text"
                ref="otpInput2"
                data-index="2"
                max="9"
                min="0"
                inputmode="numeric"
                v-model="otp2"
                :formatter="otpFormatter"
                class="col-auto m-0 p-0 otp-inputnew"
                @input="handleOTPInput2($event)"
              ></b-form-input>
              <b-form-input
                onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
                v-model="otp3"
                :formatter="otpFormatter"
                type="text"
                max="9"
                min="0"
                inputmode="numeric"
                ref="otpInput3"
                data-index="3"
                class="col-auto m-0 p-0 otp-inputnew"
                @input="handleOTPInput3($event)"
              ></b-form-input>
              <b-form-input
                onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
                v-model="otp4"
                :formatter="otpFormatter"
                type="text"
                max="9"
                min="0"
                inputmode="numeric"
                ref="otpInput4"
                data-index="4"
                class="col-auto m-0 p-0 otp-inputnew"
              ></b-form-input>
            </b-form>
            <p class="text-danger text-center">{{otpVerifyText}}</p>
            <div style="max-width: 210px; margin: 0 auto">
              <p class="resend-otp-text">Don't receive the OTP? <b-link @click="getOTP()" class="link">Resend OTP</b-link></p>
              <b-button squared block variant="success" class="mb-2" @click="verifyOTP()"
              >Verify & Proceed</b-button>
            </div>
          </div>
          <div v-if="regStep === 3" class="email-generator">
            <p class="text-right"><b-link @click="closeVerification()" class="skip-link">Skip Now</b-link></p>
            <div class="thanks-wrap">
              <p class="thanks-text">Thank you for signing up, {{userName}}!</p>
            </div>
            <p class="otp-title">Email Verification</p>
            <!-- <img src="@/assets/imgs/r3-factory-email.png" width="50" alt="Email" /> -->
            <p v-if="!emailFieldOpen" class="thanks-text mt-4 text-dark-grey">
              Please verify your email address: <br/> {{userEmail}}
              </p>
            <p v-else>
              <b-form-input
                placeholder="Email"
                :state="emailState"
                v-model="userEmail"
                class="mb-3 border-top-0 border-left-0 border-right-0"
              ></b-form-input>
            </p>
            <div style="max-width: 200px; margin: 0 auto">
              <b-button v-if="!emailFieldOpen" squared block variant="outline-success" class="mb-2" @click="editField()">
                Edit Email ID
              </b-button>
              <b-button v-if="emailFieldOpen" squared block variant="success" class="mb-2" @click="saveField()">
                Save
              </b-button>
              <b-button v-if="!emailFieldOpen" squared block variant="success" class="mb-2" @click="sendEmail()">
                Verify Email Now
              </b-button>
            </div>
          </div>
          <div v-if="regStep === 4" class="otp-verification">
            <p class="text-left"><button class="btn" @click="regStep = 3"><span><BIconArrowLeft/> Back</span></button></p>
            <div class="thanks-wrap">
              <p class="otp-title">Email Verification</p>
            </div>
            <div class="px-2 text-left">
              <p class="thanks-text mt-4">Approve the notification sent to:</p>
              <p class="thanks-text">Email address <span class="text-dark-grey ml-3">{{maskedEmail}}</span></p>
              <p class="resend-email-label">Didn't receive it?</p>
              <p class="resend-email-text"> <b-link @click="sendEmail()" class="link">Resend Link</b-link></p>
              <p class="resend-email-des">Notification sent. This may take a minute to arrive. If needed, you may request a new notification in 50 second(s).</p>
              <p class="resend-email-label">Did you already respond?</p>
              <p class="resend-email-text"> <b-link @click="closeVerification()" class="link">Click here to refresh the page.</b-link></p>
            </div>
          </div>
        </div>
      </b-col>
      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>
  </b-col>
</template>

<script>

import api from '../api'
import { BIconArrowLeft } from 'bootstrap-vue'
import GoogleLogin from 'vue-google-login';

export default {
  name: 'SignIn',
  props: [],
  components: {
    BIconArrowLeft,
    GoogleLogin
  },
  data () {
    return {
      position: null,
      userName: this.$store.state.signUpForm ? this.$store.state.signUpForm.name : '',
      userEmail: this.$store.state.signUpForm ? this.$store.state.signUpForm.email : '',
      userMobile: this.$store.state.signUpForm ? this.$store.state.signUpForm.mobile : '',
      userPassword: '',
      loginName: '',
      confirmPassword: '',
      loginMessage: '',
      signUpMessage: '',
      tabIndex: 0,
      regStep: 0,
      otpGenerated: '',
      otpVerifyText: '',
      otp1: '',
      otp2: '',
      otp3: '',
      otp4: '',
      isMobileVerified: false,
      userId: null,
      mobileFieldOpen: false,
      emailFieldOpen: false,
      params: {
        client_id: "127145128745-chlduhthucp87gk1unmibbl96g6vjsvm.apps.googleusercontent.com"
        },
        // only needed if you want to render the button with the google ui
            renderParams: {
            width: 250,
            height: 50,
            longtitle: true
        }
    }
  },
  computed: {
    signedUser: function () {
      return this.$store.state.user
    },
    isSignUp: function () {
      if (this.$store.state.signUpForm === null) {
        return false
      } else {
        return true
      }
    },
    maskedEmail: function () {
      var atIndex = this.userEmail.indexOf('@')
      if (this.userEmail && atIndex !== -1) {
        return this.userEmail.substr(0, 1) + '******' + this.userEmail.substr(atIndex)
      } else {
        return ''
      }
    },
    maskedMobile: function () {
      if (this.userMobile && this.userMobile.length >= 9) {
        return '971 ' + this.userMobile
      } else {
        return ''
      }
    },
    btnLabel: function () {
      if (this.tabIndex) {
        return 'Sign Up'
      } else {
        return 'Sign In'
      }
    },
    emailState: function () {
      if (this.userEmail.length) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(this.userEmail).toLowerCase())
      } else {
        return null
      }
    },
    userNameState: function () {
      if (this.loginName.length) {
        return this.loginName.length > 2
      } else {
        return null
      }
    },
    mobileState: function () {
      if (this.userMobile.length) {
        return this.userMobile.length === 9
      } else {
        return null
      }
    },
    nameState: function () {
      if (this.userName.length) {
        return this.userName.length > 2
      } else {
        return null
      }
    },
    passwordState: function () {
      if (this.userPassword.length) {
        return this.userPassword.length > 3
      } else {
        return null
      }
    },
    passwordConfirmState: function () {
      if (this.userPassword.length && this.confirmPassword.length) {
        return this.confirmPassword === this.userPassword && this.userPassword.length > 3
      } else {
        return null
      }
    }
  },
  methods: {
    onSuccess(googleUser) {
            console.log(googleUser);

            // This only gets the user information: id, name, imageUrl and email
            console.log(googleUser.getBasicProfile());
        },
    handleOTPInput1: function (value) {
      if (value !== '' || value !== null) {
        try {
          this.$refs.otpInput2.focus()
        } catch (e) {
          console.log(e)
        }
      }
    },
    handleOTPInput2: function (value) {
      if (value !== '' || value !== null) {
        try {
          this.$refs.otpInput3.focus()
        } catch (e) {
          console.log(e)
        }
      }
    },
    handleOTPInput3: function (value) {
      if (value !== '' || value !== null) {
        try {
          this.$refs.otpInput4.focus()
        } catch (e) {
          console.log(e)
        }
      }
    },
    mobileFormatter: function (e) {
      return String(e).substring(0, 9)
    },
    otpFormatter: function (e) {
      return String(e).substring(0, 1)
    },
    verifyOTPF: function () {
      this.otpVerifyText = ''
      const otpEntered = this.otp1 + this.otp2 + this.otp3 + this.otp4
      console.log('otp', otpEntered)
      if (this.otpGenerated.toString() === otpEntered.toString()) {
        this.otpVerifyText = 'Mobile Number verified'
        this.isMobileVerified = true
        this.regStep = 3
        this.$store.commit('SET_MOBILE_VERIFIED', this.isMobileVerified)
      } else {
        this.otpVerifyText = 'Invalid OTP'
      }
    },
    verifyOTP: function () {
      const otpEntered = this.otp1 + this.otp2 + this.otp3 + this.otp4
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'res.users',
          'method': 'verify_user_otp',
          'args': {
            'user_id': this.userId,
            'otp': otpEntered
          }
        },
        'modecode': 100
      }
      api
        .request('post', 'update', inputs)
        .then((response) => {
          var data = response.data
          if (data.errorcode) {
            console.log(data.errormessage)
          }
          console.log(data.result)
          if (data.result && typeof data.result[0] === 'object' && data.result[0].mobile_verified === true) {
            this.otpVerifyText = 'Mobile Number verified'
            this.isMobileVerified = true
            this.regStep = 3
            this.$store.commit('SET_MOBILE_VERIFIED', this.isMobileVerified)
          } else {
            this.otpVerifyText = 'OTP verification Failed!'
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    setMobileVerified: function () {
      if (this.$store.state.user && this.$store.state.mobileVerified === true) {
        const inputs = {
          'inputs': {
            'session_id': this.$store.state.token,
            'model': 'res.users',
            'method': 'update_user_data',
            'args': { 'user_id': this.$store.state.user.uid, 'mobile_verified': this.isMobileVerified }
          },
          'modecode': 100
        }
        api.request('post', 'update', inputs).then((response) => {
          if (response.data && response.data.result) {
            let user = this.$store.state.user
            const contacts = user.contact_address
            const primaryContact = contacts[0]
            const updatedPrimaryContact = { ...primaryContact, mobile_verified: this.isMobileVerified }
            contacts[0] = updatedPrimaryContact
            user = { ...this.$store.state.user, contact_address: contacts }
            this.$store.commit('SET_USER', user)
            this.$store.commit('SET_MOBILE_VERIFIED', null)
          }
        }).catch(() => {
          alert('Somthing went wrong while verifying your number. Please try again later. If this issue persist please contact us')
        })
      }
    },
    closeVerification: function () {
      setTimeout(() => this.$bvModal.hide('modalVerification'), 10)
      this.signIn()
    },
    getOTPF: function () {
      this.regStep = 2
      this.otpGenerated = Math.floor(1000 + Math.random() * 9000)
      const text = 'Dear Customer, OTP for verifying your mobile number is ' + this.otpGenerated
      const inputs = {
        'inputs': {
          'mobile': '971' + this.userMobile,
          'text': text
        },
        'modecode': 100
      }
      api
        .request('post', 'message', inputs)
        .then((response) => {
          console.log('API Response:', response)
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    getOTP: function () {
      this.regStep = 2
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'res.users',
          'method': 'generate_user_otp',
          'args': {
            'user_id': this.userId,
            'mobile': '+971' + this.userMobile
          }
        },
        'modecode': 100
      }
      api
        .request('post', 'update', inputs)
        .then((response) => {
          var data = response.data
          if (data.errorcode) {
            console.log(data.errormessage)
          }
          if (data.result) {
            console.log(data.result)
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    sendEmail: function () {
      this.regStep = 4
      const inputs = {
        'inputs': {
          'email': this.userEmail,
          'name': this.userName,
          'user_id': this.userId,
          'session_id': this.$store.state.token
        },
        'modecode': 100
      }
      api
        .request('post', 'verify-email', inputs)
        .then((response) => {
          console.log('API Response:', response)
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    signOut: function () {
      this.$store.commit('CEAR_CART')
      this.$store.commit('SET_USER', null)
      localStorage.removeItem('token_data')
      localStorage.removeItem('user_token')
    },
    submitTab: function () {
      if (this.tabIndex) {
        this.signUp()
      } else {
        if (this.userNameState && this.passwordState) {
          this.signIn()
        }
      }
    },
    hideSignIn: function () {
      setTimeout(() => {
        if (this.$store.state.user === null) {
          this.$store.commit('UPDATE_CHECKOUT', 'login')
        }
      }, 500)
    },
    signIn: function () {
      const inputs = {
         
          'email': this.loginName,
          'password': this.userPassword, 
          'red': true
      }
     
      api
        .request(process.env.VUE_APP_Get_Signin, inputs)
        .then((response) => {
          // console.log('API Response:', response)
          var data = response.data
          // console.log('API Response Data:', data)
          if (data.errorcode) {
            this.loginMessage = 'Invalid Credentials!'
            return
          }
          if (data.result) {
            console.log('data.result.mobile_verified')
            // console.log('auth restposnse:', data.result)
            if (data.result.uid) {
              this.$store.commit('SET_USER', data.result)
              console.log(data.result)
              setTimeout(() => this.$bvModal.hide('modalSignIn'), 10)
            }
            //   if (data.result.mobile_verified === true) {
            //     alert('You are not authorised user to approve this order.')
            //     this.$store.commit('CLEAR_SELECTION')
            //     this.$store.commit('SET_USER', null)
            //   } else {
            //     alert('You are not authorised user to approve this order.')
            //     this.$store.commit('CLEAR_SELECTION')
            //     this.$store.commit('SET_USER', null)
            //     return
            //   }
            //   // setTimeout(() => this.$bvModal.hide('modal-sign-in'), 10)
            //   setTimeout(() => this.$bvModal.hide('modalSignIn'), 10)
            else {
              this.loginMessage = 'Invalid Credentials!'
            }
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    }, /*
    googleLogin: function () {
      const provider = new firebase.auth.GoogleAuthProvider()
      firebase.auth().signInWithPopup(provider).then((result) => {
        console.log('Google auth result:', result)
      }).catch((err) => {
        alert('Oops. ' + err.message)
      })
    }, */
    signUp: function () {
      // this.$bvModal.hide('modal-sign-in')
      this.signUpMessage = ''
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'res.users',
          'method': 'data_user_creation',
          'args': { 'name': this.userName, 'login': this.userEmail, 'password': this.userPassword, 'mobile': '971' + this.userMobile }
        },
        'modecode': 100
      }
      console.log('User Creation input', inputs)
      api
        .request('post', 'update', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            this.response = data.errormessage
            return
          }
          if (data.result) {
            console.log('user creation restposnse:', data.result)
            // this.userPassword = 'password'
            this.loginName = this.userEmail
            if (data.result[0].message === 'User Already Exist') {
              this.signUpMessage = 'Email or Phone Number Already Exists!'
            } else {
              // this.signIn()
              this.userId = data.result[0].user_id[0][0]
              setTimeout(() => this.$bvModal.hide('modalSignIn'), 10)
              setTimeout(() => this.$bvModal.show('modalVerification'), 10)
              this.regStep = 1
            }
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    approveAmount: function () {
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'job.registration',
          'method': 'approve_job',
          'args': {
            'job_order_id': this.$store.state.activeOrder.job_name
          }
        },
        'modecode': 100
      }
      console.log('Orders Approve input', inputs)
      api
        .request('post', 'update', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            this.response = data.errormessage
            return
          }
          if (data.result) {
            console.log('Orders Approve restposnse:', data.result)
            this.$bvToast.toast(data.result[0], {
              title: 'Approved',
              variant: 'success',
              toaster: 'b-toaster-top-center'
            })
            setTimeout(function () {
              self.$router.push('/')
            }, 1000)
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    verifyEmail: function () {
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'res.users',
          'method': 'update_email_verified',
          'args': {
            'user_id': this.$store.state.user.uid,
            'email_verified': 'True'
          }
        },
        'modecode': 100
      }
      console.log('Verify Email Update input', inputs)
      api
        .request('post', 'update', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            this.response = data.errormessage
            return
          }
          if (data.result) {
            console.log('Verify Email restposnse:', data.result)
            this.$bvToast.toast('Your email has been verified!', {
              title: 'Email Verified',
              variant: 'success',
              toaster: 'b-toaster-top-center'
            })
            setTimeout(function () {
              self.$router.push('/')
            }, 1000)
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },

    setActive: function () {
        this.$router.push({ name: 'ForgotPassword' })
        },
    editField: function () {
      this.mobileFieldOpen = true
      this.emailFieldOpen = true
    },
    getData: function () {
      var token_data = JSON.parse(localStorage.getItem('token_data'));
        const inputs = {
         
              'user_id': this.signedUser.uid,
              'user_token': localStorage.getItem('user_token'),
              'token_data':token_data
             
        }
        const url = 'dataset'
        api
          .request(process.env.VUE_APP_Get_Data, inputs)
          .then((response) => {
            var data = response.data
            if (data.errorcode) {
              // this.response = data.errormessage
              return
            }
            if (data.result) {
              this.position = data.result
              if (this.position === 'Logistics') {
                // this.$router.push({ name: 'Buyback'})
              }
            }
          })
          .catch((error) => {
            console.log('What happened? ' + error)
          })
      },
    saveField: function () {
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'res.users',
          'method': 'update_user_data',
          'args': {
            'user_id': this.userId,
            'mobile': '971' + this.userMobile,
            'email': this.userEmail,
            'login': this.userEmail
          }
        },
        'modecode': 100
      }
      api.request('post', 'update', inputs).then((response) => {
        var data = response.data
        if (data.errorcode) {
          throw data.errorcode
        }
        if (data.result) {
          this.mobileFieldOpen = false
          this.emailFieldOpen = false
        }
      }).catch((error) => {
        alert(error)
      })
    }
  },
  mounted () {
    if (this.$store.state.user) { 
      this.getData()
    }

    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      if (modalId === 'modalSignIn') {
        this.userName = this.$store.state.signUpForm ? this.$store.state.signUpForm.name : ''
        this.userEmail = this.$store.state.signUpForm ? this.$store.state.signUpForm.email : ''
        this.userMobile = this.$store.state.signUpForm ? this.$store.state.signUpForm.mobile : ''
      }
      
      /* var ui = new firebaseui.auth.AuthUI(firebase.auth())
      var uiConfig = {
        signInSuccessUrl: '/reuse',
        signInOptions: [firebase.auth.FacebookAuthProvider.PROVIDER_ID]
      }
      ui.start('#firebaseui-auth-container', uiConfig) */
    })
  }
}
</script>

<style>
.user{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.user span {
  display: block;
  width: 110px;
  text-align: left;
  line-height: 12px;
  font-size: 12px;
  /* background-image: url('../assets/imgs/signin.png'); */
  background-repeat: no-repeat;
  background-size: 17px;
  padding-left: 24px;
  color: #333530;
}
.btn-sign-in{
  /* background-image: url('../assets/imgs/signin.png'); */
  background-repeat: no-repeat;
  background-size: 17px;
  padding-left: 24px;
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #333530;
}
.btn-sign-in:hover {
  /* background-image: url('../assets/imgs/signined.png'); */
  color: #28a745;
}
.nav-tabs .nav-link{
  color: #000;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
  border-width: 0px 0px 3px 0px;
  border-top: 0px;
  border-color: #ffffff #ffffff #28a745 #ffffff;
}
.navbar-dark .navbar-nav .nav-link{
  color: #333530;
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus{
  color: #333530;
}
.profile-menu .dropdown-item.active, .profile-menu .dropdown-item:active{
  background-color: #28a745;
}
.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active{
  color: #333530;
}
</style>

<style scoped>
.hidden, .show {
  transform: none;
}
.register-wraper{
  max-width: 300px;
  margin: 0 auto;
  border: 2px solid #ccc;
  padding: 10px 15px;
  text-align: center;
}
.reg-type-selector-list{
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}
.title{
  font-size: 16px;
  font-weight: bold;
  color: #777;
  margin-top: 15px;
}
.input-login{
  border-top: 0px;
}
.phone-code{
  background-color: #fff;
  border-top:0;
  border-left: 0;
}
.register-form .input-login {
  border-left: 0;
  border-right: 0;
}
.input-login:focus{
  border-color: #3FAF2B;
  border-top: 1px solid #3FAF2B;
  border-left: 1px solid #3FAF2B;
  border-right: 1px solid #3FAF2B;
  box-shadow: none;
}
.link{
  color:#3FAF2B;
  margin-top: 10px;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
}
.skip-link, .skip-link:hover{
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  color: rgba(56, 57, 52, 0.51);
}
.skip-link:hover{
  color: rgb(56, 57, 52);
}
.thanks-wrap{
  margin-top: 30px;
  border-bottom: 1px solid #E5E5E5;
  margin-bottom: 30px;
}
.thanks-text{
  color: rgba(56, 57, 52, 0.71);
}
.otp-title{
  color: #3FAF2B;
  font-weight: bold;
  font-size: 24px;
}
.text-dark-grey{
  color: rgb(56, 57, 52);
}
.resend-email-des{
  color: rgba(56, 57, 52, 0.71);
  margin-top: 20px;
  margin-bottom: 20px;
}
.resend-otp-text, .resend-email-label{
  color: rgb(56, 57, 52);
  font-size: 12px;
  font-weight: bold;
  margin-top: 30px
}
.resend-email-label{
  margin-bottom: 0px;
}
.resend-email-text .link {
  font-size: 12px;
}
.resend-otp-text .link {
  font-size: 12px;
}
.otp-input{
  background-color: rgba(56, 57, 52, 0.05);
}
@media (min-width: 668px) {
  .register-wraper{
    max-width: 520px;
  }
}
.otp-inputnew {
  width: 45px;
  height: 40px;
  padding: 5px;
  margin: 0 10px !important;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-style: dashed;
  text-align: center;
  margin-right: 50px;
}
/* Background colour of an input field with value */
.otp-inputnew.is-complete {
  background-color: #e4e4e4;
}
.otp-inputnew::-webkit-inner-spin-button,
.otp-inputnew::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
.google-signin-btn-3 {
  /* This is where you control how the button looks. Be creative! */
  display: inline-block;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: transparent;
  color: rgb(71, 68, 68);
  box-shadow: 0 1px 0 #a0a7b3;
  font-weight: bolder;
  font-size: x-large;
  border-radius: 10px;
}
a {
  color: #000000 !important;
}
span {
  color: #000000 !important;

}
</style>
