<template>
  <div>
    <div class="row justify-content-between">
      <div @click="tabIndex = 0"
        v-bind:class="{ 'border rounded col p-3 m-2 font-weight-bold active-tab': tabIndex === 0, 'border rounded col p-3 m-2 shadow bg-white': tabIndex !== 0 }">
        <div class="row no-gutters justify-content-center">
          <!-- <div class="col-auto"><img src="@/assets/imgs/reuse.png" class="image-icon mr-3" alt="Buy" /></div> -->
          <div class="col-auto d-flex mt-2">
            <div class="tab-label">GBB Claim History</div>
            <div class="tab-count ml-3">{{ repairCount }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row align-items-center justify-content-end">
        <div class="col-md-3 col-12 mb-2">
          <label for="start-date">Start Date</label>
          <b-form-input id="start-date" v-model="startDate" type="date" class="inp"></b-form-input>
        </div>
        <div class="col-md-3 col-12 mb-2">
          <label for="end-date">End Date</label>
          <b-form-input id="end-date" v-model="endDate" type="date" class="inp"></b-form-input>
        </div>
        <div class="col-md-2 col-12 mb-2 align-self-end">
          <b-button variant="success" @click="handle_date()">Search</b-button>
        </div>
      </div>
    </div>

    <h6 v-if="filterOrders.length == []">No Data Found</h6>

    <order-table v-if="filterOrders.length" :orderList="filterOrders" />
  </div>
</template>

<script>
import OrderTable from './AbbOrderTable.vue'
export default {
  name: 'OrderList',
  props: ['orders'],
  components: {
    OrderTable
  },
  data() {
    return {
      orderList: false,
      tabIndex: 0,
      startDate: null,
      endDate: null
    }
  },
  watch: {
    orders(newdata) {
      this.orderList = newdata
    }
  },
  computed: {
    filterOrders: function () {
      if (!this.orderList) {
        return []
      }
      return this.orderList.filter((item) => {
        switch (this.tabIndex) {
          case 0: return item.id
        }
      })
    },
    repairCount: function () {
      if (!this.orderList) {
        return 0
      }
      let tempOrders = this.orderList
      return tempOrders.length
    },

  },
  methods: {
    handle_date: function () {
      if (this.startDate !== null && this.endDate !== null) {
        this.$emit('updateData', { startDate: this.startDate, endDate: this.endDate });
      } else {
        this.$bvToast.toast('We are not able to process this request right now. Please select the dates', {
          title: 'Missing Date',
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        })
      }
    }
  },
  mounted() { }
}
</script>

<style scoped>
.tab-count {
  font-weight: bold;
  color: #28a745;
}

.tab-label {
  font-weight: bold;
  color: rgb(94, 94, 94);
}

.image-icon {
  max-width: 6vw;
  max-height: 6vh;
  border-radius: 50%;
}

.active-tab {
  border: 2px solid #28a745 !important;
}
</style>
