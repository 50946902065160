<template>
  <div>
    <Header/>
    <div class="home mt-1">
      <div v-if="errormessage" class="container mt-2">
        <div  class="d-block m-md-5">{{errormessage}}</div>
      </div>
      <div v-else class="container mt-2">
        <div class="row">
          <div class="col">
              <p class="tite" style="font-size: 2.5em;">View Request History</p>
          </div>
        </div>
        <div class="row justify-content-center">
        <order-list :orders="BuybackList" class="col-md-9"/>

        </div>
        <b-pagination
        v-model="currentPage"
        :total-rows="getRows"
        :per-page="perPage"
        aria-controls="product-list"
        pills
        size="md"
        align="center"
        
      ></b-pagination>
      </div>
      
    </div>
    <!-- <Footer/> -->
  </div>
</template>

<script>
import api from '../api'
import Header from '../components/Header2.vue'
// import ServiceBar from '../components/ServiceBar.vue'
import Footer from '../components/Footer.vue'
import OrderList from '../components/OrderList.vue'

export default {
  name: 'Orders',
  data () {
    return {
      currentPage: 1,
      perPage:10,
      errormessage: false,
      data: []
    }
  },
  props: [],
  computed: {
    getRows: function () {
      return this.data.length
    },
    BuybackList: function () {
      return this.data.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      )
    }
  },
  components: {
    Header,
    // ServiceBar,
    Footer,
    OrderList
  },
  methods: {
    getOrders: function () {
      const inputs = {
        
            'user_id': this.$store.state.user.uid,
            'user_token': this.$store.state.user.user_token,
            'token_data':this.$store.state.user.token_data
          
      }
      api.request(process.env.VUE_APP_Get_Buyback_Data, inputs).then((response) => {
        const data = response.data
        if (data.errorcode) {
          this.errormessage = data.errormessage
        } else if (data.result && typeof data.result === 'object') {
          this.data = data.result
        } else if (data.result && typeof data.result === 'string') {
          this.errormessage = data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    }
  },
  mounted () {
    if (!this.$store.state.user) {
      this.errormessage = 'To view orders, please log in'
      this.$bvModal.show('modalSignIn')
    } else {
      this.getOrders()
    }
  }
}
</script>

<style scoped>
.info-box{
  margin-right: 0 3px;
}
.title-bar{
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 15px;
}
.tite{
  color: #37a531;
  font-family: 'Avenir Black', sans-serif;
}
</style>
