<template>
    <div>
      <Header/>
      <div class="home mt-1">
        <header>
          <!-- <ServiceBar :items="services" /> -->
        </header>
        <div class="mt-5">
          <!--<img class="mb-3" src="@/assets/imgs/logo.png" /> -->
          <p class="title text-center mt-3">Congratulations</p>
          <h4 class="">Your password has been changed successfully</h4>
          <b-button class="mt-3" variant="success"   style="font-size: larger;"  @click="Homepage()">Main Page</b-button>
        </div>
      </div>
      <Footer/>
    </div>
  </template>
  
  <script>
  import Header from '@/components/Header.vue'
  // import ServiceBar from '@/components/ServiceBar.vue'
  import Footer from '@/components/Footer.vue'
  import services from '@/data/services'
  export default {
    name: 'PasswordChanged',
    components: {
      Header,
      // ServiceB1ar,
      Footer
    },
    computed: {
    },
    data () {
      return {
        services
      }
    },
    methods: {
    Homepage: function () {
        this.$router.push({ name: 'LandingPage' })
   }
    }
  }
  </script>
  
  <style scoped>
  .thanks{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
    justify-content: center;
  }
  h4{
    font-weight: 700;
  }
  .title{
    color: #37a531;
    font-size: 30px;
    font-family: 'Avenir Black', sans-serif;
  }
  </style>
  