import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import LandingPage from '../views/LandingPage'
import LandingPage2 from '../views/LandingPage_ar.vue'

import ABB from '../views/ABB.vue'
import ABB_Onboarding from '../views/ABB_Onboarding.vue'
import ABB_Track from '../views/TrackABB.vue'
import ABB_Onboarding_Track from '../views/Track_ABB_Onboarding.vue'

import Mobile from '../components/Mobiles.vue'
import Mobile2 from '../components/Mobiles_ar.vue'
import Tablets from '../components/Tablets.vue'

import Laptop from '../components/Laptop.vue'
import Headphones from '../components/headphones.vue'
import Smartwatches from '../components/Smartwatches.vue'
import Tablets2 from '../components/Tablets_ar.vue'

import Laptop2 from '../components/Laptop_ar.vue'
import Headphones2 from '../components/headphones_ar.vue'
import Smartwatches2 from '../components/Smartwatches_ar.vue'
import Orders from '../views/Orders.vue'
import Abb_records from '../views/Abb_records.vue'
import Orders_ar from '../views/Orders_ar.vue'
import Orders2 from '../views/Orders2.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ForgotPasswordEnter from '../views/ForgotPasswordEnter.vue'
import PasswordChanged from '../views/PasswordChanged.vue'
import store from '../store'
import api from '../api'
import PageNotFound from '../views/PageNotFound.vue'
import FAQ from '../views/FAQ.vue'
import TrackBuyback from '../views/TrackBuyback.vue'
import TrackQuote from '../views/Trackquote.vue'
import TrackBuyback_ar from '../views/TrackBuyback_ar.vue'
import TrackBuyback2 from '../views/TrackBuyback2.vue'

import Calculator from '../views/Calculator.vue'

// const getSession = (to, from, next) => {
//   if (store.state.token === null) {
//     const inputs = {
//       'inputs': {
//         'login': 'admin',
//         'password': 'admin'
//       },
//       'modecode': 100
//     }
//     const url = 'admin'
//     api.request('post', url, inputs, 'login').then((response) => {
//       var data = response.data
//       if (data.error) {
//         this.error = 'Login Failed!'
//         return
//       }
//       if (data.result) {
//         var userAuth = data.result.session_id
//         store.commit('SET_TOKEN', userAuth)
//         next()
//         if (window.localStorage) {
//           window.localStorage.setItem('user', userAuth)
//         }
//       }
//     }).catch(() => {
//              next()

//     })
//   } else {
//     next()
//   }
// }


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
    meta: { facebookDomainVerification: 'h2trof9ymlvqyf8fd4k8yaabyf35h7' },
    children: [
    ]
  },
  {
    path: '/calculator',
    name: 'Calculator',
    component: Calculator
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: { facebookDomainVerification: 'h2trof9ymlvqyf8fd4k8yaabyf35h7' },
    children: [
    ]
  },
  {
    path: '/forgotpasswordlink',
    name: 'ForgotPasswordEnter',
    component: ForgotPasswordEnter,
    meta: { facebookDomainVerification: 'h2trof9ymlvqyf8fd4k8yaabyf35h7' },
    children: [
    ]
  },
  {
    path: '/passwordchanged',
    name: 'PasswordChanged',
    component: PasswordChanged,
    meta: { facebookDomainVerification: 'h2trof9ymlvqyf8fd4k8yaabyf35h7' },
    children: [
    ]
  },
  // {
  //   path: '/about-sell-old-phone-buy-used-mobile-online-and-mobile-repair-dubai-uae',
  //   name: 'Home',
  //   component: Home,
  //   meta: { facebookDomainVerification: 'h2trof9ymlvqyf8fd4k8yaabyf35h7' },
  //   children: [
  //   ]
  // },
 
  {
    path: '/ar',
    name: 'LandingPage2',
    component: LandingPage2,
  },
  {
    path: '/mobiles_ar-:cate_id',
    name: 'Mobile2',
    component: Mobile2,
    props:true
  },
  {
    path: '/mobiles-:cate_id',
    name: 'Mobile',
    component: Mobile,
    props:true
  },
  {
    path: '/laptop-:cate_id',
    name: 'Laptop',
    component: Laptop
  },
  {
    path: '/smartwatches-:cate_id',
    name: 'Smartwatches',
    component: Smartwatches
  },
  {
    path: '/headphones-:cate_id',
    name: 'Headphones',
    component: Headphones
  },
  {
    path: '/tablets-:cate_id',
    name: 'Tablets',
    component: Tablets
  },
  {
    path: '/laptop_ar-:cate_id',
    name: 'Laptop2',
    component: Laptop2
  },
  {
    path: '/abb_clams',
    name: 'ABB',
    component: ABB
  },
  {
    path: '/abb_onboarding',
    name: 'ABB Onboarding',
    component: ABB_Onboarding
  },
  {
    path: '/abb_onboarding/track/:id',
    name: 'ABB_Onboarding_Track',
    component: ABB_Onboarding_Track
  },
  {
    path: '/abb_clams/track/:id',
    name: 'ABB_Track',
    component: ABB_Track,
    props: true
  },
  {
    path: '/smartwatches_ar-:cate_id',
    name: 'Smartwatches2',
    component: Smartwatches2
  },
  {
    path: '/headphones_ar-:cate_id',
    name: 'Headphones2',
    component: Headphones2
  },
  {
    path: '/tablets_ar-:cate_id',
    name: 'Tablets2',
    component: Tablets2
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
    props: true
  },
  {
    path: '/abb_records',
    name: 'Abb_records',
    component: Abb_records,
    props: true
  },
  {
    path: '/orders_ar',
    name: 'Orders_ar',
    component: Orders_ar,
    props: true
  },
  {
    path: '/buybacklist',
    name: 'Buyback',
    component: Orders2,
    props: true
  },
  {
    path: '/orders/sell/:id',
    name: 'SellOrders',
    component: TrackBuyback,
    props: true
  },
  {
    path: '/quote/:id',
    name: 'TrackQuote',
    component: TrackQuote,
    props: true
  },
  {
    path: '/orders_ar/sell_ar/:id',
    name: 'SellOrders_ar',
    component: TrackBuyback_ar,
    props: true
  },
  {
    path: '/batch/:id',
    name: 'SellOrders2',
    component: TrackBuyback2,
    props: true
  },
  {
    path: '/faq',
    component: FAQ,
    name: 'FAQ'
  },
  {
    path: '*',
    component: PageNotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

// router.beforeEach(getSession)

export default router
