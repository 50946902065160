<template>
    <div>
        <Header />
        <hr/>
        <div class="container p-5">
            <div class="5">
                <h3>Get an Instant Quotation for Your Device!</h3>
                <h5>Unlock Your Device's True Value.</h5>
                <!-- <p>
                    {{ userList }}
                </p> -->
            </div>
            <!-- <div class="d-flex justify-content-center mt-3 ">
                <h6 class="mt-2">Country: </h6>
                <b-form-select v-model="country" size="md" @change="getPartners(country)" style="width: fit-content;">
                    <template #first>
                        <b-form-select-option :value="null" disabled>Countries List</b-form-select-option>
                    </template>
                    <b-form-select-option :value="'United Arab Emirates'">United Arab Emirates</b-form-select-option>
                    <b-form-select-option :value="'Saudi Arabia'">Saudi Arabia</b-form-select-option>
                </b-form-select>
                <h6 class="mt-2 ml-5">Partner: </h6>

                <b-form-select v-model="partner" :options="options" style="width: fit-content;" @change="Set_status()">
                    <template #first>
                        <b-form-select-option :value="null" disabled>Partner List</b-form-select-option>
                    </template>

                    <b-form-select-option v-for="i in options2" :key="i" :value="i.id">
                        {{ i.name }}
                    </b-form-select-option>

                </b-form-select>


            </div> -->
            <div class="d-flex justify-content-center mt-3">
                <b-container class="bv-example-row d-flex">
                    <div class="col-md-12 " v-if="show == 'All'">
                        <b-row class=" justify-content-center" v-if="cate">
                            <b-col v-for="i in categoryList[0]" :key="i.id">
                                <a href="#" @click="category(i.name)">
                                    <img :src="'data:image/png;base64,' + i.image" height="180" />
                                    <div>
                                        <h5 class="mt-2 text-center" style="color: black;" v-if="i.name === 'Mobile'">
                                            <b>{{
                    i.name
                }}s</b>
                                        </h5>
                                        <h5 class="mt-2  text-center" style="color: black;" v-else><b>{{ i.name }}</b>
                                        </h5>
                                    </div>
                                </a>
                            </b-col>

                        </b-row>
                    </div>
                    <div>
                        <b-button v-if="show !== 'All'" class="ml-3 mt-3 mb-3" @click="show = 'All'">Back to
                            Categories</b-button>

                        <div class="col-md-12" v-if="show === 'Mobile' || getFromURL === 'Mobile'">
                            <b-row class=" justify-content-center">
                                <Mobile :partner="partner" :country="country" :mobile_cate_id="categoryList[0][0].id" />

                            </b-row>

                        </div>

                        <div class="col-md-12" v-if="show === 'Laptop' || getFromURL === 'Laptop'">
                            <b-row class=" justify-content-center">
                                <Laptop :partner="partner" :country="country" :laptop_cate_id="categoryList[0][2].id" />

                            </b-row>

                        </div>
                        <div class="col-md-12" v-if="show === 'Tablets' || getFromURL === 'Tablet'">
                            <b-row class=" justify-content-center">
                                <Tablet :partner="partner" :country="country" :tablet_cate_id="categoryList[0][1].id" />

                            </b-row>

                        </div>


                        <div class="col-md-12" v-if="show === 'Smartwatches'">
                            <b-row class=" justify-content-center" v-if="cate">
                                <Smartwatches :partner="partner" :country="country"
                                    :watch_cate_id="categoryList[0][3].id" />

                            </b-row>

                        </div>

                        <div class="col-md-12" v-if="show === 'Headphones & wireless audio'">
                            <b-row class=" justify-content-center" v-if="cate">
                                <Headphones :partner="partner" :country="country"
                                    :headphone_cate_id="categoryList[0][4].id" />

                            </b-row>

                        </div>
                    </div>
                </b-container>
            </div>
        </div>
        <Footer/>
    </div>
</template>
<script>
import api from '../api'
import Header from '../components/Header2.vue'
import Footer from '../components/Footer.vue'
import Mobile from '../components/Mobiles_demo.vue'
import Laptop from '../components/Laptop_demo.vue'
import Tablet from '../components/Tablets_demo.vue'
import Smartwatches from '../components/Smartwatches_demo.vue'
import Headphones from '../components/headphones_demo.vue'
export default {
    name: 'Home',
    data() {
        return {
            show: 'All',
            partner: 4,
            country: 'United Arab Emirates',
            options2: null,
            cate: false
        }
    },
    components: {
        Header,
        Footer,
        Mobile,
        Laptop,
        Tablet,
        Smartwatches,
        Headphones
    },
    computed: {
        categoryList: function () {
            return this.$store.state.categoryList
        },
        userList: function () {
            return this.$store.state.user
        },
    },
    methods: {
        category: function (cate) {
            if (this.country && this.partner) {
                this.show = cate
            } else {
                this.$bvToast.toast('Please Select Country and Partner', {
                    title: 'Somthing went wrong',
                    variant: 'danger',
                    toaster: 'b-toaster-top-right'
                })
            }
        },
        Set_status: function () {
            this.show = 'All'
        },
        getPartners: function (data) {
            const inputs = {

                'country': data

            }
            api.request(process.env.VUE_APP_Get_Partner_List, inputs).then((response) => {
                if (response.data.result) {
                    this.show = 'All'
                    this.options2 = response.data.result
                } else {
                    throw response.data
                }
            }).catch(() => {
                // this.$bvToast.toast('We are not able to process this request right now. Please try again later.', {
                //   title: 'Error',
                //   variant: 'danger',
                //   toaster: 'b-toaster-top-right'
                // })
            })
        },
        get_categories: function () {
            console.log(process.env.VUE_APP_Get_Categories)
            const inputs = {

                'reuse_sell': true,

            }
            api.request(process.env.VUE_APP_Get_Categories, inputs).then((response) => {
                if (response.data.result && typeof response.data.result === 'object') {
                    this.$store.commit('SET_CATEGORY_LIST', response.data.result)
                    this.cate = true
                } else {
                    throw response.data
                }
            }).catch(() => {
                this.$bvToast.toast('We are not able to process this request right now. Please try again later.', {
                    title: 'Error',
                    variant: 'danger',
                    toaster: 'b-toaster-top-right'
                })
            })
        },
    },
    mounted() {
        this.get_categories()
    }
}

</script>