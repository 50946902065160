<template>
  <div class="cont">
    <!-- Web View of content -->
    <div class="  custom-content d-none d-lg-block">
      <h1>{{ con_title }}</h1>
          <div class="text-left" v-html="content"></div>
    </div>
    <!-- Mobile View -->
    <div class=" d-lg-none">


      <div class="px-3 mx-1">
        <h1>{{ con_title }}</h1>
          <div class="text-left" v-html="content"></div>
    </div>
  </div>
</div></template>
<script>
export default {
  name: 'repaircontent',
  props: {
    content: {
      default: ''
    },
    con_title:{
      default: ''
    }
  }
}
</script>
<style>

</style>