import { render, staticRenderFns } from "./Laptop_demo.vue?vue&type=template&id=056dddcc&scoped=true"
import script from "./Laptop_demo.vue?vue&type=script&lang=js"
export * from "./Laptop_demo.vue?vue&type=script&lang=js"
import style0 from "./Laptop_demo.vue?vue&type=style&index=0&id=056dddcc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "056dddcc",
  null
  
)

export default component.exports