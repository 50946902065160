<template>
  <div class="container mt-4">
    <h6 class="text-left mb-2">Register new device! (optional)</h6>
    <b-row v-for="(item, index) in rows" :key="index">
      <h6 class="w-100 ">Device No: {{ index + 1 }}</h6>
      <div class="w-100"></div>
      <b-col cols="6" class="p-1">
        <select class="form-control" v-model="item.category" @change="select_cate(index)">
          <option disabled value="">Select Category</option>
          <option v-for="category in cate[0]" :key="category.id" :value="category.id">{{ category.name }}</option>
        </select>
      </b-col>
      <b-col cols="6" class="p-1">
        <select class="form-control" v-model="item.brand" @change="select_brand(index)">
          <option disabled value="">Select Brand</option>
          <option v-for="brand in brands" :key="brand.id" :value="brand.id">{{ brand.name }}</option>
        </select>
      </b-col>
      <div class="w-100"></div>
      <b-col col="6" class="p-1">
        <select class="form-control" v-model="item.model" @change="handle_record(index)">
          <option disabled value="">Select Model</option>
          <option v-for="model in sku_models" :key="model.value" :value="model.value">{{ model.text }}</option>
        </select>
      </b-col>
      <b-col col="6" class="p-1">
        <input type="text" class="form-control" v-model="item.imei" placeholder="IMEI/Serial No"
          @change="handle_record(index)" />
      </b-col>
      <div class="w-100"></div>
      <b-col col="6" class="p-1 col-3">
        <input type="text"
          onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
          max="999999999" class="form-control" v-model="item.buyingPrice" placeholder="Price"
          :formatter="amountFormatter" @change="handle_record(index)" />
      </b-col>
      <b-col col="6" class="p-1 col-9">
        <input type="text" class="form-control" v-model="item.remarks" placeholder="Remarks"
          @change="handle_record(index)" />
      </b-col>
      <div class="w-100"></div>
      <b-col cols="12 text-right" class="p-1">
        <b-button class="btn btn-danger" size="sm" @click="removeRow(index)" v-if="rows.length > 1">
          <i class="fas fa-trash"></i> Remove Device
        </b-button>
      </b-col>
      <hr class="w-100" />
    </b-row>
    <b-button class="btn btn-success" size="sm" @click="addRow" :disabled="rows.length >= 3">
      <i class="fas fa-plus"></i> Add More Device
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    cate: {},
    brands: {},
    sku_models: {}
  },
  data() {
    return {
      comments: "",
      categories: ["Smartphone", "Tablet", "Laptop"],
      brands2: ["Apple", "Samsung", "Dell"],
      models: ["iPhone 13", "Galaxy S21", "XPS 13"],
      gbs: ["64 GB", "128 GB", "256 GB"],
      rows: [
        {
          category: "",
          brand: "",
          model: "",
          gb: "",
          imei: "",
          buyingPrice: "",
          remarks: ""
        }
      ]
    };
  },
  methods: {
    amountFormatter: function (e) {
      return String(e).substring(0, 4)
    },
    select_cate(index) {
      this.$emit('category', this.rows[index].category);
    },
    select_brand(index) {
      this.$emit('sbrand', this.rows[index]);

    },
    handle_record() {
      this.$emit('devicedata', this.rows);
    },
    addRow() {
      if (this.rows.length < 3) {
        this.rows.push({
          category: "",
          brand: "",
          model: "",
          gb: "",
          imei: "",
          buyingPrice: "",
          remarks: ""
        });
      }
      // this.$emit('devicedata',this.rows);
    },
    removeRow(index) {
      this.rows.splice(index, 1);
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: auto;
}

button i {
  margin-right: 5px;
}

.collapsed>.when-open,
.not-collapsed>.when-closed {
  display: none;
}
</style>
