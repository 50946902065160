export default
[
  {
    id: 1,
    ctg: 'BuyBack',
    name: 'What is Redington Trade-In program?',
    des: 'Redington is the driving force behind the Redington Trade-In Program. We want to help create a more sustainable environment by recycling old devices like phones, laptops, tablets , smartwatches and true wireless. In exchange, we will assess the devices value and provide you with voucher to purchase a new device that will increase your workplace productivity. Its a win-win situation for both you and the environment.'
  },
  {
    id: 2,
    ctg: 'BuyBack',
    name: 'What items can I trade with Redington Trade-In?',
    des: 'Smartphones, Laptops, Tablets, Smartwatches and headphones/Earphones For any of these devices, you can go through the assessment and receive an immediate price quotes based on the device condition'
  },
  {
    id: 3,
    ctg: 'BuyBack',
    name: 'What does the condition of device mean?',
    des: 'There are 3 different grades to describe what condition your device is in. These grades are based on how your device looks and how well it works, the condition of your device impacts the value that will be offered to you. Just select the condition of your phone from the options below when trading it in and we will tell you how much money you can make for it.'
  },
  {
    id: 4,
    ctg: 'BuyBack',
    name: 'Do I need to include accessories when I sell my device?',
    des: 'Not required for Smartphones, Tablets, & Smartwatch. Laptop charger is mandatory.'
  },
  {
    id: 5,
    ctg: 'BuyBack',
    name: 'What will happen to my phone?',
    des: 'We are passionate about protecting the planet, which is why we refurbish or recycle every phone we buy. In most cases, your phone will be refurbished by our expert technicians and resold to a new home. If your phone is broken, well recycle it responsibly and use the parts to help give other phones a second life. Either way, selling your phone with Redington Trade-In helps to reduce e-waste - its smart for you, smart for the planet!'
  },
  {
    id: 6,
    ctg: 'BuyBack',
    name: 'Can I sell my broken phone?',
    des: "Yes, you can sell your broken phone- and its easy too! If your phone has cosmetic and water damage, just select condition 3 when getting your valuation. Please note: we can't buy phones that have been snapped in half, bent or crushed, or phones with missing or broken components."
  },
  {
    id: 7,
    ctg: 'BuyBack',
    name: 'Do I have to provide proof of purchase for the device I trade in?',
    des: 'No, there is no proof of purchase required to trade in a device.'
  },
]
