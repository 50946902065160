<template>
  <div>
    <div>
      <b-modal id="modal-center" hide-footer title="" centered header-class="border-0 p-0" hide-header-close='true'
        size="lg">
        <div>
          <img src="../assets/trade-in--en.png" width="766px" />
        </div>
      </b-modal>
    </div>
    <b-modal hide-header-close='true' no-close-on-backdrop='false' @hide="hideSignIn()" id="modalTerms" centered
      size="sm" title="" footer-class="border-0 mx-auto" header-class="border-0">
      <template #modal-header="{ }">
      </template>
      <div class="scroll text-center" style="height: 400px;">

        <div class=" ex1  justify-content-center" style="height: 420px;">
          <img src="../assets/agree/001.jpg" width="250px" />
          <img src="../assets/agree/002.jpg" width="250px" />
          <img src="../assets/agree/003.jpg" width="250px" />
          <img src="../assets/agree/004.jpg" width="250px" />
          <img src="../assets/agree/005.jpg" width="250px" />
          <img src="../assets/agree/006.jpg" width="250px" />
          <img src="../assets/agree/007.jpg" width="250px" />
          <img src="../assets/agree/008.jpg" width="250px" />
          <img src="../assets/agree/011.png" width="250px" />
          <img src="../assets/agree/011.png" class="mt-1" width="250px" />
        </div>

        <!-- </pre> -->



      </div>
      <template #modal-footer="{ }">
        <b-button class="mt-3" squared variant="success" @click="Accept()" style="font-size: large;">Agree</b-button>
      </template>
    </b-modal>


    <div v-responsive.lg.xl>
      <Header2 v-if="this.$store.state.user" />

      <div>
        <div class="">
          <div class="">
            <b-carousel controls class="" id="carousel-1" v-model="slide" :interval="3000" indicators
              background="#ababab" img-width="1024" img-height="480" style="text-shadow: 1px 1px 2px #333;"
              @sliding-start="onSlideStart" @sliding-end="onSlideEnd">


              <div v-if="User_id" v-for="i in banners" :key="i">

                <b-carousel-slide :img-src="'data:image/png;base64,' + i.image"></b-carousel-slide>
              </div>
              <div v-else>
                <b-carousel-slide>
                  <template #img>
                    <a href="" @click="cateclick()">
                      <img width="100%" height="" src="../assets/home/Redington HomePage Desktop Image 1.jpg"
                        alt="image slot">
                    </a>
                  </template>
                </b-carousel-slide>
              </div>

            </b-carousel>
          </div>
        </div>
        <div class="abb" style="background-color: #CECECE91;width: 600px;height: 480px;" v-if="!User_id">
          <div class="abb1 text-left ml-4">
            <h1 style="font-size: 55px;font-weight: 700;">
              Exchange for Change
            </h1>
            <h1 style="font-size: 35px;font-weight: 600;color: #000000A6;">
              Exchange, Upgrade & Save
            </h1>
            <h1 style="font-size: 20px;font-weight: 500;color: #000000A6;" class="mt-3">
              Unlock Double Benefits: Redington Offers<br />
              Best Trade-in Deals for Your Next Device!
            </h1>
            <b-button style="background-color: #016938;color: white;" variant="link" class="mt-4  py-2 px-4">Trade-in
              Your
              Device</b-button>

          </div>
        </div>
        <Header v-if="!this.$store.state.user" />
      </div>


      <div class="home  p-5">
        <div class="container-fluid ">
          <p style="font-size: 55px;">
            <b>What would you like to <b style="color: #37a531;">trade</b>?</b>
          </p>
          <h5 style="color: black;font-size: 30px;font-weight: bolder;"><b>Let’s get started</b></h5>

          <div>
            <div class="d-flex justify-content-center mt-5">
              <b-container class="bv-example-row d-flex">
                <div class="col-md-12 ">
                  <b-row class="mt-3 justify-content-center" v-if="cate">
                    <b-col v-for="i in categoryList[0]" :key="i.id">
                      <a href="#" @click="cateclick(i.id, i.name)">
                        <img :src="'data:image/png;base64,' + i.image" height="180" />
                        <div>
                          <h5 class="mt-2" style="color: black;" v-if="i.name === 'Mobile'"><b>{{ i.name }}s</b></h5>
                          <h5 class="mt-2" style="color: black;" v-else><b>{{ i.name }}</b></h5>
                        </div>
                      </a>
                    </b-col>

                  </b-row>
                </div>
              </b-container>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <div class="d-flex ">
        <div class="container">
          <img src="../assets/home/Redington HomePage Desktop Image 2.jpg" width="650px" />
        </div>

        <div style="width: 700px ;">
          <div class="abb2" style="background-color: #FFFFFF; height: 500px; width: 600px ; border-radius:20px ;">
            <div class="d-flex justify-content-center mt-5">
              <div class="mt-5 text-left ml-5">
                <h1 style="font-size: 50px;font-weight: 800;">Make a difference</h1>
                <h1 style="font-size: 20px;font-weight: 500;color: #00000099;" class="mt-5">Trade-in Your Old
                  Device<br />and Create an Impact</h1>
                <b-button style="background-color: #016938;color: white;" variant="link" class="mt-4  py-2 px-3"
                  >Trade-in
                  Your Device</b-button>
              </div>

              <b-modal id="bv-modal-example" size="xl" hide-footer title="Price Calculator">
                <div class=" ml-5">
                  <h4>Select the country and Partner </h4>
                </div>
                <div class="d-flex justify-content-left ml-5">
                  <h6 class="mt-2">Country: </h6>
                  <b-form-select v-model="country" size="md" @change="getPartners(country)" style="width: fit-content;">
                    <template #first>
                      <b-form-select-option :value="null" disabled>Countries List</b-form-select-option>
                    </template>
                    <b-form-select-option :value="'United Arab Emirates'">United Arab Emirates</b-form-select-option>
                    <b-form-select-option :value="'Saudi Arabia'">Saudi Arabia</b-form-select-option>
                  </b-form-select>
                  <h6 class="mt-2 ml-5">Partner: </h6>

                  <b-form-select v-model="partner" :options="options" style="width: fit-content;"
                    @change="Set_status()">
                    <template #first>
                      <b-form-select-option :value="null" disabled>Partner List</b-form-select-option>
                    </template>

                    <b-form-select-option v-for="i in options2" :key="i" :value="i.id">
                      {{ i.name }}
                    </b-form-select-option>

                  </b-form-select>


                </div>
                <div class="d-flex justify-content-center " v-if="country && partner">
                  <b-container class="bv-example-row d-flex">
                    <div class="col-md-12 " v-if="show === 'All'">
                      <b-row class=" justify-content-center" v-if="cate">
                        <b-col v-for="i in categoryList[0]" :key="i.id">
                          <a href="#" @click="show = i.name">
                            <img :src="'data:image/png;base64,' + i.image" height="180" />
                            <div>
                              <h5 class="mt-2 text-center" style="color: black;" v-if="i.name === 'Mobile'"><b>{{ i.name
                                  }}s</b></h5>
                              <h5 class="mt-2  text-center" style="color: black;" v-else><b>{{ i.name }}</b></h5>
                            </div>
                          </a>
                        </b-col>

                      </b-row>
                    </div>
                    <div>
                      <b-button v-if="show !== 'All'" class="ml-3 mt-3 mb-1" @click="show = 'All'">Back to
                        Categories</b-button>

                      <div class="col-md-12" v-if="show === 'Mobile' || getFromURL === 'Mobile'">
                        <b-row class=" justify-content-center">
                          <Mobile :partner="partner" :country="country" :mobile_cate_id="categoryList[0][0].id " />

                        </b-row>

                      </div>

                      <div class="col-md-12" v-if="show === 'Laptop' || getFromURL === 'Laptop'">
                        <b-row class=" justify-content-center">
                          <Laptop :partner="partner" :country="country" :laptop_cate_id="categoryList[0][2].id"/>

                        </b-row>

                      </div>
                      <div class="col-md-12" v-if="show === 'Tablets' || getFromURL === 'Tablet'">
                        <b-row class=" justify-content-center">
                          <Tablet :partner="partner" :country="country" :tablet_cate_id="categoryList[0][1].id" />

                        </b-row>

                      </div>


                      <div class="col-md-12" v-if="show === 'Smartwatches'">
                        <b-row class=" justify-content-center" v-if="cate">
                          <Smartwatches :partner="partner" :country="country" :watch_cate_id="categoryList[0][3].id" />

                        </b-row>

                      </div>

                      <div class="col-md-12" v-if="show === 'Headphones & wireless audio'">
                        <b-row class=" justify-content-center" v-if="cate">
                          <Headphones :partner="partner" :country="country" :headphone_cate_id="categoryList[0][4].id"/>

                        </b-row>

                      </div>
                    </div>
                  </b-container>
                </div>
              </b-modal>
              <div class="mt-3 text-left ">
                <h1 style="font-size: 15px;font-weight: 500;">Join us in a greener future -<br />recycle your old
                  devices
                  for a sustainable tomorrow!</h1>
                <img class="mt-5 ml-5" src="../assets/home/Redington - Instant Cash Voucher.png" width="130px" /><br />
                <img class="mt-5 ml-5" src="../assets/home/Redington - Best prices, guaranteed.png"
                  width="130px" /><br />
                <img class="mt-5 ml-5" src="../assets/home/Redington - Certified Data Wipe.png" width="130px" /><br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Tablet View -->

    <div v-responsive.md>
      <Header2 v-if="this.$store.state.user" />

      <div>
        <div class="">
          <div class="">
            <b-carousel class="" id="carousel-1" v-model="slide" :interval="3000" indicators background="#ababab"
              img-width="1024" img-height="480" style="text-shadow: 1px 1px 2px #333;" @sliding-start="onSlideStart"
              @sliding-end="onSlideEnd">


              <div v-if="User_id" v-for="i in banners" :key="i">
                <a :href="i.product_url">
                  <b-carousel-slide :img-src="'data:image/png;base64,' + i.image"></b-carousel-slide></a>
              </div>
              <div v-else>
                <b-carousel-slide>
                  <template #img>
                    <a href="" @click="cateclick()">
                      <img width="100%" height="" src="../assets/home/Redington HomePage Desktop Image 1.jpg"
                        alt="image slot">
                    </a>
                  </template>
                </b-carousel-slide>
              </div>

            </b-carousel>
          </div>
        </div>
        <div class="abb" style="background-color: #CECECE91;width: 400px;height: 320px;" v-if="!User_id">
          <div class="abb1 text-left ml-4">
            <h1 style="font-size: 25px;font-weight: 700;">
              Exchange for Change
            </h1>
            <h1 style="font-size: 18px;font-weight: 600;color: #000000A6;">
              Exchange, Upgrade & Save
            </h1>
            <h1 style="font-size: 15px;font-weight: 500;color: #000000A6;" class="mt-3">
              Unlock Double Benefits: Redington Offers<br />
              Best Trade-in Deals for Your Next Device!
            </h1>
            <b-button style="background-color: #016938;color: white; font-size: medium;" variant="link"
              class="mt-4  py-2 px-3">Trade-in Your
              Device</b-button>

          </div>
        </div>
        <Header v-if="!this.$store.state.user" />
      </div>


      <div class="home  p-5">
        <div class="container-fluid ">
          <p style="font-size: 40px;">
            <b>What would you like to <b style="color: #37a531;">trade</b>?</b>
          </p>
          <h5 style="color: black;font-size: 25px;font-weight: bolder;"><b>Let’s get started</b></h5>

          <div>
            <div class="d-flex justify-content-center mt-5">
              <b-container class="bv-example-row d-flex">
                <div class="col-md-12 ">
                  <b-row class="mt-3 justify-content-center" v-if="cate">
                    <b-col v-for="i in categoryList[0]" :key="i.id">
                      <a href="#" @click="cateclick(i.id, i.name)">
                        <img :src="'data:image/png;base64,' + i.image" height="10" />
                        <div>
                          <h5 class="mt-2" style="color: black;" v-if="i.name === 'Mobile'"><b>{{ i.name }}s</b></h5>
                          <h5 class="mt-2" style="color: black;" v-else><b>{{ i.name }}</b></h5>
                        </div>
                      </a>
                    </b-col>

                  </b-row>
                </div>
              </b-container>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <div class="">
        <div class="container">
        </div>
        <div class="">
          <img src="../assets/home/smartphone-repairs-1080x700 2(1).png" width="100%" class="p-2" />

          <div class="d-flex justify-content-center mt-3">
            <div class=" text-left">
              <h1 style="font-size: 35px;font-weight: 800;">Make a difference</h1>
              <h1 style="font-size: 25px;font-weight: 500;color: #00000099;" class="mt-1">Trade-in Your Old
                Device<br />and Create an Impact</h1>
              <b-button style="background-color: #016938;font-size: 20px;color: white;" variant="link"
                class="mt-2 btn2 py-2 px-3">Trade-in Your Device</b-button>
              <h1 style="font-size: 25px;font-weight: 500;" class="mt-3">Join us in a greener future -<br />recycle your
                old devices for a sustainable tomorrow!</h1>

            </div>

          </div>
        </div>
        <div>
          <div>
            <img class="mt-5" src="../assets/home/mobile/Instant Cash Step 1.svg" width="250px" /><br />
            <img class="mt-5 " src="../assets/home/mobile/Best prices Step 2.svg" width="250px"><br />
            <img class="mt-5 " src="../assets/home/mobile/Certified Data Wipe 3.svg" width="250px" /><br />
          </div>
        </div>
      </div>
    </div>


    <!-- Mobile View -->

    <div v-responsive.sm.xs>
      <Header2 v-if="this.$store.state.user" />

      <div>
        <div class="">
          <div class="">
            <b-carousel class="" id="carousel-1" v-model="slide" :interval="3000" indicators background="#ababab"
              img-width="1024" img-height="480" style="text-shadow: 1px 1px 2px #333;" @sliding-start="onSlideStart"
              @sliding-end="onSlideEnd">


              <div v-if="User_id" v-for="i in banners" :key="i">
                <a :href="i.product_url">
                  <b-carousel-slide :img-src="'data:image/png;base64,' + i.image"></b-carousel-slide></a>
              </div>
              <div v-else>
                <b-carousel-slide>
                  <template #img>
                    <a href="" @click="cateclick()">
                      <img width="100%" height="" src="../assets/home/mobile/image 1.png" alt="image slot">
                    </a>
                  </template>
                </b-carousel-slide>
              </div>

            </b-carousel>
          </div>
        </div>
        <Header v-if="!this.$store.state.user" />

        <div class="abb3" style="background-color: #CECECE91;width: 220px;height: 250px;" v-if="!User_id">
          <div class="abb1 text-left ml-4">
            <h1 style="font-size: 18px;font-weight: 700;">
              Exchange for Change
            </h1>
            <h1 style="font-size: 12px;font-weight: 600;color: #000000A6;">
              Exchange, Upgrade & Save
            </h1>
            <h1 style="font-size: 10px;font-weight: 500;color: #000000A6;width: 150px;" class="mt-3">
              Unlock Double Benefits: Redington Offers
              Best Trade-in Deals for Your Next Device!
            </h1>
            <b-button style="background-color: #016938;color: white;" variant="link"
              class="mt-2 btn2 py-1 px-2">Trade-in
              Your Device</b-button>

          </div>
        </div>
        <!-- <Header /> -->

      </div>


      <div class="home  p-3 mt-3">
        <div class="container-fluid ">
          <p style="font-size: 20px;">
            <b>What would you like to <b style="color: #37a531;">trade</b>?</b>
          </p>
          <h5 style="color: black;font-size: 15px;font-weight: bolder;"><b>Let’s get started</b></h5>

          <div>
            <div class="d-flex justify-content-center mt-4">
              <b-container class="bv-example-row d-flex">
                <div class="col-md-12 ">
                  <b-row class=" justify-content-center" v-if="cate">
                    <b-col v-for="i in categoryList[0]" :key="i.id">
                      <a href="#" @click="cateclick(i.id, i.name)">
                        <img :src="'data:image/png;base64,' + i.image" height="80" />
                        <div>
                          <h5 class="mt-2" style="color: black;"><b>{{ i.name }}</b></h5>
                        </div>
                      </a>
                    </b-col>

                  </b-row>
                </div>
              </b-container>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <div class="">
        <div class="">
          <img src="../assets/home/smartphone-repairs-1080x700 2(1).png" width="100%" class="p-2" />

          <div class="d-flex justify-content-left mt-3 ml-4">
            <div class=" text-left">
              <h1 style="font-size: 25px;font-weight: 800;">Make a difference</h1>
              <h1 style="font-size: 15px;font-weight: 500;color: #00000099;" class="mt-1">Trade-in Your Old
                Device<br />and Create an Impact</h1>
              <b-button style="background-color: #016938;color: white;" variant="link"
                class="mt-2 btn2 py-1 px-2">Trade-in
                Your Device</b-button>
              <h1 style="font-size: 15px;font-weight: 500;" class="mt-3">Join us in a greener future -<br />recycle your
                old devices for a sustainable tomorrow!</h1>

            </div>

          </div>
        </div>
        <div>
          <div>
            <img class="mt-5" src="../assets/home/mobile/Instant Cash Step 1.svg" width="150px" /><br />
            <img class="mt-5 " src="../assets/home/mobile/Best prices Step 2.svg" width="150px"><br />
            <img class="mt-5 " src="../assets/home/mobile/Certified Data Wipe 3.svg" width="150px" /><br />
          </div>
        </div>
      </div>
      <!-- <div class="">
            <div class="">
                <div class="">
                <b-carousel
                class=""
                id="carousel-1"
                v-model="slide"
                :interval="3000"
                indicators
                background="#ababab"
                img-width="1024"
                img-height="480"
                style="text-shadow: 1px 1px 2px #333;"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd">
                <div v-if="User_id"  v-for="i in banners3" :key="i">
                    <a :href="i.product_url">
                    <b-carousel-slide
                    :img-src="'data:image/png;base64,'+i.image"
                ></b-carousel-slide></a>
                </div>
                <b-carousel-slide v-else>
                <template #img>
                  <a href="" @click="cateclick()">
                    <img
                    width="100%"
                    height="560"
                    src="../assets/Buyback_content/mobile/Redington corporate design banner - mobile.jpg"
                    alt="image slot"
                  >
                  </a>
                  
                </template>
              </b-carousel-slide>
                
              </b-carousel>
         </div>
        </div>

        </div> -->


      <!-- <div class=" mt-5 p-5" style="background-color: #F2F2F2;">
          <div class=" mt-5" >
            <h5  class="mt-5 "><b>What would you like to <b style="color: #37a531;">Trade</b>?</b></h5>
            <p >Let’s get started</p>
          <div >
            <div class="d-flex justify-content-center mt-5">
            <b-container class="bv-example-row d-flex" >
              <div  class="  ">
              <b-row class="mt-3 justify-content-center" v-if="cate" >
                <b-col v-for = "i in categoryList[0]" :key="i.id" > 
                  <a href="#" @click="cateclick(i.id,i.name)" >
                    <img :src="'data:image/png;base64,' + i.image" height="100" />
                    <div >
                    <h6 class="mt-2" style="color: black;">{{ i.name }}</h6>
                  </div>
                  </a>
                </b-col>
                
              </b-row>
              </div>
              </b-container>
              </div>
          </div>
          </div>
          </div> -->
      <!-- <div class="home mt-5 ">
          <div class="container-fluid mt-5" >
            <div class="mt-5">
            <div class="">
                <div class="">

                <b-carousel
                class=""
                id="carousel-1"
                v-model="slide"
                :interval="3000"
                controls
                indicators
                background="#ababab"
                img-width="1024"
                img-height="480"
                style="text-shadow: 1px 1px 2px #333;"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd">
                <div v-if="User_id"  v-for="i in banners4" :key="i">
                    <a :href="i.product_url">
                    <b-carousel-slide
                    :img-src="'data:image/png;base64,'+i.image"
                ></b-carousel-slide></a>
                </div>
                <b-carousel-slide v-else>
                <template #img>
                  <a href="" @click="cateclick()">
                    <img
                    width="100%"
                    height="560"
                    src="../assets/Buyback_content/Redington Mobile 3.jpg"
                    alt="image slot"
                  >
                  </a>
                  
                </template>
              </b-carousel-slide>
                </b-carousel>
         </div>
        </div>
        </div>
          </div>
          </div> -->
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from '../components/Header.vue'
import Header2 from '../components/Header3.vue'
import Footer from '../components/Footer.vue'
import Mobile from '../components/Mobiles_demo.vue'
import Laptop from '../components/Laptop_demo.vue'
import Tablet from '../components/Tablets_demo.vue'
import Smartwatches from '../components/Smartwatches_demo.vue'
import Headphones from '../components/headphones_demo.vue'
import api from '../api'
export default {
  name: 'Home',
  data() {
    return {
      id: this.$route.params.id,
      items: [...Array(20).keys()].map((i) => {
        return { i, title: `Responsive`, content: `Content` };
      }),
      meta: [
        {}
      ],
      reviews: null,
      rev: false,
      cate: false,
      cate1: [{
        'id': '',
        'name': '',
        'img': ''
      }],
      cate2: [{
        'name': '',
        'img': ''
      }],
      cate3: [{
        'name': '',
        'img': ''
      }],
      banners: [{
        'product_url': '',
        'image': ''
      }],
      banners2: [{
        'product_url': '',
        'image': ''
      }],
      banners3: [{
        'product_url': '',
        'image': ''
      }],
      banners4: [{
        'product_url': '',
        'image': ''
      }],
      buy_it: [{
        'product_url': '',
        'image': ''
      }],
      timestamp: '',
      show: 'All',
      partner: null,
      country: null,
      options2: null

    }
  },
  components: {
    Header,
    Footer,
    Header2,
    Mobile,
    Laptop,
    Tablet,
    Smartwatches,
    Headphones
  },
  metaInfo() {
    const cTitile = this.webpage_title
    const cMeta = this.meta
    return {
      title: 'Redington Trade-In',
      meta: cMeta,
      titleTemplate: function (title) {
        return `${title}`
      }
    }
  },
  computed: {
    categoryList: function () {
      return this.$store.state.categoryList
    },
    dataUrl(value) {
      return 'data:image/png;base64,' + value
    },
    User_id() {
      return this.$store.state.user
    }
  },
  methods: {
    get_banners: function () {

      var token_data = JSON.parse(localStorage.getItem('token_data'));

      const inputs = {

        'id': this.$store.state.user.uid,
        'user_token': this.$store.state.user.user_token,
        'token_data':this.$store.state.user.token_data

      }
      api.request(process.env.VUE_APP_Get_Banners, inputs).then((response) => {
        if (response.data.result && typeof response.data.result === 'object') {
          this.banners = response.data.result
        } else {
          throw response.data
        }
      }).catch(() => {
        // this.$bvToast.toast('We are not able to process this request right now. Please try again later.', {
        //   title: 'Error',
        //   variant: 'danger',
        //   toaster: 'b-toaster-top-right'
        // })
      })
    },
    cateclick: function (cate_id, name) {
      console.log(cate_id)
      console.log(name)
     
      if (name.includes('Mobile')) {
        
        this.$router.push({ path: `mobiles-${cate_id}`,query: { id: this.id }  })
      } else if (name.includes('Headphone')) {
        this.$router.push({ path: `headphones-${cate_id}`,query: { id: this.id } })
      } else if (name.includes('Tablets')) {
        this.$router.push({ path: `tablets-${cate_id}`,query: { id: this.id } })
      } else if (name.includes('Laptop')) {
        this.$router.push({ path: `laptop-${cate_id}`,query: { id: this.id }  })
      } else if (name.includes('Smartwatches')) {
        this.$router.push({ path: `smartwatches-${cate_id}`,query: { id: this.id } })
      }


    },
    get_categories: function () {
      console.log(process.env.VUE_APP_Get_Categories)
      const inputs = {

        'reuse_sell': true,

      }
      api.request(process.env.VUE_APP_Get_Categories, inputs).then((response) => {
        if (response.data.result && typeof response.data.result === 'object') {
          this.$store.commit('SET_CATEGORY_LIST', response.data.result)
          this.cate = true
        } else {
          throw response.data
        }
      }).catch(() => {
        this.$bvToast.toast('We are not able to process this request right now. Please try again later.', {
          title: 'Error',
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        })
      })
    },
    Accept: function () {
      const inputs = {

        'id': this.$store.state.user.uid

      }
      api.request(process.env.VUE_APP_Set_Terms, inputs).then((response) => {
        if (response.data.result) {
          this.$router.push({ name: 'LandingPage' })
          this.$store.commit('SET_USER', null)
        } else {
          throw response.data
        }
      }).catch(() => {
        // this.$bvToast.toast('We are not able to process this request right now. Please try again later.', {
        //   title: 'Error',
        //   variant: 'danger',
        //   toaster: 'b-toaster-top-right'
        // })
      })
    },

    setActive: function (item) {
      if (item === 'repair') {
        this.$router.push({ name: 'RepairGadget' })
      } else if (item === 'buy') {
        this.$router.push({ name: 'ReuseBuy' })
      } else if (item === 'sell') {
        this.$router.push({ name: 'ReuseSell' })
      } else if (item === 'laptop') {
        this.$router.push({ path: '/reuse/buy-used-gaming-gadgets-laptops-online-used-mobile-purchase-in-dubai?Category=buy-used-laptops-in-dubai-with-warranty' })
      } else if (item === 'phone') {
        this.$router.push({ path: '/reuse/buy-used-gaming-gadgets-laptops-online-used-mobile-purchase-in-dubai?Category=buy-used-smartphones-mobile-online-dubai' })
      }
    },
    Set_status: function () {
      this.show = 'All'
    },
    getPartners: function (data) {
      const inputs = {

        'country': data

      }
      api.request(process.env.VUE_APP_Get_Partner_List, inputs).then((response) => {
        if (response.data.result) {
          this.show = 'All'
          this.options2 = response.data.result
        } else {
          throw response.data
        }
      }).catch(() => {
        // this.$bvToast.toast('We are not able to process this request right now. Please try again later.', {
        //   title: 'Error',
        //   variant: 'danger',
        //   toaster: 'b-toaster-top-right'
        // })
      })
    },

    setActive: function (item) {
      if (item === 'repair') {
        this.$router.push({ name: 'RepairGadget' })
      } else if (item === 'buy') {
        this.$router.push({ name: 'ReuseBuy' })
      } else if (item === 'sell') {
        this.$router.push({ name: 'ReuseSell' })
      } else if (item === 'laptop') {
        this.$router.push({ path: '/reuse/buy-used-gaming-gadgets-laptops-online-used-mobile-purchase-in-dubai?Category=buy-used-laptops-in-dubai-with-warranty' })
      } else if (item === 'phone') {
        this.$router.push({ path: '/reuse/buy-used-gaming-gadgets-laptops-online-used-mobile-purchase-in-dubai?Category=buy-used-smartphones-mobile-online-dubai' })
      }
    },
    getNow: function () {
      const today = new Date();
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + ' ' + time;
      this.timestamp = dateTime;
    }
  },
  mounted() {
    if (this.$store.state.user) {
      if (this.$store.state.user.location === 'Oman' || this.$store.state.user.location === 'Bahrain') {
        this.$router.push({ name: 'ABB' })
      }
    }
    
    // this.getNow()
    this.get_categories()
    if (this.$store.state.user) {
      // if (this.$store.state.user.location !== 'Saudi Arabia' && this.$store.state.user.location !== 'Qatar') { 
      this.get_banners()
       if (this.$store.state.user.terms !== true && this.$store.state.user.location === 'Saudi Arabia') {
         this.$bvModal.show('modalTerms')
      }
      // this.get_banners2()
      // this.get_banners3()
      // this.get_banners4()
      // } else {
      //   if (this.$store.state.user.location === 'Qatar') {
      //     this.get_banners()
      //     this.get_banners2()
      //     this.get_banners3()
      //     this.get_banners4()
      //   }
      //   if (this.$store.state.user.terms === true) {
      //     this.get_banners()
      //     this.get_banners2()
      //     this.get_banners3()
      //     this.get_banners4()
      //   } else {
      //     if (this.$store.state.user.location !== 'Qatar') {
      //       this.$bvModal.show('modalTerms')
      //     }
      //   }
      // }

    }
  }
}
</script>
<style scoped>
.text-color {
  color: #39A536;
}

.btn {
  font-size: larger;
  padding: 10px 20px 10px 20px;
  font-weight: 800;
  color: aliceblue;
  background: #39A536;
  border-radius: 7px;
}

.btn2 {
  font-size: small;
  padding: 10px 20px 10px 20px;
  font-weight: 800;
  color: aliceblue;
  background: #39A536;
  border-radius: 5px;
}

.bb {
  padding: 5px;
  background-color: #c9ddc8;
  border-radius: 25px;
}

.bgr {
  width: 1000px;
  background: #FFFFFF;
  border-radius: 10px;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.bgr2 {
  background: #FFFFFF;
  border-radius: 10px;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.comment {
  background: #FFFFFF;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

}

.comment2 {
  width: 100;
  height: 100;
  background: #FFFFFF;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

}

.delivery-service-item {
  cursor: pointer;
  transition: transform .2s;
  width: fit-content;

}

.delivery-service-item.active {
  box-shadow: 0 5px 10px rgb(0 0 0 / 0.5);
  border: 5px solid rgb(13, 176, 43, 0.6);
}

.delivery-service-item:hover {
  transform: scale(1.2);
  z-index: 1;
  background-color: transparent;

}

.delivery-service-item .img-top {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}

.delivery-service-item:hover .img-top {
  display: inline;
}

.grade-image-block {
  position: relative;
}

.grade-image-block img {
  display: block;
  justify-self: center;
}

.grade-image-block .check-icon-ref {
  position: absolute;
  color: rgb(13, 176, 43, 0.6);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 667px) {
  .delivery-service-item:hover {
    transform: scale(1);
  }
}

.bgg {
  background: rgba(231, 229, 227, 0.22);
  border-radius: 25px;
  width: 617px;
}

a:hover {
  text-decoration: none;
}

.btnn {
  background-color: transparent;
  border: none;
  text-align: left;
  color: #000000;
}

.checked {
  color: orange;
}

div.scrollmenu {
  background-color: white;
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

div.scrollmenu a:hover {
  background-color: #ffffff;
}

/* Red border */
hr.new1 {
  border-top: 1px solid rgb(13, 176, 43, 0.6);
  margin-left: 50px;
  margin-right: 50px;
}

div.ex1 {
  background-color: white;
  height: 350px;
  width: fit-content;
  overflow-y: scroll;
}

div.ex12 {
  background-color: white;
  height: 200px;
  width: fit-content;
  overflow-y: scroll;
}

.g-signin-button {
  /* This is where you control how the button looks. Be creative! */
  display: inline-block;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: #3c82f7;
  color: #fff;
  box-shadow: 0 3px 0 #0f69ff;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.vl {
  border-left: 1px solid green;
}

.abb {
  position: absolute;
  left: 10%;
  width: 100%;
  top: 0%;
}

.abb3 {
  position: absolute;
  left: 5%;
  width: 100%;
  top: 0%;
}

.abb1 {
  position: absolute;
  top: 30%;
  width: 100%;
  text-align: center;
  font-size: 18px;
}

.abb2 {
  position: absolute;
  left: 45%;
  bottom: 16%;
  width: 100%;
}

div.ex1 {
  background-color: transparent;
  width: fit-content;
  height: 110px;
  overflow: scroll;
}
</style>
