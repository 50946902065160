<template>
  <div>
    <div class="">
      <Header />
    </div>
    <div class="mt-5 pb-2" v-responsive.lg.xl>

      <h3 v-if="!brand_active">اختر العلامة التجارية</h3>
      <h3 v-if="model_selected">مسلسل</h3>
      <h3 v-if="pro_selected">اختر الموديل</h3>

      <h3 v-if="repair">اختر روم</h3>
      <h3 v-if="grade_selected">اختر الشرط</h3>
      <div class="d-flex justify-content-center mt-5">
        <b-container class="bv-example-row d-flex">

          <div class="info-box  justify-content-center  border border-secondary mb-2 col-md-10 ml-3 scroll"
            style="max-height: 450px;min-height: 450px;">
            <img src="../assets/Buyback_content/2 - Choose Brands & Product.svg" class="col-md-12" />
            <b-row class="d-flex justify-content-center notranslate">
              <b-col v-for="i in brandList" :key="i.id" class="mt-2" v-if="brand_selected">
                <a href="#">
                  <div class="info-box border border-secondary mb-2"
                    style="max-height: 150px;max-width: 150px;min-height: 150px;min-width: 150px"
                    @click="brand(i.id, i.name)">
                    <img :src="'data:image/png;base64,' + i.image" height="120" />
                    <br />
                    {{ i.name }}
                  </div>
                </a>
              </b-col>
            </b-row>

            <b-row class="d-flex justify-content-center notranslate" v-if="model_selected">
              <b-col v-if="!modelList2.empty && !modelList2.error" v-for="i in modelList2" :key="i.id" class="mt-2">
                <a href="#">
                  <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center"
                    style="min-width: 180px" @click="model_new(i.id, i.name)">
                    <p class="txt2">{{ i.name }} Series</p>
                  </div>
                </a>
              </b-col>
              <div v-else>
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <span class="mb-2">No Data Found</span>
                  <b-button class="explore_btn mb-2" @click="reloadPage">Explore More Brands</b-button>
                </div>
              </div>
            </b-row>
            <b-row class="d-flex justify-content-center notranslate" v-if="pro_selected">
              <b-col v-if="!modelPro.empty && !modelPro.error" v-for="i in modelPro" :key="i.id" class="mt-2">
                <a href="#">
                  <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center"
                    style="min-width: 180px" @click="pro_new(i.id, i.name)">
                    <p class="txt2">{{ i.name }}</p>
                  </div>
                </a>
              </b-col>
              <div v-else>
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <span class="mb-2">No Data Found</span>
                  <b-button class="explore_btn mb-2" @click="reloadPage">Explore More Brands</b-button>
                </div>
              </div>
            </b-row>
            <b-row class="d-flex justify-content-center notranslate" v-if="repair">
              <b-col v-for="i in priceList" :key="i.id" class="mt-2" v-if="i.name !== 'null'">
                <a href="#">
                  <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center"
                    style="min-width: 180px" @click="grades(i.id, i.name)">
                    <p class="txt2">{{ i.name }}</p>
                  </div>
                </a>
              </b-col>
            </b-row>
            <b-row class=" d-flex justify-content-center " v-if="grade_selected">
              <b-col v-for="i in gradeList" :key="i.id" class="">
                <b-row style="flex-wrap: inherit !important;">
                  <b-col>
                    <div class="info-box3 txt border border-secondary"
                      style="max-height: 300px;max-width: 250px;min-height: 300px;min-width: 250px" v-if="i.c"
                      @click="checkout_grade('C', i.c, i.c1, i.c_id)">
                      <h4 class="mt-3">حالة 1</h4>
                      <h6>{{ i.c }} </h6>
                      <ul class="text-right mt-2 ml-4 mr-2" style="padding:0% ;  list-style-type: none;">
                        <li>
                          الخطوط او النقاط في الشاشة او تغير في اللوان الشاشة او حرق في الشاشة او اللمس لا يعمل
                        </li>
                        <li>
                          جهاز متصدع
                        </li>
                        <li>
                          صحة البطارية: مؤشر الخدمة
                        </li>
                        <li>
                          أي مما يلي لا يعمل: البصمة او بصمة الوجه او الكاميرا
                        </li>
                        <li>
                          قفل الجهاز/قفل الشريحة
                        </li>
                        <li>
                          الأجزاء المفقودة (الغطاء الخلفي ، اطار الشريحة أو أزرار)
                        </li>
                      </ul>
                    </div>
                  </b-col>

                  <b-col>
                    <div class="info-box3 txt border border-secondary"
                      style="max-height: 300px;max-width: 250px;min-height: 300px;min-width: 250px" v-if="i.b"
                      @click="checkout_grade('B', i.b, i.b1, i.b_id)">
                      <h4 class="mt-3">حالة 2</h4>
                      <h6>{{ i.b }} </h6>
                      <ul class="text-right mt-3 mr-2  ml-4" style="padding:0% ;  list-style-type: none;">
                        <li>
                          خدوش مرئية على الشاشة ، مفتوح أو مبدل
                        </li>
                        <li>
                          خدوش كبيرة / خدوش على الغطاء الخلفي والجوانب.
                        </li>
                        <li>
                          صحة البطارية 80 ٪ أو أعلى
                        </li>
                        <li>
                          مشاكل في المايكروفون او السماعات او الازرار
                        </li>
                        <li>
                          لا يوجد قفل على الجهاز/الشريحة
                        </li>
                        <li>
                          ا توجد أجزاء مفقودة (الغطاء الخلفي ، اطار الشريحة ، أزرار)
                        </li>
                      </ul>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="info-box3 txt border border-secondary "
                      style="max-height: 300px;max-width: 250px;min-height: 300px;min-width: 250px" v-if="i.a"
                      @click="checkout_grade('A', i.a, i.a1, i.a_id)">
                      <h4 class="mt-3">حالة 3</h4>
                      <h6>{{ i.a }} </h6>
                      <ul class="text-right mt-3 ml-4 mr-2" style="padding:0% ;  list-style-type: none; ">
                        <li>
                          لا خدوش على الشاشة
                        </li>
                        <li>
                          لا انحناء في الجهاز و خدوش طفيفة
                        </li>
                        <li>
                          صحة البطارية 90 ٪ أو أعلى
                        </li>
                        <li>
                          تعمل بكامل طاقتها
                        </li>
                        <li>
                          لا يوجد قفل على الجهاز
                        </li>
                        <li>
                          لا توجد أجزاء مفقودة (الغطاء الخلفي ، اطار الشريحة ، أزرار)
                        </li>
                      </ul>
                    </div>
                  </b-col>
                </b-row>

              </b-col>
            </b-row>

          </div>
          <div class=" info-box border border-secondary mb-2 col-md-2 notranslate" style="min-height: fit-content">

            <div class="info-box border border-secondary mb-2 mt-4" style="min-height: 55px;">
              <p id="myDiv" ref="myDiv" class="mt-4">Mobiles</p>
            </div>
            <div v-if="brand_active" class="info-box border border-secondary mb-2 mt-2 " style="min-height: 55px;">
              <p class="mt-4">{{ brand_active }}</p>
            </div>
            <div v-if="model_active" class="info-box border border-secondary mb-2 mt-2 " style="min-height: 55px;">
              <p class="mt-4">{{ model_active }} Series</p>
            </div>
            <div v-if="pro_active" class="info-box border border-secondary mb-2 mt-2 " style="min-height: 55px;">
              <p class="mt-4">{{ pro_active }}</p>
            </div>
            <div v-if="grade_active" class="info-box border border-secondary mb-2 mt-2 " style="min-height: 55px;">
              <p class="mt-4">{{ grade_active }}</p>
            </div>
          </div>
          <div>
            <b-modal id="modal-prevent-closing" ref="modal" scrollable @show="resetModal" @hidden="resetModal"
              hide-footer hide-header @ok="handleOk">
              <!-- <div class="p-2 " >
                <p class="" style="font-size: small;">FINAL TRADE-IN AMOUNT</p>
                <h5>AED {{ value_selected }}</h5>
                <p class=""  style="font-size: small;">The final amount is based on the result provided for {{ pro_active }}</p>
              </div> -->


              <div class="text-right">
                <h2>نموذج إعادة الشراء</h2>
              </div>
              <!-- {{ selected3 }}i.id === selected3 -->
              <div class="p-2 text-right" v-if="promo !== 'No Data Found'">
                <p class="" style="font-size: small;">المبلغ النهائي في المبادلة</p>
                <h5 v-if="selected3 !== 'Select a Device' && selected3 !== null" v-for="i in promo" :key="i">
                  <b v-if="selected3.includes(i.id)"> {{ parseInt(value_selected) + parseInt(i.price) }}</b>
                </h5>
                <h5 v-if="selected3 === null"> {{ value_selected }}</h5>
                <h5 v-if="selected3 !== 'Select a Device' && selected3 !== null" v-for="i in promo" :key="i">
                  <p class="" style="font-size: small;" v-if="selected3.includes(i.id)">يعتمد المبلغ النهائي على النتيجة
                    المقدمة {{ pro_active }} of {{ value_selected }} + {{ i.price }} (Offer Price)</p>
                </h5>
                <h5 v-if="selected3 === null">
                  <p class="" style="font-size: small;">{{ pro_active }} يعتمد المبلغ النهائي على النتيجة المقدمة </p>
                </h5>
              </div>
              <div v-else class="text-right">
                <p class="" style="font-size: small;">المبلغ النهائي في المبادلة</p>
                <h5> {{ value_selected }}</h5>
                <h5>
                  <p class="" style="font-size: small;">{{ pro_active }} يعتمد المبلغ النهائي على النتيجة المقدمة </p>
                </h5>
              </div>
              <b-navbar toggleable type="light" variant="light" class="mt-3 text-right"
                v-if="promo !== 'No Data Found'">
                <!-- <b-navbar-brand href="#" class="text-right">تطبيق العرض</b-navbar-brand> -->
                <b-navbar-toggle target="navbar-toggle-collapse">
                  <template #default="{ expanded }">
                    <b-icon v-if="expanded" icon="chevron-bar-up">
                      <BIconChevronUp />
                    </b-icon>
                    <b-icon v-else icon="chevron-bar-down">
                      <BIconChevronDown />
                    </b-icon>
                  </template>
                </b-navbar-toggle>
                <div class="">
                  <p>
                    تطبيق العرض
                  </p>
                </div>


                <b-collapse id="navbar-toggle-collapse" is-nav>
                  <div>
                    <b-form-select v-model="selected2" class="mb-3 text-right" @change="offer()">
                      <b-form-select-option :value="null">الرجاء تحديد عرض</b-form-select-option>
                      <b-form-select-option :value="i.promo" v-for="i in promo" :key="i">{{ i.promo
                        }}</b-form-select-option>
                    </b-form-select>

                    <b-form-select v-model="selected3" class=""
                      v-if="selected2 !== 'Please Select an offer' && selected2 !== null">
                      <b-form-select-option :value="null">اختر جهازا</b-form-select-option>
                      <b-form-select-option :value="i.id" v-for="i in promo_device" :key="i"
                        v-if="selected2 === i.promo">{{ i.name
                        }}</b-form-select-option>
                    </b-form-select>
                    <div v-if="selected3 !== 'Select a Device' && selected3 !== null" class="">
                      <b-input-group>
                        <b-input-group-append class="mt-3" is-text style="background-color: transparent !important;">
                          <i class="fas fa-mobile show-icon"></i>
                        </b-input-group-append>
                        <b-form-input class="mt-3" :state="newdeviceState" :formatter="newdeviceFormatter"
                          v-model="new_device" placeholder="New Device Name"></b-form-input>
                      </b-input-group>
                      <b-input-group>
                        <b-input-group-append class="mt-3" is-text style="background-color: transparent !important;">
                          <i class="fas fa-barcode show-icon"></i>
                        </b-input-group-append>
                        <b-form-input class="mt-3" :state="imeiState" :formatter="imeiFormatter" v-model="text5"
                          placeholder="New Device IMEI/Serial"></b-form-input>
                      </b-input-group>

                    </div>
                  </div>

                </b-collapse>
              </b-navbar>
              <b-navbar toggleable type="light" variant="light" class="mt-3 text-right">
                <!-- <b-navbar-brand href="#">تفاصيل العميل</b-navbar-brand> -->

                <b-navbar-toggle target="navbar-toggle-collapse2">
                  <template #default="{ expanded }">
                    <b-icon v-if="expanded" icon="chevron-bar-up">
                      <BIconChevronUp />
                    </b-icon>
                    <b-icon v-else icon="chevron-bar-down">
                      <BIconChevronDown />
                    </b-icon>
                  </template>
                </b-navbar-toggle>
                <div class="">
                  <p>
                    تفاصيل العميل
                  </p>
                </div>
                <b-collapse id="navbar-toggle-collapse2" is-nav>
                  <div class="p-2  ">
                    <div class="d-flex ">
                      <div class="p-2 ">
                        <!-- <b-col cols="auto" class="px-1 " >
                    <div class="aa"  id="overlay">
                      <b>Full Name</b>
                    </div>
                  </b-col> -->
                        <label class="text-right"></label>
                        <b-form-input placeholder="الاسم" :state="nameState" v-model="userName"
                          class="inp text-right"></b-form-input>
                      </div>

                      <div class="p-2 ">
                        <!-- <b-col cols="auto" class="px-1 " >
                    <div class="aa"  id="overlay">
                      <b>Email ID</b>
                    </div>
                  </b-col> -->
                        <label class="text-right"></label>
                        <b-form-input placeholder="بريد إلكتروني" :state="emailState" v-model="userEmail"
                          class="inp text-right"></b-form-input>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="p-2 ">
                        <!-- <b-col cols="auto" class="px-1 " >
                    <div class="aa"  id="overlay">
                      <b>Mobile Number</b>
                    </div>
                  </b-col> -->
                        <label class="text-right"></label>

                        <b-input-group style="width: fit-content;">
                          <template #prepend>
                            <b-input-group-text class="phone-code">{{ country_code }}</b-input-group-text>
                          </template>
                          <b-form-input type="text"
                            onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
                            max="999999999" placeholder="phone number" :state="mobileState" v-model="userMobile"
                            class="input-login inp" :formatter="mobileFormatter"></b-form-input>
                        </b-input-group>
                        <!-- </b-input-group> -->
                      </div>
                      <div class="p-2">
                        <!-- <b-col cols="auto" class="px-1 " >
                    <div class="aa"  id="overlay">
                      <b>Address</b>
                    </div>
                  </b-col> -->
                        <label class="text-right"></label>
                        <b-form-input placeholder="عنوان" :state="addState" v-model="useradd"
                          class=" inp text-right"></b-form-input>
                      </div>

                    </div>
                    <div>
                      <div class="d-flex mt-3">
                        <VueUploadMultipleImage2 :new_device="new_device" :emailState="emailState"
                          :mobileState="mobileState" :nameState="nameState" :addState="addState" :userName="userName"
                          :userEmail="userEmail" :userMobile="userMobile" :useradd="useradd" :newimei="text5"
                          :selected3="selected3" @upload-success="uploadImageSuccess" @before-remove="beforeRemove"
                          @edit-image="editImage" :data-images="images" dragText="Upload Emrates ID (Front & Back)"
                          browseText="" maxImage="2" idUpload="myIdUpload" editUpload="myIdEdit" primaryText="Selected"
                          popupText="Upload Emrates ID">
                        </VueUploadMultipleImage2>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </b-navbar>
            </b-modal>
            <b-modal id="modal-prevent-closing-otp" ref="modal" title="Enter The OTP" @show="resetModal"
              @hidden="resetModal" @ok="handleOk2" hide-footer>

              <div class="d-flex justify-content-center">
                <otp-input :isValid="isCodeValid" :digits="4" @on-complete="onCompleteHandler"
                  @on-changed="onChangedHandler" @on-paste="onPasteHandler">
                  <template #errorMessage> There is an error </template>
                </otp-input>
              </div>
              <div class="d-flex justify-content-center">
                <b-button class="mt-3 btnn pl-4 pr-4" @click="handleOk2()">Confirm</b-button>

              </div>
            </b-modal>
            <b-modal id="modal-prevent-closing2" ref="modal" scrollable @show="resetModal" @hidden="resetModal"
              hide-footer @ok="handleOk">
              <div class="text-right">
                <h2>تفاصيل المنتج</h2>
              </div>
              <div class="p-2 text-right" v-if="promo !== 'No Data Found'">
                <p class="" style="font-size: small;">المبلغ النهائي في المبادلة</p>
                <h5 v-if="selected3 !== 'Select a Device' && selected3 !== null" v-for="i in promo" :key="i">
                  <b v-if="selected3.includes(i.id)"> {{ parseInt(value_selected) + parseInt(i.price) }}</b>
                </h5>
                <h5 v-if="selected3 === null"> {{ value_selected }}</h5>
                <h5 v-if="selected3 !== 'Select a Device' && selected3 !== null" v-for="i in promo" :key="i">
                  <p class="" style="font-size: small;" v-if="selected3.includes(i.id)">يعتمد المبلغ النهائي على النتيجة
                    المقدمة {{
        pro_active }} of {{ value_selected }} + {{ i.price }} (Offer Price)</p>
                </h5>
                <h5 v-if="selected3 === null">
                  <p class="" style="font-size: small;">{{ pro_active }} يعتمد المبلغ النهائي على النتيجة المقدمة </p>
                </h5>
              </div>
              <div v-else class="text-right">
                <p class="" style="font-size: small;">المبلغ النهائي في المبادلة</p>
                <h5> {{ value_selected }}</h5>
                <h5>
                  <p class="" style="font-size: small;">{{ pro_active }} يعتمد المبلغ النهائي على النتيجة المقدمة </p>
                </h5>
              </div>
              <b-navbar toggleable type="light" variant="light" class="mt-3 text-right">
                <!-- <b-navbar-brand href="#" >استلام قائمة المراجعة</b-navbar-brand> -->

                <b-navbar-toggle target="navbar-toggle-collapse3">
                  <template #default="{ expanded }">
                    <b-icon v-if="expanded" icon="chevron-bar-up">
                      <BIconChevronUp />
                    </b-icon>
                    <b-icon v-else icon="chevron-bar-down">
                      <BIconChevronDown />
                    </b-icon>
                  </template>
                </b-navbar-toggle>
                <div class="">
                  <p>
                    استلام قائمة المراجعة </p>
                </div>
                <b-collapse id="navbar-toggle-collapse3" is-nav>

                  <div class="p-3 ml-2 text-right">
                    <h6>تعليمات</h6>
                    <b-form-group label="" v-slot="{ ariaDescribedby }">
                      <b-form-checkbox class="text-right" v-for="option in options" v-model="selected"
                        :key="option.value" :value="option.value" :aria-describedby="ariaDescribedby" name="flavour-3a">
                        {{ option.text }}
                        <p style="font-size: smaller;" v-if="option.text === 'الجودة والأهلية'">لقد راجعت الجهاز وتم
                          توثيق جميع
                          الأخطاء. أنا أقر أن الجهاز ليس مزيفًا أو مسروق.</p>
                        <p style="font-size: smaller;" v-if="option.text === 'إزالة الشريحة'">لقد أزلت الشريحة و أعدت
                          إدخال اطار
                          الشريحة إلى الجهاز.</p>
                        <p style="font-size: smaller;" v-if="option.text === 'تم مسح البيانات'">لقد قمت بمسح الجهاز من
                          جميع البيانات
                          الشخصية.</p>
                        <p style="font-size: smaller;" v-if="option.text === 'ابحث عن جهازي'">لقد تأكدت من أن العثور على
                          جهاز iPhone
                          الخاص بي تم إيقاف تشغيله للجهاز.</p>
                      </b-form-checkbox>
                    </b-form-group>
                  </div>
                </b-collapse>
              </b-navbar>
              <b-navbar toggleable type="light" variant="light" class="mt-3">
                <!-- <b-navbar-brand href="#"> Device Info</b-navbar-brand> -->

                <b-navbar-toggle target="navbar-toggle-collapse4">
                  <template #default="{ expanded }">
                    <b-icon v-if="expanded" icon="chevron-bar-up">
                      <BIconChevronUp />
                    </b-icon>
                    <b-icon v-else icon="chevron-bar-down">
                      <BIconChevronDown />
                    </b-icon>
                  </template>
                </b-navbar-toggle>
                <div class="">
                  <p>
                    معلومات الجهاز </p>
                </div>
                <b-collapse id="navbar-toggle-collapse4" is-nav>

                  <VueUploadMultipleImage :new_device="new_device" :id="id" :id2="id2" :pro_active="pro_active"
                    :brand_active="brand_active" model_active="Mobiles" :sms_id="sms_id"
                    :value_selected="value_selected" :grade_selected="grade_selected" :selected="selected"
                    :vendor="vendor" :promotion="selected3" :newimei="text5" @upload-success="uploadImageSuccess"
                    @before-remove="beforeRemove" @edit-image="editImage" :data-images="images"
                    dragText="Upload Device Images" browseText="" maxImage="1" idUpload="myIdUpload"
                    editUpload="myIdEdit" primaryText="Selected" popupText="Upload Device Images">
                  </VueUploadMultipleImage>
                </b-collapse>
              </b-navbar>
              <div class="p-2  ">
                <div class="">

                </div>


              </div>

            </b-modal>

          </div>

        </b-container>

      </div>

    </div>



    <!-- Tablet View -->
    <div v-responsive.md>
      <div class="d-flex justify-content-center ">
        <b-container class="bv-example-row d-flex">
          <div class="info-box  justify-content-center  border border-secondary mb-2 col-md-12 scroll">
            <img src="../assets/Buyback_content/mobile/Step 1.svg" class="col-md-10 ml-5" v-if="brand_selected" />
            <img src="../assets/Buyback_content/mobile/Step 2.svg" class="col-md-10 ml-5" v-if="model_selected" />
            <img src="../assets/Buyback_content/mobile/Step 3.svg" class="col-md-10 ml-5" v-if="pro_selected" />
            <img src="../assets/Buyback_content/mobile/Step 4.svg" class="col-md-10 ml-5" v-if="repair" />
            <img src="../assets/Buyback_content/mobile/Step 5.svg" class="col-md-10 ml-5" v-if="grade_selected" />

            <b-row class="d-flex justify-content-center notranslate">
              <b-col v-for="i in brandList" :key="i.id" class="mt-2" v-if="brand_selected">
                <a href="#">
                  <div class="info-box border border-secondary mb-2"
                    style="max-height: 150px;max-width: 150px;min-height: 150px;min-width: 150px"
                    @click="brand(i.id, i.name)">
                    <img :src="'data:image/png;base64,' + i.image" height="120" />
                    <br />
                    {{ i.name }}
                  </div>
                </a>
              </b-col>
            </b-row>

            <b-row class="d-flex justify-content-center notranslate" v-if="model_selected">
              <b-col v-if="!modelList2.empty && !modelList2.error" v-for="i in modelList2" :key="i.id" class="mt-2">
                <a href="#">
                  <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center"
                    style="min-height: 80px;min-width: 130px" @click="model_new(i.id, i.name)">
                    <p class="txt2">{{ i.name }} Series</p>
                  </div>
                </a>
              </b-col>
              <div v-else>
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <span class="mb-2">No Data Found</span>
                  <b-button class="explore_btn mb-2" @click="reloadPage">Explore More Brands</b-button>
                </div>
              </div>
            </b-row>
            <b-row class="d-flex justify-content-center notranslate" v-if="pro_selected">
              <b-col v-if="!modelPro.empty && !modelPro.error" v-for="i in modelPro" :key="i.id" class="mt-2">
                <a href="#">
                  <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center"
                    style="min-height: 80px;min-width: 130px" @click="pro_new(i.id, i.name)">
                    <p class="txt2">{{ i.name }}</p>
                  </div>
                </a>
              </b-col>
              <div v-else>
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <span class="mb-2">No Data Found</span>
                  <b-button class="explore_btn mb-2" @click="reloadPage">Explore More Brands</b-button>
                </div>
              </div>
            </b-row>
            <b-row class="d-flex justify-content-center notranslate" v-if="repair">
              <b-col v-for="i in priceList" :key="i.id" class="mt-2">
                <a href="#">
                  <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center"
                    style="min-height: 80px;min-width: 130px" @click="grades(i.id, i.name)">
                    <p class="txt2">{{ i.name }}</p>
                  </div>
                </a>
              </b-col>
            </b-row>
            <b-row class=" d-flex justify-content-center " v-if="grade_selected">
              <b-col v-for="i in gradeList" :key="i.id" class="">
                <b-row>
                  <b-col>
                    <div class="info-box3 txt border border-secondary "
                      style="max-height: 350px;max-width: 350px;min-height: 350px;min-width: 350px; font-size: medium;"
                      v-if="i.a" @click="checkout_grade('A', i.a, i.a1, i.a_id)">
                      <h4 class="mt-3">Condition 1</h4>
                      <h6>{{ i.a }} </h6>
                      <ul class="text-left mt-3 ml-4 mr-2" style="padding:0% ; ">
                        <li>
                          No Scratches on the screen
                        </li>
                        <li>
                          Dent-free and minor scratches on body
                        </li>
                        <li>
                          Battery health 90% or Above
                        </li>
                        <li>
                          Fully functional
                        </li>
                        <li>
                          No Device Lock/Sim Lock
                        </li>
                        <li>
                          No missing parts (back cover, sim tray, buttons)
                        </li>
                      </ul>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="info-box3 txt border border-secondary mt-3"
                      style="max-height: 350px;max-width: 350px;min-height: 350px;min-width: 350px;font-size: medium;"
                      v-if="i.b" @click="checkout_grade('B', i.b, i.b1, i.b_id)">
                      <h4 class="mt-3">Condition 2</h4>
                      <h6>{{ i.b }} </h6>
                      <ul class="text-left mt-3 mr-2  ml-4" style="padding:0% ;">
                        <li>
                          Visible scratches on the screen, open or swapped
                        </li>
                        <li>
                          Major scratches / dents on back cover and housing.
                        </li>
                        <li>
                          Battery health 80% or Above
                        </li>
                        <li>
                          Issues with either mic, speaker or buttons
                        </li>
                        <li>
                          No Device Lock/Sim Lock
                        </li>
                        <li>
                          No missing parts (back cover, sim tray, buttons)
                        </li>
                      </ul>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="info-box3 txt border border-secondary mt-3 mb-5"
                      style="max-height: 350px;max-width: 350px;min-height: 350px;min-width: 350px;font-size: medium;"
                      v-if="i.c" @click="checkout_grade('C', i.c, i.c1, i.c_id)">
                      <h4 class="mt-3">Condition 3</h4>
                      <h6>{{ i.c }} </h6>
                      <ul class="text-left mt-2 ml-4 mr-2" style="padding:0% ;">
                        <li>
                          LCD dots/ lines/ discoloration/Screen Burn, Screen Touch not working
                        </li>
                        <li>
                          Cracked device
                        </li>
                        <li>
                          Battery health: Service indicator on
                        </li>
                        <li>
                          Any of the following not working: Touch ID, Face ID, Camera
                        </li>
                        <li>
                          Device Lock/Sim Lock
                        </li>
                        <li>
                          Missing parts (back cover, sim tray or buttons)
                        </li>
                      </ul>
                    </div>
                  </b-col>
                </b-row>

              </b-col>
            </b-row>

          </div>

        </b-container>

      </div>
    </div>






    <!-- Mobile View -->
    <div class="" v-responsive.sm.xs>
      <hr />


      <div class="d-flex justify-content-center ">
        <b-container class="bv-example-row d-flex">
          <div class="info-box  justify-content-center  border border-secondary mb-2 col-md-12 scroll">
            <img src="../assets/Buyback_content/mobile/Step 1.svg" class="col-md-12" />
            <b-row class="d-flex justify-content-center notranslate">
              <b-col v-for="i in brandList" :key="i.id" class="mt-2" v-if="brand_selected">
                <a href="#">
                  <div class="info-box border border-secondary mb-2"
                    style="max-height: 130px;max-width: 130px;min-height: 130px;min-width: 130px"
                    @click="brand(i.id, i.name)">
                    <img :src="'data:image/png;base64,' + i.image" height="90" />
                    <br />
                    {{ i.name }}
                  </div>
                </a>
              </b-col>
            </b-row>

            <b-row class="d-flex justify-content-center notranslate" v-if="model_selected">
              <b-col v-if="!modelList2.empty && !modelList2.error" v-for="i in modelList2" :key="i.id" class="mt-2">
                <a href="#">
                  <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center"
                    style="min-height: 80px;min-width: 130px" @click="model_new(i.id, i.name)">
                    <p class="txt2">{{ i.name }} Series</p>
                  </div>
                </a>
              </b-col>
              <div v-else>
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <span class="mb-2">No Data Found</span>
                  <b-button class="explore_btn mb-2" @click="reloadPage">Explore More Brands</b-button>
                </div>
              </div>
            </b-row>
            <b-row class="d-flex justify-content-center notranslate" v-if="pro_selected">
              <b-col v-if="!modelPro.empty && !modelPro.error" v-for="i in modelPro" :key="i.id" class="mt-2">
                <a href="#">
                  <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center"
                    style="min-height: 80px;min-width: 130px" @click="pro_new(i.id, i.name)">
                    <p class="txt2">{{ i.name }}</p>
                  </div>
                </a>
              </b-col>
              <div v-else>
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <span class="mb-2">No Data Found</span>
                  <b-button class="explore_btn mb-2" @click="reloadPage">Explore More Brands</b-button>
                </div>
              </div>
            </b-row>
            <b-row class="d-flex justify-content-center notranslate" v-if="repair">
              <b-col v-for="i in priceList" :key="i.id" class="mt-2">
                <a href="#">
                  <div class="info-box2 d-flex border border-secondary mb-2  justify-content-center"
                    style="min-height: 80px;min-width: 130px" @click="grades(i.id, i.name)">
                    <p class="txt2">{{ i.name }}</p>
                  </div>
                </a>
              </b-col>
            </b-row>
            <b-row class=" d-flex justify-content-center " v-if="grade_selected">
              <b-col v-for="i in gradeList" :key="i.id" class="">
                <b-row>
                  <b-col>
                    <div class="info-box3 txt border border-secondary "
                      style="max-height: 300px;max-width: 250px;min-height: 300px;min-width: 250px" v-if="i.a"
                      @click="checkout_grade('A', i.a, i.a1, i.a_id)">
                      <h4 class="mt-3">Condition 1</h4>
                      <h6>{{ i.a }} </h6>
                      <ul class="text-right mt-3 ml-4 mr-2" style="padding:0% ;  list-style-type: none; ">
                        <li>
                          الخطوط او النقاط في الشاشة او تغير في اللوان الشاشة او حرق في الشاشة او اللمس لا يعمل
                        </li>
                        <li>
                          جهاز متصدع
                        </li>
                        <li>
                          صحة البطارية: مؤشر الخدمة
                        </li>
                        <li>
                          أي مما يلي لا يعمل: البصمة او بصمة الوجه او الكاميرا
                        </li>
                        <li>
                          قفل الجهاز/قفل الشريحة
                        </li>
                        <li>
                          الأجزاء المفقودة (الغطاء الخلفي ، اطار الشريحة أو أزرار)
                        </li>
                      </ul>

                    </div>
                  </b-col>
                  <b-col>
                    <div class="info-box3 txt border border-secondary mt-3"
                      style="max-height: 300px;max-width: 250px;min-height: 300px;min-width: 250px" v-if="i.b"
                      @click="checkout_grade('B', i.b, i.b1, i.b_id)">
                      <h4 class="mt-3">Condition 2</h4>
                      <h6>{{ i.b }} </h6>
                      <ul class="text-right mt-3 mr-2  ml-4" style="padding:0% ;  list-style-type: none;">
                        <li>
                          خدوش مرئية على الشاشة ، مفتوح أو مبدل
                        </li>
                        <li>
                          خدوش كبيرة / خدوش على الغطاء الخلفي والجوانب.
                        </li>
                        <li>
                          صحة البطارية 80 ٪ أو أعلى
                        </li>
                        <li>
                          مشاكل في المايكروفون او السماعات او الازرار
                        </li>
                        <li>
                          لا يوجد قفل على الجهاز/الشريحة
                        </li>
                        <li>
                          ا توجد أجزاء مفقودة (الغطاء الخلفي ، اطار الشريحة ، أزرار)
                        </li>
                      </ul>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="info-box3 txt border border-secondary mt-3 mb-5"
                      style="max-height: 300px;max-width: 250px;min-height: 300px;min-width: 250px" v-if="i.c"
                      @click="checkout_grade('C', i.c, i.c1, i.c_id)">
                      <h4 class="mt-3">Condition 3</h4>
                      <h6>{{ i.c }} </h6>
                      <ul class="text-right mt-3 ml-4 mr-2" style="padding:0% ;   list-style-type: none;">
                        <li>
                          لا خدوش على الشاشة
                        </li>
                        <li>
                          لا انحناء في الجهاز و خدوش طفيفة
                        </li>
                        <li>
                          صحة البطارية 90 ٪ أو أعلى
                        </li>
                        <li>
                          تعمل بكامل طاقتها
                        </li>
                        <li>
                          لا يوجد قفل على الجهاز
                        </li>
                        <li>
                          لا توجد أجزاء مفقودة (الغطاء الخلفي ، اطار الشريحة ، أزرار)
                        </li>
                      </ul>
                    </div>
                  </b-col>
                </b-row>

              </b-col>
            </b-row>

          </div>

        </b-container>

      </div>
    </div>
  </div>
</template>

<script>
import api from '../api'
import Header from '../components/Header2_ar.vue'
import VueUploadMultipleImage from '../components/VueUploadMultipleImage_ar.vue'
import VueUploadMultipleImage2 from '../components/VueUploadMultipleImage2_ar.vue'
import OtpInput from "otp-input-vue2";
import { BIconChevronUp, BIconChevronDown } from 'bootstrap-vue'
export default {
  name: 'LeftBar',
  props: [],
  components: {
    Header,
    VueUploadMultipleImage,
    VueUploadMultipleImage2,
    OtpInput,
    BIconChevronUp,
    BIconChevronDown
  },
  data() {
    return {
      trans: null,
      id2: this.$route.query.id,
      promo: null,
      promo_device: [],
      name2: '',
      name2State: null,
      status: 'not_accepted',
      selected: [], // Must be an array reference!
      options: [
        { text: 'الجودة والأهلية', value: 'Quality' },
        { text: 'إزالة الشريحة', value: 'Sim' },
        { text: 'تم مسح البيانات', value: 'Data' },
        { text: 'ابحث عن جهازي', value: 'Find' }
      ],
      selected2: null,
      selected3: null,
      options2: [
        { value: null, text: 'New Device Model.' },
        { value: 'a', text: 'iPhone 13' },
        { value: 'b', text: 'iPhone 13 Pro' },
        { value: 'c', text: 'iPhone 13 Pro Max' },
      ],
      options3: [
        { value: null, text: 'Select Payment' },
        { value: 'a', text: 'Cash' },
        { value: 'b', text: 'Card' }
      ],
      sms_id: null,
      C: null,
      id: null,
      grade_selected: null,
      value_selected: null,
      brand_selected: true,
      brand_active: null,
      brand_id: null,
      model_selected: false,
      model_active: null,
      model_id: null,
      pro_selected: false,
      pro_active: null,
      pro_id: null,
      grade_selected: false,
      grade_active: null,
      grade_id: null,
      vendor: null,
      userName: this.$store.state.signUpForm ? this.$store.state.signUpForm.name : '',
      useradd: this.$store.state.signUpForm ? this.$store.state.signUpForm.useradd : '',
      userEmail: this.$store.state.signUpForm ? this.$store.state.signUpForm.email : '',
      userMobile: this.$store.state.signUpForm ? this.$store.state.signUpForm.mobile : '',
      text5: this.$store.state.signUpForm ? this.$store.state.signUpForm.text5 : '',
      new_device: this.$store.state.signUpForm ? this.$store.state.signUpForm.new_device : '',
      country_code: 0,

    }
  },
  computed: {
    Location: function () {
      return this.$store.state.user.location
    },
    nameState() {
      return this.userName.length > 3 ? true : false
    },
    addState() {
      return this.useradd.length > 4 ? true : false
    },
    emailState() {
      if (this.userEmail.length < 5 || this.userEmail.length) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(this.userEmail).toLowerCase())
      } else {
        return null
      }
    },

    mobileState: function () {
      if (this.userMobile.length < 9 || this.userMobile.length) {
        return this.userMobile.length === 9
      } else {
        return null
      }
    },
    imeiState() {
      if (this.text5.length < 5 || this.text5.length) {
        const re = /^[0-9a-zA-Z]+$/
        return re.test(String(this.text5).toLowerCase())
      } else {
        return null
      }
    },
    newdeviceState() {
      if (this.new_device.length < 5) {

        return false
      } else {
        return true
      }
    },
    brandList: function () {
      return this.$store.state.brandList
    },
    modelList: function () {
      return this.$store.state.modelList
    },
    modelList2: function () {

      return this.$store.state.modelList2
    },
    modelPro: function () {
      return this.$store.state.modelPro
    },
    modelGen: function () {
      return this.$store.state.modelGen
    },
    modelRam: function () {
      return this.$store.state.modelRam
    },
    romList: function () {
      return this.$store.state.romList
    },
    priceList: function () {
      return this.$store.state.deductionData
    },
    gradeList: function () {
      return this.$store.state.gradesData
    },
    defects: function () {
      return this.$store.state.defectList
    }
  },
  mounted() {
    this.$store.commit('CLEAR_SELECTION')
  },

  methods: {
    getPromo: function (id, grade) {
      let currentDate = new Date().toJSON().slice(0, 10);
      const inputs = {

        'id': this.$store.state.user.uid,
        'device': id,
        'date': currentDate,
        'category': parseInt(this.$route.params.cate_id),
        'brand': this.brand_id,
        'condition': grade

      }
      api
        .request(process.env.VUE_APP_Get_Promotion_Buyback, inputs)
        .then((response) => {
          var data = response.data
          if (data.errorcode) {
            return
          }
          if (data.result) {
            // console.log(data.result)
            this.promo = data.result
            this.promo_device = []
            var i = 0
            var j = 0
            for (let i = 0; i < this.promo.length; i++) {
              for (let j = 0; j < this.promo[i].device.length; j++) {
                this.promo_device.push(this.promo[i].device[j]);
              }
            }
          }
        })
        .catch((error) => {
          // console.log('What happened? ' + error)
        })
    },
    uploadImageSuccess(formData, index, fileList) {

    },
    reloadPage() {
      location.reload();
    },
    myFunction(value) {
      // console.log(value)
    },
    beforeRemove(index, done, fileList) {
      // console.log('index', index, fileList)
      var r = confirm("remove image")
      if (r == true) {
        done()
      } else {
      }
    },
    editImage(formData, index, fileList) {
      // console.log('edit data', formData, index, fileList)
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    mobileFormatter: function (e) {
      return String(e).substring(0, 9)
    },
    imeiFormatter: function (e) {
      return String(e).substring(0, 20)
    },
    newdeviceFormatter: function (e) {
      return String(e).substring(0, 40)
    },

    checkout_grade: function (grade, price, vendor, id) {
      this.id = id
      this.getPromo(id, grade)
      this.trans = this.$refs.myDiv.innerHTML
      this.grade_selected = grade
      this.value_selected = price
      this.vendor = vendor
      if (this.id2) {
        this.$bvModal.show('modal-prevent-closing2')
      } else {
        this.$bvModal.show('modal-prevent-closing')
      }
    },
    cate: function (cate_id, name) {
      this.category_selected = false
      this.category_active = name
      this.category_id = cate_id
      this.getBrands2(cate_id)
    },
    brand: function (cate_id, name) {
      this.brand_active = name
      this.brand_id = cate_id
      this.getModels(name)

    },
    model_new: function (cate_id, name) {
      this.model_active = name
      this.model_id = cate_id
      this.getYear(name)
    },
    pro_new: function (cate_id, name) {
      this.pro_active = name
      this.pro_id = cate_id
      this.getDeductionData(name)
    },
    grades: function (cate_id, name) {
      this.grade_active = name
      this.grade_id = cate_id
      this.getGradeData(cate_id)
    },
    getModels: function (id) {
      const inputs = {

        'cate': 'Mobile',
        'brand': this.brand_active,
        'location': this.Location

      }
      api
        .request(process.env.VUE_APP_Get_Series_data, inputs)
        .then((response) => {
          var data = response.data
          if (data.errorcode) {
            return
          }
          if (data.result) {
            this.$store.commit('SET_MODEL_LIST2', data.result)
            this.brand_selected = false
            this.model_selected = true
          }
        })
        .catch((error) => {
          // console.log('What happened? ' + error)
        })
    },
    getYear: function (id) {
      const inputs = {

        'cate': 'Mobile',
        'brand': this.brand_active,
        'series': id,
        'location': this.Location

      }
      api
        .request(process.env.VUE_APP_Get_Model_Data, inputs)
        .then((response) => {
          var data = response.data
          if (data.errorcode) {
            return
          }
          if (data.result) {
            this.$store.commit('SET_MODEL_Proc', data.result)
            this.model_selected = false
            this.pro_selected = true
          }
        })
        .catch((error) => {
          // console.log('What happened? ' + error)
        })
    },
    getDeductionData: function (id) {
      const inputs = {

        'cate': 'Mobile',
        'brand': this.brand_active,
        'model': id,
        'location': this.Location

      }
      api
        .request(process.env.VUE_APP_Get_Rom_Data, inputs)
        .then((response) => {
          var data = response.data
          if (data.errorcode) {
            return
          }
          if (data.result) {
            // console.log('sdsds')
            this.$store.commit('SET_DEDUCTION_DATA', data.result)
            this.pro_selected = false
            this.repair = true

          }
        })
        .catch((error) => {
          // console.log('What happened? ' + error)
        })
    },
    getGradeData: function (id) {
      let currentDate = new Date().toJSON().slice(0, 10);

      const inputs = {
        'id': id,
        'user': this.$store.state.user.uid,
        'current_date': currentDate,
        'country': this.Location
      }
      api
        .request(process.env.VUE_APP_Get_Price_Data, inputs)
        .then((response) => {
          var data = response.data
          if (data.errorcode) {
            return
          }
          if (data.result) {
            // console.log('sdsds')
            this.$store.commit('SET_GRADES_DATA', data.result)
            // this.id = data.result[0].id
            this.country_code = data.result[0].country_code
            this.repair = false
            this.grade_selected = true

          }
        })
        .catch((error) => {
          // console.log('What happened? ' + error)
        })
    },
    offer() {
      if (this.selected2 === null) {
        this.selected3 = null
        this.text5 = ''
        this.new_device = ''
      }
    },
    getBrands2: function () {
      // if (!this.$store.state.selectedCategory) {
      //   return false
      // }
      const inputs = {

        'cate_id': parseInt(this.$route.params.cate_id)

      }
      const url = 'dataset'
      api
        .request(process.env.VUE_APP_Get_Brand_Data, inputs)
        .then((response) => {
          var data = response.data
          if (data.errorcode) {
            // this.response = data.errormessage
            return
          }
          if (data.result) {
            this.$store.commit('SET_BRAND_LIST', data.result)
          }
        })
        .catch((error) => {
          // console.log('What happened? ' + error)
        })
    },
    clearSelection: function () {
      this.$store.commit('CLEAR_SELECTION')
    },
    visit: function (sel) {
      if (sel === 'cate') {
        this.$router.push({ name: 'ReuseSell' })
      } else {
        window.location.reload()
      }
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvent) {
      this.getOTP()
    },
    handleOk2(bvModalEvent) {
      this.$bvModal.hide('modal-prevent-closing-otp')
      this.$bvModal.show('modal-prevent-closing2')
      // this.getOTP()
    },
    handleOnComplete(value) {
      // console.log('OTP completed: ', value);
    },
    handleOnChange(value) {
      // console.log('OTP changed: ', value);
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }
      this.submittedNames.push(this.name)
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing')
      })
    },

  },
  mounted() {
    // this.$bvModal.show('modal-prevent-closing2')
    if (!this.$store.state.user) {
      this.$bvModal.show('modalSignIn')
    } else {
      this.getBrands2()

    }
  }
}
</script>

<style scoped>
.icon-left {
  position: absolute;
  right: -20px;
  top: 0;
}

a {
  color: #A9A9A9 !important;
}

/* .selection-box-shadow {
      animation: mymove 3s infinite;
    } */

@keyframes mymove {
  50% {
    box-shadow: 0px 0px 15px 0px rgba(0, 193, 56, 0.75);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 193, 56, 0.75);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 193, 56, 0.75);
  }
}

.aa {
  text-align: left;
  margin-left: 20px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: -10px;
  background: #FFFFFF;
  width: fit-content;
  position: absolute;
  display: block;
  z-index: 2;
  border-radius: 5px;
}

.details {
  padding: 20px;
  margin: 10px;
  background: #FFFFFF;
  border: 1px solid rgba(63, 63, 68, 0.005);
  box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
  border-radius: 7px;
}

.details2 {
  padding: 10px;
  background: #FFFFFF;
  border: 1px solid rgba(63, 63, 68, 0.005);
  box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
  border-radius: 7px;
}

.details2 b {
  margin-left: 20px;
}

.list-group-item {
  color: black !important;
}

.list-group-item.active {
  background-color: rgb(56, 173, 56) !important;
  border-color: rgb(56, 173, 56) !important;
}

.info-box {
  margin-right: 3px;
  margin-left: 3px;
  padding: 3px;
  border-radius: 7px;
  border-color: #e0e0ec !important;
  text-align: center;
  background: #FFFFFF;
  border: 1px solid rgba(63, 63, 68, 0.005);
  box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
  border-radius: 7px;
}

.info-box4 {
  margin-right: 3px;
  margin-left: 3px;
  padding: 3px;
  border-radius: 7px;
  border-color: #e0e0ec !important;
  text-align: center;
  background: #FFFFFF;
  border: 1px solid rgba(63, 63, 68, 0.005);
  box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
  border-radius: 7px;
}

.info-box4:hover {
  background-color: green;
  color: white;
}

.info-box2 {
  margin-right: 3px;
  margin-left: 3px;
  padding: 3px;
  border-radius: 7px;
  border-color: green !important;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

}

.info-box2:hover {
  background-color: green;
  color: white;
}

.info-box3 {
  border-color: green !important;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.info-box3:hover {
  background-color: green;
  color: white;
}

.otp-inputnew {
  width: 45px;
  height: 40px;
  padding: 5px;
  margin: 0 10px !important;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-style: dashed;
  text-align: center;
  margin-right: 50px;
}

/* Background colour of an input field with value */
.otp-inputnew.is-complete {
  background-color: #e4e4e4;
}

.otp-inputnew::-webkit-inner-spin-button,
.otp-inputnew::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.otp-title {
  color: #3FAF2B;
  font-weight: bold;
  font-size: 24px !important;
}

.col {
  flex-grow: 0;
}

.scroll {
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}

.txt {
  font-size: smaller;
}

.explore_btn {
  background-color: rgb(56, 173, 56) !important;
  color: #fff;
  border: none;
}

.aa {
  text-align: left;
  margin-left: 10px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
  background: #FFFFFF;
  width: fit-content;
  position: absolute;
  display: block;
  font-size: smaller;
  font-weight: lighter;
  z-index: 2;
  border-radius: 5px;
}

.btnn:hover {
  background-color: #3FAF2B;
  border-color: #3FAF2B;
}

footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: #FFFFFF !important;
  border-radius: 15px 15px 0px 0px;
  justify-content: center;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.5) !important;
}

.aaa {
  color: black !important;
}

.aaa:hover {
  color: #FFFFFF !important;
}

.a {
  color: black !important;
  ;
}

.txt2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

a {
  color: black !important;
}
</style>