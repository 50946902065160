<template>
    <div>
      <Header/>
      <header>
          <ServiceBar />
          <!-- <ServiceBar :items="getServiceTypes" /> -->
        </header>
      <div class="d-none d-lg-block">
        <b-container class="mt-5 d-flex">
            <b-col class="mt-3">
                <p class="title text-center mt-3">New Credentials</p>
                <h5 class=" text-center mt-3">Enter Your New Password</h5>
                <PasswordField />
            </b-col>
            <b-col class="tip mt-5">
                <p class="title text-center mt-3">Guide to Create Strong Passwords</p>
                <ul class="text-left ml-3" style="font-size: large;">
                  <li>At least 8 characters the more characters, the better.</li>
                  <li>A mixture of both uppercase and lowercase letters.</li>
                  <li>A mixture of letters and numbers.</li>
                  <li>Inclusion of at least one special character, e.g., ! @ # ? ]</li>
                </ul>
                  </b-col>
        </b-container>
      </div>
      <div class="d-lg-none">
        <b-container class="mt-5 ">
          <b-col class="tip mt-5">
                <p class="title text-center mt-3">Guide to Create Strong Passwords</p>
                <ul class="text-left " style="font-size: medium;">
                  <li>At least 8 characters the more characters, the better.</li>
                  <li>A mixture of both uppercase and lowercase letters.</li>
                  <li>A mixture of letters and numbers.</li>
                  <li>Inclusion of at least one special character, e.g., ! @ # ? ]</li>
                </ul>
                  </b-col>
            <b-col class="mt-3">
                <p class="title text-center mt-3">New Credentials</p>
                <h5 class=" text-center mt-3">Enter Your New Password</h5>
                <PasswordField />
            </b-col>
            
        </b-container>
      </div>
      <Footer/>
    </div>
  </template>
  
  <script>
  import api from '../api'
  import PasswordField from '@/components/PasswordField.vue'
  import Header from '@/components/Header.vue'
  import Footer from '@/components/Footer.vue'
  // import ServiceBar from '@/components/ServiceBar.vue'

  export default {
    name: 'Profile',
    data () {
      return {
      }
    },
    props: {
    },
    computed: {
    },
    components: {
      Header,
      PasswordField,
      Footer,
      // ServiceBar
    },
    methods: {
      forgotpassword: function () {
        if(window.location.href === 'https://www.r3factory.com/forgotpasswordlink'){
          this.$router.push({ name: 'LandingPage' })
        }else if(window.location.href === 'https://www.r3factory.com/forgotpasswordlink'){
          this.$router.push({ name: 'LandingPage' })
        }
      }
    },
    mounted () {
      this.forgotpassword()
    }
  }
  </script>
  
  <style scoped>
  .title{
    color: #37a531;
    font-size: 30px;
    font-family: 'Avenir Black', sans-serif;
  }
  .tip{
    border: 1px solid green;
    border-radius: 40px;
  }
  </style>
  