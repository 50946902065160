<template>
  <div>
    <SplashScreen v-if="animate" />

    <Header class="head" />

    <div class="mt-5 pb-2 print d-none d-lg-block">
      <!-- <h2 class="mb-3 info-box" style="color: #39A536;"><b>Thank you</b></h2> -->
      <div class="d-flex justify-content-center mt-3">
        <b-container class=" d-flex justify-content-center">
          <div class=" mb-2 ">
            <div class="home">
              <!-- <img src="../assets/Buyback_content/5 - Thank you.svg"/> -->
              <div class="row justify-content-end" v-if="this.$store.state.user">
                <b-button @click="getOrderStatus2()" class="button mr-3 mb-3"
                  v-if="this.$store.state.user.position === 'Manager' && order[0].status === 'Pending'">Cancel
                  Buyback</b-button>
                <!-- <b-button @click="getOrderStatus()" class="button mr-3 mb-3" v-if="order[0].status === 'Pending'">Incomplete Buyback</b-button>
            <b-button @click="getOrderStatus()" class="button mr-3 mb-3" v-if="order[0].status === 'Incomplete'">Complete Buyback</b-button> -->
                <!-- <b-button @click="print_rsp()" class="button mr-3 mb-3" v-if="trans === 'Seller'">Print</b-button>
            <b-button @click="print_rsp()" class="button mr-3 mb-3" v-else>اطبع</b-button> -->
                <b-button @click="exportToPDF" class="button mr-3 mb-3" v-if="trans === 'Seller'">Print
                  Receipt</b-button>
                <b-button @click="exportToPDF" class="button mr-3 mb-3" v-else>Print Receipt</b-button>

              </div>
              <div class="row justify-content-end" v-else>
                <b-button @click="exportToPDF" class="button mr-3 mb-3" v-if="trans === 'Seller'">Print
                  Receipt</b-button>
                <b-button @click="exportToPDF" class="button mr-3 mb-3" v-else>Print Receipt</b-button>

              </div>
              <div class="thanks p-3 info-box border border-secondary mb-2 " id="element-to-convert" v-if="!animate">
                <div class="text-center d-flex justify-content-center" style="position: relative;">
                  <div class="text-left">
                    <h5 v-if="trans === 'Seller'">Purchase Receipt</h5>
                    <h5 v-else>إيصال الشراء</h5>
                    <p><b>Date:</b> {{ order[0].date }}</p>
                    <p><b>Reference#</b> {{ order[0].id }}</p>
                  </div>

                  <qr-code class="ml-5" :text="'https://vas.redingtongroup.com/orders/sell/' + id" size="80"></qr-code>

                  <div v-if="order[0].partner === 'LULU'" style=" position: absolute;
                      
                      right: 16px;
                      font-size: 18px;align-self: center;">
                    <barcode v-bind:value="order[0].lulu_gift" width="1" height="50" font-size="12">
                      Error finding barcode
                    </barcode>
                    <!-- <qr-code class="ml-5" :text="order[0].lulu_gift" size="80"></qr-code> -->
                    <p style="font-size: 12px;font-weight: 600;">Lulu Barcode</p>

                  </div>

                  <!-- <div>
              <qr-code :text="'https://vas.redingtongroup.com/orders/sell/'+id" size="100"></qr-code>
            </div> -->
                </div>
                <div class="text-left mt-3">
                  <div class="d-flex justify-content-around ">
                    <div>
                      <h6><u id="myDiv" ref="myDiv">Seller</u></h6>
                      <p class=" notranslate"><b>Name:</b> {{ order[0].seller }}</p>
                      <p class="notranslate"><b>Email:</b> {{ order[0].email }}</p>
                      <p class="notranslate"><b>Contact:</b> {{ order[0].num }}</p>
                      <p class="notranslate"><b>Place:</b> {{ order[0].add }}</p>
                    </div>
                    <div>
                      <h6 class=""><u>Purchased via</u></h6>
                      <p class=" "><b>Sales Representative:</b> {{ order[0].sales }}</p>
                      <p class=" "><b>Store Name:</b> {{ order[0].address }}</p>
                      <p><b>Address:</b> {{ order[0].shop }}</p>
                    </div>

                  </div>

                  <br />
                  <!-- <h6><u id="myDiv" ref="myDiv">Seller</u></h6>
        <p class="mt-4 notranslate"><b>Name:</b> {{order[0].seller}}</p>
        <p class="notranslate"><b>Email:</b> {{order[0].email}}</p>
        <p class="notranslate"><b>Contact:</b> {{order[0].num}}</p>
        <p class="notranslate"><b>Place:</b> {{order[0].add}}</p> -->
                  <!-- <div class="d-flex justify-content-center" v-if="order[0].pro_d">
          <table id="customers">
            <tr >
              <th style="border-right: none; ">The Device You Have Purchased</th>
              <th style="border-left: none;"></th>
            </tr>
            <tr>
              <th>Product Description</th>
              <th>IMEI/Serial Number </th>
            </tr>
            <tr>
              <td>{{order[0].pro_d}}</td>
              <td>{{order[0].pro_i}}</td>
            </tr>
          </table>
        </div> -->
                  <div class=" d-flex justify-content-center">
                    <table id="customers">
                      <tr v-if="order[0].pro_d">
                        <th style="border-right: none; ">The Device You Have Purchased</th>
                        <th style="border-left: none;border-right: none; "></th>
                        <th style="border-left: none;border-right: none; "></th>
                        <th style="border-left: none;border-right: none; "></th>
                        <th style="border-left: none;"></th>
                      </tr>
                      <tr v-if="order[0].pro_d">
                        <th>Product Description</th>
                        <th style="border-right: none; ">IMEI/Serial Number </th>
                        <th style="border-left: none;border-right: none; "></th>
                        <th style="border-left: none;border-right: none; "></th>
                        <th style="border-left: none;"></th>
                      </tr>
                      <tr v-if="order[0].pro_d">
                        <td>{{ order[0].pro_d }}</td>
                        <td style="border-right: none; ">{{ order[0].pro_i }}</td>
                        <td style="border-left: none;border-right: none; "></td>
                        <td style="border-left: none;border-right: none; "></td>
                        <td style="border-left: none;"></td>
                      </tr>
                      <tr>
                        <th style="border-right: none; ">The Device You wish to trade-in</th>
                        <th style="border-left: none;border-right: none; "></th>
                        <th style="border-left: none;border-right: none; "></th>
                        <th style="border-left: none;border-right: none; "></th>
                        <th style="border-left: none;"></th>

                      </tr>
                      <tr>
                        <th>Product Description</th>
                        <th>IMEI/Serial Number </th>
                        <th>Price</th>
                        <th>Additional Value</th>
                        <th>Total</th>
                      </tr>
                      <tr v-for="i in order" :key="i">
                        <td>{{ i.device }}</td>
                        <td>{{ i.imei }}</td>
                        <td>{{ i.price }}</td>
                        <td v-if="i.device === order[0].device && order[0].pro_p !== false">{{ order[0].pro_p }}</td>
                        <td v-else>None</td>
                        <td v-if="i.device === order[0].device && order[0].pro_p !== false">{{ parseInt(i.price) +
      parseInt(order[0].pro_p) }}</td>
                        <td v-else>{{ parseInt(i.price) }}</td>
                      </tr>
                      <tr>
                        <th style="border-right: none; "></th>
                        <th style="border-left: none;border-right: none; "></th>
                        <th style="border-left: none;border-right: none; " v-if="trans === 'Seller'">Trade-In Total</th>
                        <th style="border-left: none;border-right: none; " v-else>إجمالي المبادلة</th>
                        <th style="border-left: none;border-right: none; "></th>
                        <th style="border-left: none;">{{ order[0].total }}</th>

                      </tr>
                    </table>
                  </div>

                  <div class="mt-3 d-flex justify-content-around ">
                    <!-- <div>
            <h6 class="mt-4"><u>Purchased via</u></h6>
            <p class=" "><b>Sales Representative:</b> {{order[0].sales}}</p>
            <p class=" "><b>Store Name:</b> {{order[0].address}}</p>
            <p><b>Address:</b> {{order[0].shop}}</p>
          </div> -->
                    <div class="mt-3">
                      <!-- <qr-code :text="'https://vas.redingtongroup.com/orders/sell/'+id" size="100"></qr-code> -->
                    </div>
                  </div>
                  <!-- <div class="html2pdf__page-break"></div> -->

                  <div class="mt-3 container" id="c2" v-if="trans === 'Seller'" style="font-size: smaller;">
                    <h6 class="text-center">TERMS & CONDITIONS</h6>
                    <h6 class="text-left mt-3">
                      I hereby acknowledge and agree to the below terms and conditions. By signing the below, I confirm
                      that I have read, understood, and agree to the terms and conditions to the best of my true
                      consciousness.
                    </h6>
                    <h6 class="text-left mt-2">
                      a) Agree to provide accurate and complete information regarding the devices I intend to trade-in,
                      including but not limited to the make, model, condition, and any associated accessories.
                      <br />
                      b) Acknowledge that the trade-in value offered by Retailer for the devices is subject to
                      evaluation and verification upon physical inspection by Retailer or its authorized
                      representatives.
                      <br />

                      c) Understand that the final trade-in value offered by Retailer may vary from the initial
                      estimate, and any adjustments shall be communicated to Customer prior to the completion of the
                      trade-in transaction.
                      <br />

                      d) Warrants that he/she has legal ownership or proper authorization to trade- in the devices and
                      that the devices are free from any liens, encumbrances, or restrictions that would prevent their
                      transfer.
                      <br />

                      e) Agrees to transfer all ownership rights, title, and interest in the trade-in devices to
                      Retailer upon the completion of the trade-in transaction.
                      <br />

                      f) Acknowledges that the trade-in transaction is final, I understand that under any circumstances
                      it cannot be returned or reclaimed.
                      <br />

                      g) I Release the Retailer from any liability, claims, damages, or disputes arising from or related
                      to the trade-in transaction, including but not limited to loss of data, personal information, or
                      any unauthorized use of the trade-in devices.
                      <br />

                      h) Agree to indemnify and hold Retailer harmless from any third-party claims or demands arising
                      out of or in connection with the trade-in transaction, including any claims of infringement or
                      violation of intellectual property rights.
                      <br />

                      i) Agree that once the transaction is finalized he/ she shall not raise any disputes from the
                      trade-in transaction and I hereby waive all the rights to raise any dispute regarding the trade-in
                      transaction once the transaction is completed in accordance with the laws of the jurisdiction
                      governing this agreement.
                      <br />

                      j) This clause constitutes the entire agreement regarding the trade-in transaction and supersedes
                      any prior understandings or agreements, whether written or verbal, relating to the same.
                      <br />

                      k) By proceeding with the trade-in transaction, I acknowledge that I have read and understood the
                      terms and conditions outlined in this clause and agree to be bound by them.
                      <br />


                      l) This clause shall be binding upon Parties, their successors, assigns, and legal
                      representatives.

                    </h6>
                    <br />
                  </div>
                  <div v-else class="notranslate">
                    <h3 class="text-center">القيود و الاحكام</h3>
                    <!-- <h6 class="text-right mt-4">
            انا الموقع ادناه _________________ حامل الجواز رقم ______________ اقر و أوافق على الشروط و الاحكام المرفقة في الأسفل بتوقيعي المرفق ادناه, و أؤكد اني قرأت و فهمت و أوافق على هذه الشروط و الاحكام  إلى أفضل ما لدي من وعي.

          </h6> -->
                    <h6 class="text-right mt-3 p-3">

                      <br /><br /> ١. أقر بموجبه وأوافق على الشروط والأحكام الواردة أدناه. من خلال التوقيع على ما يلي ،
                      أؤكد أنني قد قرأت وفهمت وأوافق على الشروط والأحكام بأفضل ما لدي من وعي.
                      <br /><br /> ٢. اوافق على تقديم معلومات دقيقة وكاملة بشأن الأجهزة التي ينوون استبدالها ، بما في
                      ذلك على سبيل المثال: الطراز والحالة وأي ملحقات مرتبطة.
                      <br /> <br /> ٣. اقر بأن قيمة الاستبدال التي يقدمها بائع التجزئة للأجهزة تخضع للتقييم والتحقق من
                      خلال الفحص المادي من قبل بائع التجزئة أو ممثليه المعتمدين.
                      <br /> <br /> ٤. افهم أن قيمة المقايضة النهائية التي يقدمها بائع التجزئة قد تختلف عن التقدير
                      الأولي ، ويجب إبلاغ العميل بأي تعديلات قبل إتمام معاملة المقايضة.
                      <br /> <br /> ٥. اتعهد بأن لديه/لها الملكية القانونية أو الإذن المناسب للتداول في الأجهزة وأن
                      الأجهزة خالية من أي أعباء أو قيود من شأنها أن تمنع نقلها.
                      <br /> <br /> ٦. الموافقة على نقل جميع حقوق الملكية في أجهزة المقايضة إلى بائع التجزئة عند إتمام
                      صفقة الاستبدال.
                      <br /> <br /> ٧. أقر بأن معاملة الاستبدال نهائية ، وأدرك أنه لا يمكن إرجاعها أو استردادها تحت أي
                      ظرف من الظروف.
                      <br /> <br /> ٨. أعفي بائع التجزئة من أي مسؤولية أو مطالبات أو أضرار أو نزاعات تنشأ عن أو تتعلق
                      بمعاملة المقايضة ، بما في ذلك على سبيل المثال فقدان البيانات أو المعلومات الشخصية أو أي استخدام
                      غير مصرح به لأجهزة الاستبدال.
                      <br /> <br /> ٩. الموافقة على تعويض وإبقاء بائع التجزئة غير ضار من أي مطالبات أو مطالب من طرف ثالث
                      تنشأ عن أو فيما يتعلق بمعاملة المقايضة ، بما في ذلك أي مطالبات انتهاك حقوق الملكية الفكرية.
                      <br /> <br /> ١٠. أوافق على أنه بمجرد الانتهاء من المعاملة ، لن يقوم/تقوم بإثارة أي نزاعات من
                      معاملة التجارة ، وأنا بموجب هذا أتنازل عن جميع الحقوق لإثارة أي نزاع بشأن معاملة التجارة بمجرد
                      اكتمال المعاملة وفقًا للقوانين. من الاختصاص الذي يحكم هذه الاتفاقية.
                      <br /> <br /> ١١. يشكل هذا البند الاتفاقية الكاملة فيما يتعلق بصفقة التجارة ويبطل أي تفاهمات أو
                      اتفاقيات سابقة ، سواء كانت مكتوبة أو شفهية، تتعلق بها.
                      <br /> <br /> ١٢. من خلال متابعة معاملة الاستبدال ، أقر بأنهم قد قرأوا وفهموا الشروط والأحكام
                      الموضحة في هذا البند وأوافق على الالتزام بها.
                      <br /> <br /> ١٣. يجب أن يكون هذا البند ملزمًا للأطراف وخلفائهم والمتنازل لهم والممثلين
                      القانونيين.

                    </h6>
                    <br />
                  </div>
                  <div class="row justify-content-end" v-if="trans === 'Seller'">
                    <h6 class="text-center mr-5  mb-5">
                      <hr />Signature of the Customer

                    </h6>
                  </div>
                  <div class="row justify-content-start" v-else>
                    <h6 class="text-center mr-5  mb-5">
                      <hr />
                      توقيع العميل
                    </h6>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </b-container>
      </div>
    </div>
    <div class="mt-3 pb-2 print d-lg-none">
      <!-- <h2 class="mb-3 info-box" style="color: #39A536;"><b>Thank you</b></h2> -->
      <div class="d-flex justify-content-center mt-3">
        <b-container class=" d-flex justify-content-center">
          <div class=" mb-2 ">
            <div class="home">
              <!-- <img src="../assets/Buyback_content/5 - Thank you.svg"/> -->

              <div class="thanks p-5 info-box border border-secondary mb-2 ">
                <div>
                  <qr-code :text="'https://vas.redingtongroup.com/orders/sell/' + id" size="100"></qr-code>
                </div>
                <div class="text-left mt-5">
                  <h4 v-if="trans === 'Seller'">Purchase Receipt</h4>
                  <h2 v-else>إيصال الشراء</h2>
                </div>

                <div class="text-left mt-3">
                  <div class=" justify-content-center">
                    <div>
                      <p><b>Date:</b> {{ order[0].date }}</p>
                      <p><b>Reference#</b> {{ order[0].id }}</p>
                    </div>

                  </div>

                  <br />
                  <h5><u id="myDiv" ref="myDiv">Seller</u></h5>
                  <p class="mt-3 notranslate"><b>Name:</b> {{ order[0].seller }}</p>
                  <p class="notranslate"><b>Email:</b> {{ order[0].email }}</p>
                  <p class="notranslate"><b>Contact:</b> {{ order[0].num }}</p>
                  <p class="notranslate"><b>Place:</b> {{ order[0].add }}</p>
                  <div class="mt-3" v-if="order[0].pro_d">
                    <table id="customers">
                      <tr>
                        <th style="border-right: none; ">The Device You Have Purchased</th>
                        <th style="border-left: none;"></th>
                      </tr>
                      <tr>
                        <th>Product Description</th>
                        <th>IMEI/Serial Number </th>
                      </tr>
                      <tr>
                        <td>{{ order[0].pro_d }}</td>
                        <td>{{ order[0].pro_i }}</td>
                      </tr>
                    </table>
                  </div>
                  <div>

                  </div>
                  <div class="justify-content-center d-flex mt-3">
                    <b-button @click="exportToPDF" class="button mr-3 mb-3" v-if="trans === 'Seller'">Print
                      Receipt</b-button>
                    <b-button @click="exportToPDF" class="button mr-3 mb-3" v-else>Print Receipt</b-button>

                  </div>
                  <div class="row justify-content-center"  v-if="this.$store.state.user">
                    <b-button @click="getOrderStatus2()" class="button mr-3 mb-3"
                      v-if="this.$store.state.user.position === 'Manager' && order[0].status === 'Pending'">Cancel Buyback</b-button>
                    <b-button @click="getOrderStatus()" class="button mr-3 mb-3"
                      v-if="order[0].status === 'Pending'">Incomplete Buyback</b-button>
                    <b-button @click="getOrderStatus()" class="button mr-3 mb-3"
                      v-if="order[0].status === 'Incomplete'">Complete Buyback</b-button>
                    <!-- <b-button @click="print_rsp()" class="button mr-3 mb-3" v-if="trans === 'Seller'">Print</b-button>
            <b-button @click="print_rsp()" class="button mr-3 mb-3" v-else>اطبع</b-button> -->

                  </div>

                </div>
              </div>
            </div>

          </div>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../api'
import Header from '../components/Header2.vue'
import html2pdf from "html2pdf.js";
import SplashScreen from '../components/SplashScreen.vue'
import VueBarcode from 'vue-barcode';
export default {
  name: 'ThanksPage',
  props: {
    orderno: ''
  },
  components: {
    Header,
    html2pdf,
    SplashScreen,
    'barcode': VueBarcode
  },
  computed: {
    customerList: function () {
      return this.$store.state.reciept
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      trans: false,
      order: null,
      // position:this.$store.state.user.position,
      animate: true
    }
  },
  methods: {
    exportToPDF() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 1,
        filename: "Receipt.pdf",
      });
    },
    print_rsp: function () {
      // this.order = true
      window.print();
    },
    getOrderDetails: function () {
      var token_data = JSON.parse(localStorage.getItem('token_data'));
      const inputs = {
      
            'id': this.id
          
      }
      api.request(process.env.VUE_APP_Get_Device_Data, inputs).then((response) => {
        if (response.data.errorcode) {
          this.errormessage = response.data.errormessage
        } else if (response.data.result && typeof response.data.result === 'object') {
          this.order = response.data.result
          // console.log(this.order)
        } else if (response.data.result && typeof response.data.result === 'string') {
          this.errormessage = response.data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    },
    getOrderStatus: function () {
      const inputs = {
       
            'id': this.id
          
      }
      api.request(process.env.VUE_APP_Complete_Buyback, inputs).then((response) => {
        if (response.data.errorcode) {
          this.errormessage = response.data.errormessage
        } else if (response.data.result) {
          window.location.reload()
        } else if (response.data.result && typeof response.data.result === 'string') {
          this.errormessage = response.data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    },
    getOrderStatus2: function () {
      const inputs = {
      
            'id': this.id,
            'user_token': this.$store.state.user.user_token,
            'token_data':this.$store.state.user.token_data
          
      }
      api.request(process.env.VUE_APP_Cancel_Buyback, inputs).then((response) => {
        if (response.data.errorcode) {
          this.errormessage = response.data.errormessage
        } else if (response.data.result) {
          window.location.reload()
        } else if (response.data.result && typeof response.data.result === 'string') {
          this.errormessage = response.data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    },
    // getData: function () {
    //     const inputs = {
    //       'inputs': {
    //         'session_id': this.$store.state.token,
    //         'model': 'r.users',
    //         'method': 'get_data',
    //         'args': {
    //           'user_id': this.$store.state.user.uid
    //         }
    //       },
    //       'modecode': 100
    //     }
    //     api
    //       .request('post', 'update', inputs)
    //       .then((response) => {
    //         var data = response.data
    //         if (data.errorcode) {
    //           return
    //         }
    //         if (data.result) {
    //           this.position = data.result
    //         }
    //       })
    //       .catch((error) => {
    //         console.log('What happened? ' + error)
    //       })
    //   },
  },

  mounted() {
    // if (this.orderno !== undefined) {
    //   this.orderno === undefined
    //   window.location.reload()
    // }
    this.getOrderDetails()
    // this.getData()
    setTimeout(() => {
      // console.log(this.$refs.myDiv.innerHTML)
      this.trans = this.$refs.myDiv.innerHTML
      this.animate = false
    }, 2000);
  }
}
</script>

<style scoped>
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}

#customers th {
  font-size: x-small;
}

#customers td {
  font-size: x-small;
}

.thanks {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}

.hrc {
  border: 1px solid rgb(180, 180, 180);
  width: 60%;
}

.hrc2 {
  border: 1px solid rgb(199, 197, 197);
  width: 100%;
}

#c2 h6 {
  font-size: x-small;
}

p {
  font-size: x-small;
  margin: 0;
}

.info-box {
  width: fit-content;
  margin-right: 3px;
  margin-left: 3px;
  padding: 3px;
  border-radius: 7px;
  border-color: #e0e0ec !important;
  text-align: center;
}

.wdk_content-pagetitle {
  display: none;
}

@media print {
  @page {
    margin: 0;
  }

  head * {
    visibility: hidden;
  }

  body * {
    visibility: hidden;
  }

  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }

  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
