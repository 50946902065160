<template>
  <b-nav-form @submit.stop.prevent="searchProduct($router.history.current.name)">
  <div class="input-group" @mouseleave="set_input_flag(false)">
    <div class="dropdown d-flex">
      <div class="input-group-append">
        <button class="btn bg-success text-white" type="button">
          <i class="fa fa-search"></i>
        </button>
      </div>
      <input
        type="text"
        v-model="searchTerm"
        @focus="set_input_flag(true)"
        @input="set_input_flag(true)"
        @click="set_input_flag(true)"
        class="form-control txt-bor-rad text-left"
        placeholder="Search for devices"
      />
      <div
      v-show="is_input_focused"
      class="dropdown-list"
      @mouseleave="set_input_flag(false)"
      >
        <div
          v-on:click="searchProduct(item)"
          v-for="item in list_of_search_options"
          :key="item"
          class="dropdown-item text-left"
        >
          <div  class="d-lg-none" style="font-size: 12px;">Search<b> {{ searchTerm }} </b>in<b> {{ item }}</b></div>
          <div  class="d-none d-lg-block">Search<b> {{ searchTerm }} </b>in<b> {{ item }}</b></div>
        </div>
      </div>
    </div>
  </div>
  </b-nav-form>
</template>

<script>
export default {
  name: 'SearchBox',
  data () {
    return {
      searchTerm: '',
      list_of_search_options: [],
      is_input_focused: false
    }
  },
  methods: {
    set_input_flag: function (flag) {
      this.is_input_focused = flag
      if (this.searchTerm.length === 0) {
        this.is_input_focused = false
      }
    },
    searchProduct: function (item) {
      switch (item) {
        case 'Repair':
          this.$router.push({ name: 'RepairGadget' })
          break
        case 'RepairGadget':
          this.$router.push({ name: 'RepairGadget' })
          break
        case 'Repair Gadget':
          this.$router.push({ name: 'RepairGadget' })
          break
        case 'GadgetProtection':
          this.$router.push({ name: 'GadgetProtection' })
          break
        case 'Gadget Protection/Warranty':
          this.$router.push({ name: 'GadgetProtection' })
          break
        case 'DataRecovery':
          this.$router.push({ name: 'DataRecovery' })
          break
        case 'Data Recovery':
          this.$router.push({ name: 'DataRecovery' })
          break
        case 'TemperedGlass':
          this.$router.push({ name: 'TemperedGlass' })
          break
        case 'Tempered Glass':
          this.$router.push({ name: 'TemperedGlass' })
          break
        case 'Texture':
          this.$router.push({ name: 'Texture' })
          break
        case 'LaserEngraving':
          this.$router.push({ name: 'LaserEngraving' })
          break
        case 'Laser Engraving':
          this.$router.push({ name: 'LaserEngraving' })
          break
        case 'RenewUpgrade':
          this.$router.push({ name: 'RenewUpgrade' })
          break
        case 'Upgrade':
          this.$router.push({ name: 'RenewUpgrade' })
          break
        case 'Renew':
          this.$router.push({ name: 'RenewUpgrade' })
          break
        case 'Sell your gadget':
          this.$router.push({ name: 'ReuseSell' })
          break
        default:
          this.$router.push({ name: 'ReuseBuy', query: { search: this.searchTerm } })
      }
    },
    set_list_of_search_options: function () {
      switch (this.$router.history.current.name) {
        case 'Repair':
          this.list_of_search_options = [
            'Repair Gadget',
            'Gadget Protection/Warranty',
            'Data Recovery'
          ]
          break
        case 'RepairGadget':
          this.list_of_search_options = [
            'Repair Gadget',
            'Gadget Protection/Warranty',
            'Data Recovery'
          ]
          break
        case 'GadgetProtection':
          this.list_of_search_options = [
            'Repair Gadget',
            'Gadget Protection/Warranty',
            'Data Recovery'
          ]
          break
        case 'DataRecovery':
          this.list_of_search_options = [
            'Repair Gadget',
            'Gadget Protection/Warranty',
            'Data Recovery'
          ]
          break
        case 'TemperedGlass':
          this.list_of_search_options = [
            'Tempered Glass',
            'Texture',
            'Laser Engraving',
            'Upgrade'
          ]
          break
        case 'Texture':
          this.list_of_search_options = [
            'Tempered Glass',
            'Texture',
            'Laser Engraving',
            'Upgrade'
          ]
          break
        case 'LaserEngraving':
          this.list_of_search_options = [
            'Tempered Glass',
            'Texture',
            'Laser Engraving',
            'Upgrade'
          ]
          break
        case 'RenewUpgrade':
          this.list_of_search_options = [
            'Tempered Glass',
            'Texture',
            'Laser Engraving',
            'Upgrade'
          ]
          break
        case 'Renew':
          this.list_of_search_options = [
            'Tempered Glass',
            'Texture',
            'Laser Engraving',
            'Upgrade'
          ]
          break
        case 'Home':
          this.list_of_search_options = []
          break
        default:
          this.list_of_search_options = [
            'Buy verified devices',
            'Sell your gadget'
          ]
      }
    }
  },
  computed: {
    select: function (item) {
      this.set_input_flag()
      return this.searchProduct(item)
    }
  },
  mounted: function () {
    this.set_list_of_search_options()
  }
}
</script>

<style scoped>
.input-group-append .btn {
  border-radius: 50% 0 0 50%;
}
input {
  border-radius: 0 25px 25px 0;
  width: 100% !important;
}
input:focus{
  box-shadow: none;
  border-color: #4CAF50;
}
input::placeholder{
  opacity: 0.7;
}
@media (min-width: 992px) {
  .input-group {
    width: 600px;
  }
}
.dropdown{
  position: relative;
  width: 100% !important;
}
.dropdown-list{
  position: absolute;
  margin-top: 2.4rem;
  z-index: 1;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.dropdown-item{
  width: 100%;
  cursor: pointer;
}
.dropdown-item:hover{
  background: #edf2f7;
}
.dropdown-item:focus{
  background: #a4ecae !important;
}

</style>
